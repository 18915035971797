import {
  createDiscountSchemaFail,
  createDiscountSchemaRequest,
  createDiscountSchemaSuccess,
  getDiscountSchemaFail,
  getDiscountSchemaSuccess,
  updateDiscountSchemaFail,
  updateDiscountSchemaSuccess,
  getDiscountSchemaRequest,
  getDiscountSchemaNextRequest,
  getDiscountSchemaPreviousRequest,
  updateDiscountSchemaRequest,
  clearAllData,
  getDiscountSchemeByIdRequest,
  discountSchemaEditSuccess,
  discountSchemeEditFail,
} from "./DiscountSchemeSlice";
import { mergeMap } from "rxjs";
import {
  getDiscountSchema,
  createDiscountSchema,
  updateDiscountSchema,
  getDiscountSchemeById,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();

//get DiscountSchema epic
const getDiscountSchemaEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDiscountSchemaRequest.match),
    mergeMap(async (action: any) => {
      try {
        const response = await getDiscountSchema(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getDiscountSchemaSuccess(action?.payload)
        : getDiscountSchemaFail()
    )
  );

//get discount scheme by id epic
const getDiscountSchemeByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDiscountSchemeByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getDiscountSchemeById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? discountSchemaEditSuccess(action.payload)
        : discountSchemeEditFail()
    )
  );

//create
const createDiscountSchemeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createDiscountSchemaRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          body.append(`${key}`, String(value));
        }
        const response = await createDiscountSchema(body);
        if (response) {
          dispatch(getDiscountSchemaRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? createDiscountSchemaSuccess()
        : createDiscountSchemaFail();
    })
  );

//update
const updateDiscountSchemeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateDiscountSchemaRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page, search } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          body.append(`${key}`, String(value));
        }
        const response = await updateDiscountSchema(body, id);
        if (response) {
          dispatch(getDiscountSchemaRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearAllData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? updateDiscountSchemaSuccess()
        : updateDiscountSchemaFail();
    })
  );

//get next
const getDiscountSchemeNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDiscountSchemaNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? getDiscountSchemaSuccess(action?.payload)
        : getDiscountSchemaFail();
    })
  );

//get previous
const getDiscountSchemaPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDiscountSchemaPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getDiscountSchemaSuccess(action?.payload)
        : getDiscountSchemaFail()
    )
  );

export const discountSchemaEpics = combineEpics(
  getDiscountSchemaEpic,
  createDiscountSchemeEpic,
  updateDiscountSchemeEpic,
  getDiscountSchemeNext,
  getDiscountSchemaPrevious,
  getDiscountSchemeByIdEpic
);
