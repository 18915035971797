import React from "react";
import unauthorizedImage from "../../Assets/403Image.png";
import { Grid } from "@mui/material";

function Unauthorized() {
  return (
    <>
      <Grid style={{ height: 630, width: 1000 }}>
        <img
          width={1000}
          height={630}
          src={unauthorizedImage}
          alt="Unauthorized"
        />
      </Grid>
    </>
  );
}

export default Unauthorized;
