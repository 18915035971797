import {
  createTaxGroupFail,
  createTaxGroupRequest,
  createTaxGroupSuccess,
  getTaxGroupSuccess,
  getTaxGroupFail,
  updateTaxGroupFail,
  updateTaxGroupSuccess,
  getTaxGroupRequest,
  updateTaxGroupRequest,
  getTaxGroupNextRequest,
  getTaxGroupPreviousRequest,
  clearTaxGroupData,
  getTaxGroupByIdRequest,
  taxGroupEditSuccess,
  taxGroupEditFail,
} from "./taxGroupSlice";
import { mergeMap } from "rxjs";
import {
  createTaxGroup,
  getTaxGroup,
  getTaxGroupById,
  updateTaxGroup,
} from "./api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";

export const controller = new AbortController();

// get manufacture epic
const getTaxGroupEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTaxGroupRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getTaxGroup(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getTaxGroupSuccess(action?.payload) : getTaxGroupFail()
    )
  );

//get tax group by id epic
const getTaxGroupByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTaxGroupByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getTaxGroupById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? taxGroupEditSuccess(action?.payload)
        : taxGroupEditFail()
    )
  );

//get next
const getTaxGroupNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTaxGroupNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getTaxGroupSuccess(action?.payload) : getTaxGroupFail()
    )
  );

//get previous
const getTaxGroupPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTaxGroupPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getTaxGroupSuccess(action?.payload) : getTaxGroupFail()
    )
  );
//create TaxGroup epic
const createTaxGroupEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createTaxGroupRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      const { name, rate, active } = values;
      try {
        const body = JSON.stringify({
          name,
          rate,
          active,
        });
        const response = await createTaxGroup(body);
        if (response) {
          dispatch(getTaxGroupRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? createTaxGroupSuccess() : createTaxGroupFail();
    })
  );

//update TaxGroup epic
const updateTaxGroupEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateTaxGroupRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      const { name, rate, active, remarks } = values;
      try {
        const body = JSON.stringify({
          name,
          rate,
          active,
          remarks,
        });
        const response = await updateTaxGroup(body, id);
        if (response) {
          dispatch(getTaxGroupRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(clearTaxGroupData());
          dispatch(closeModal());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateTaxGroupSuccess() : updateTaxGroupFail()
    )
  );

export const taxGroupEpics = combineEpics(
  getTaxGroupEpic,
  createTaxGroupEpic,
  updateTaxGroupEpic,
  getTaxGroupNext,
  getTaxGroupPrevious,
  getTaxGroupByIdEpic
);
