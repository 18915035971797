import { Card, CardContent, Divider, Grid, Chip, Avatar } from "@mui/material";
import React, { memo, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import AppButton from "../AppElements/Button/AppButton";
import { useAppSelector } from "../../AppUtils/Utils/appHooks";
import {
  showModal,
  updateShowTreeViewListing,
  showReferenceRangeModal,
  updateInventorySidebar,
} from "../../Pages/CommonAppRedux/CommonAppSlice";
import { commonAppSelector } from "../../Pages/CommonAppRedux/selector";
// import { patientDetailSelector } from "../../Pages/ClinicPages/PatientDetails/Redux/selector";
import FixedAppBar from "./Header/FixedHeader";
import Header from "./Header/Header";
import SidebarMenu from "./Sidebar/SidebarMenu";
import { useStyles, Main } from "./Sidebar/SidebarStyles";
import AppTabs from "../AppTabs/Tabs";
import { masterSidebarData } from "./Sidebar/Master/MasterSidebarData";
import { accSidebarData } from "./Sidebar/Accounting/AccountingSidebarData";

import { useImmer } from "use-immer";
import { handlePermission } from "../../AppUtils/Utils/permission";
import { loginSelector } from "../../Pages/Login/Redux/selector";
import { postIRDSyncInventoryRequest } from "../../Pages/InventoryPages/IRDReport/IRDSync/Redux/irdSyncInventorySlice";
import { invSidebarData } from "./Sidebar/Inventory/InventorySidebarData";
import FileImport from "../AppElements/CSVFileImport/FileImport";

interface layoutProps {
  children: React.ReactNode;
}
const Layout = ({ children }: layoutProps) => {
  const [openSidebar, setOpenSidebar] = useImmer({
    openClinicSidebar: false,
    openLabSidebar: false,
    openInvSidebar: false,
    openMasterSidebar: false,
    openIPDSidebar: false,
    openAccSidebar: false,
  });

  const addRef = useRef<HTMLInputElement>(null);

  const {
    openMasterSidebar,
    openInvSidebar,
    openLabSidebar,
    openIPDSidebar,
    openAccSidebar,
  } = openSidebar;
  const {
    hideAddButton,
    hideTabAndAction,
    passPermission,
    AddClinicIRDButton,
    AddInventoryIRDButton,
    updatedInventorySideBar,
    buyerCaption,
    hideHeader,
    globalOrganizationRules: rule,
    hideSidebarInitially,
    AddClinicEHRButton,
    showTreeView,
    showTreeViewListing,
    uploadCSVFile,
  } = useAppSelector(commonAppSelector);

  // const { selectedPatient } = useAppSelector(patientDetailSelector);
  const { permissions, isSuperuser } = useAppSelector(loginSelector);
  const [dataToBeLoad, setDataToBeLoad] = useImmer([]);
  const dispatch = useDispatch();
  //function to check the active sidebar
  const isActiveSidebar = (name: string) => {
    return localStorage?.getItem("activeSidebar") === name;
  };
  useEffect(() => {
    // set active sidebar
    isActiveSidebar("lab")
      ? setOpenSidebar((draft) => {
          draft.openLabSidebar = true;
        })
      : isActiveSidebar("clinic")
      ? setOpenSidebar((draft) => {
          draft.openClinicSidebar = true;
        })
      : isActiveSidebar("inventory")
      ? setOpenSidebar((draft) => {
          draft.openInvSidebar = true;
        })
      : isActiveSidebar("ipd")
      ? setOpenSidebar((draft) => {
          draft.openIPDSidebar = true;
        })
      : isActiveSidebar("accounting")
      ? setOpenSidebar((draft) => {
          draft.openAccSidebar = true;
        })
      : setOpenSidebar((draft) => {
          draft.openMasterSidebar = true;
        });
  }, []);
  useEffect(() => {
    // To also update the name in the items array:
    const updatedSidebarData = invSidebarData.map((section) => {
      if (section.name === "Customer") {
        const updatedItems = section.items.map((item) => {
          return {
            ...item,
            name: `Registration ${buyerCaption as string} `,
          };
        });
        return {
          ...section,
          name: buyerCaption as string,
          items: updatedItems,
        };
      } else if (section.name === "Inventory Management") {
        const updatedItems = section.items.map((item) => {
          return { ...item };
        });
        return {
          ...section,
          name: `${
            rule?.organizationSector === "HEALTH-CARE"
              ? "Pharmacy Item Setup"
              : "Inventory Management"
          } `,
          items: updatedItems,
        };
      } else if (section.name === "Sale") {
        const updatedItems = section.items.map((item, i) => {
          console.log(item, "ii");
          return {
            ...item,
            name: `${
              rule?.isChalanSystem
                ? i === 0
                  ? "Chalan"
                  : "Chalan Cancelled"
                : i === 0
                ? "Sale"
                : "Sale Return"
            }`,
          };
        });
        return {
          ...section,
          name: `${rule?.isChalanSystem ? "Chalan" : "Sale"} `,
          items: updatedItems,
        };
      } else if (section.name === "Financial Report") {
        const updatedItems = section.items.map((item) => {
          return {
            ...item,
            name: `${
              item.name === "Sale Report"
                ? rule?.isChalanSystem
                  ? "Chalan Report"
                  : "Sale Report"
                : item.name
            }`,
          };
        });
        return {
          ...section,
          items: updatedItems,
        };
      } else {
        return section;
      }
    });
    dispatch(updateInventorySidebar(updatedSidebarData));
    // }
  }, [buyerCaption]);

  // update data to be load
  useEffect(() => {
    setDataToBeLoad(
      openMasterSidebar
        ? masterSidebarData
        : openInvSidebar
        ? updatedInventorySideBar
        : openAccSidebar
        ? accSidebarData
        : null
    );
  }, [
    openInvSidebar,
    openLabSidebar,
    openMasterSidebar,
    updatedInventorySideBar,
    openIPDSidebar,
    openAccSidebar,
    setDataToBeLoad,
  ]);

  const [open, setOpen] = useState(true);

  const classes = useStyles();
  const handleClickOpen = () => {
    dispatch(showModal(true));
    dispatch(showReferenceRangeModal());
  };
  const handleClickShowTreeView = () => {
    dispatch(updateShowTreeViewListing(!showTreeViewListing));
  };

  const handleUploadIRDSync = () => {
    if (AddInventoryIRDButton) {
      dispatch(postIRDSyncInventoryRequest());
    }
  };
  //event stop default event
  // document.addEventListener("keydown", (e) => {
  //   if (
  //     e.key === "s" &&
  //     (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)
  //   ) {
  //     e.preventDefault();
  //   }
  // });
  // document.addEventListener("keydown", (e) => {
  //   if (
  //     e.key === "p" &&
  //     (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)
  //   ) {
  //     e.preventDefault();
  //   }
  // });
  //event
  useEffect(() => {
    const handleAddButton = (event: KeyboardEvent) => {
      if (event.ctrlKey && (event.key === "o" || event.key === "O")) {
        event.preventDefault(); // prev ent default behavior of saving the page
        addRef?.current?.click(); // click the button
      }
    };
    document.addEventListener("keydown", handleAddButton);
    return () => {
      document.removeEventListener("keydown", handleAddButton);
    };
  }, []);
  if (open === true) {
    const element = document?.getElementById("myMainMenu");
    element?.addEventListener("click", () => {
      setOpen(!rule?.collapseSideBar);
    });
  }
  return (
    <>
      <FixedAppBar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />
      <Header open={open} setOpen={setOpen} />

      <SidebarMenu open={open} dataToBeLoad={dataToBeLoad} setOpen={setOpen} />
      <Main open={open} id="myMainMenu">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card sx={{ overflow: "inherit" }}>
              {!hideTabAndAction && (
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  className={classes.cardHeader}
                >
                  <Grid item className={classes.tabs}>
                    <AppTabs dataToBeLoad={dataToBeLoad as any} />
                  </Grid>
                  {isSuperuser ||
                  handlePermission(
                    permissions,
                    passPermission?.add_permission
                  ) ? (
                    <>
                      {!hideAddButton &&
                        (showTreeView ? (
                          <Grid item xs={1.2} lg={2}>
                            <Grid container spacing={0.5}>
                              <Grid item xs={6} lg={6}>
                                <AppButton
                                  tooltipTitle={
                                    showTreeViewListing
                                      ? "ViewListing"
                                      : "TreeView"
                                  }
                                  onClick={handleClickShowTreeView}
                                >
                                  {showTreeViewListing
                                    ? "ViewListing"
                                    : "TreeView"}
                                </AppButton>
                              </Grid>
                              <Grid item xs={6} lg={6}>
                                <AppButton
                                  tooltipTitle="Add"
                                  submit={true}
                                  onClick={handleClickOpen}
                                  autoFocus
                                  reference={addRef}
                                >
                                  Add
                                </AppButton>
                              </Grid>
                            </Grid>
                          </Grid>
                        ) : uploadCSVFile ? (
                          <>
                            <Grid item>
                              <Grid container spacing={0.5}>
                                <Grid
                                  item
                                  justifyItems={"center"}
                                  alignItems={"center"}
                                >
                                  <FileImport />
                                </Grid>

                                <Grid item xs={1.2} lg={0.9}>
                                  <AppButton
                                    tooltipTitle="Add"
                                    submit={true}
                                    onClick={handleClickOpen}
                                    autoFocus
                                    reference={addRef}
                                  >
                                    Add
                                  </AppButton>
                                </Grid>
                              </Grid>
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid item xs={1.2} lg={0.9}>
                              <AppButton
                                tooltipTitle="Add"
                                submit={true}
                                onClick={handleClickOpen}
                                autoFocus
                                reference={addRef}
                              >
                                Add
                              </AppButton>
                            </Grid>
                          </>
                        ))}

                      {(AddClinicIRDButton ||
                        AddInventoryIRDButton ||
                        AddClinicEHRButton) && (
                        <>
                          <Grid item>
                            <AppButton
                              tooltipTitle={
                                AddClinicEHRButton
                                  ? "Upload EHR Sync"
                                  : "Upload IRD Sync"
                              }
                              submit={true}
                              onClick={handleUploadIRDSync}
                            >
                              {AddClinicEHRButton
                                ? "Upload EHR Sync"
                                : "Upload IRD Sync"}
                            </AppButton>
                          </Grid>
                        </>
                      )}
                    </>
                  ) : null}
                  {/* {selectedPatient && (
                                        <Grid item>
                                            <Chip
                                                avatar={
                                                    <Avatar>
                                                        {selectedPatient?.firstName?.charAt()}
                                                    </Avatar>
                                                }
                                                label={
                                                    selectedPatient?.fullName +
                                                    " " +
                                                    `(${selectedPatient?.customerNoFull})`
                                                }
                                                className="selected-patient-chip"
                                            />
                                        </Grid>
                                    )} */}
                </Grid>
              )}
              <Divider />
              <CardContent className={classes.cardContent}>
                {children}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Main>
    </>
  );
};

export default memo(Layout);
