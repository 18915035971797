import { createSlice, createAction } from "@reduxjs/toolkit";
import { journal } from "./types";
import { v4 as uuidv4 } from "uuid";
const initialState: any = {
  journals: [],
  loading: false,
  loadingCreateJournal: false,
  edit: false,
  journal: null || {},
  count: 0,
  previous: "",
  next: "",
  loadingAccount: false,
  accounts: [],
  journalVoucherDetails: [
    { unique: uuidv4(), accountHead: null, drAmount: 0, crAmount: 0 },
  ],
  readOnly: false,
};

export const Journal = createSlice({
  name: "JournalReducer",
  initialState,
  reducers: {
    loadingJournal: (state) => {
      state.loadingJournal = true;
    },
    getJournalRequest: (state, payload) => {
      state.loadingJournal = true;
    },
    getJournalSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingJournal = false;
      state.journals = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getJournalFail: (state) => {
      state.loadingJournal = false;
    },
    getJournalNextRequest: (state, payload) => {
      state.loadingJournal = true;
    },
    getJournalPreviousRequest: (state, payload) => {
      state.loadingJournal = true;
    },
    createJournalRequest: (state, payload) => {
      state.loadingCreateJournal = true;
    },
    createJournalSuccess: (state) => {
      state.loadingCreateJournal = false;
    },
    createJournalFail: (state) => {
      state.loadingCreateJournal = false;
    },

    getJournalVoucherByIdRequest: (state, { payload: { id, readOnly } }) => {
      state.edit = true;
      state.readOnly = readOnly;
    },
    journalVoucherEditSuccess: (state, { payload }) => {
      return {
        ...state,
        journal: payload,
        journalVoucherDetails: payload.voucherDetails.map((value: any) => {
          return { ...value, unique: uuidv4() };
        }),
      };
    },
    journalVoucherEditFail: (state) => {
      state.edit = false;
    },

    updateJournalRequest: (state, payload) => {
      state.loadingCreateJournal = true;
    },
    updateJournalSuccess: (state) => {
      state.loadingCreateJournal = false;
    },
    updateJournalFail: (state) => {
      state.loadingCreateJournal = false;
    },
    getAccountRequest: (state, payload) => {
      state.loadingAccount = true;
    },
    getAccountSuccess: (state, { payload: { results } }) => {
      state.accounts = results;
      state.loadingAccount = false;
    },
    getAccountFail: (state) => {
      state.loadingAccount = false;
    },

    addVoucherDetails: (state, { payload }) => {
      state.journalVoucherDetails = payload;
    },

    approveVoucherRequest: (state, payload) => {
      state.loadingAccount = true;
    },
    approveVoucherSuccess: (state) => {
      state.loadingAccount = false;
    },
    approveVoucherFail: (state) => {
      state.loadingAccount = false;
    },
    cancelVoucherRequest: (state, payload) => {
      state.loadingAccount = true;
    },
    cancelVoucherSuccess: (state) => {
      state.loadingAccount = false;
    },
    cancelVoucherFail: (state) => {
      state.loadingAccount = false;
    },

    clearJournalData: (state) => {
      state.edit = false;
      state.journal = null;
      state.journalVoucherDetails = [
        { unique: uuidv4(), accountHead: null, drAmount: 0, crAmount: 0 },
      ];
      state.readOnly = false;
    },
  },
});
// Action creators are generated for each case reducer function
const { actions, reducer } = Journal;
export const {
  loadingJournal,
  getJournalRequest,
  getJournalNextRequest,
  getJournalPreviousRequest,
  getJournalSuccess,
  getJournalFail,
  createJournalRequest,
  createJournalSuccess,
  createJournalFail,
  getJournalVoucherByIdRequest,
  journalVoucherEditSuccess,
  journalVoucherEditFail,
  updateJournalRequest,
  updateJournalSuccess,
  updateJournalFail,
  addVoucherDetails,
  getAccountRequest,
  getAccountSuccess,
  getAccountFail,
  approveVoucherRequest,
  approveVoucherSuccess,
  approveVoucherFail,
  cancelVoucherRequest,
  cancelVoucherSuccess,
  cancelVoucherFail,
  clearJournalData,
} = actions;
export default reducer;
