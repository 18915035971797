import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

const StockAnalysissModURL = "/api/v1/inventory-stock-app/stock-analysis";
export const getStockAnalysis = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${StockAnalysissModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

export const searchStockAnalysis = ({ rowsPerPage, search }: searchProps) => {
  return `${StockAnalysissModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};

export const postStockAnalysisApi = () => {
  const values = "";
  const body = JSON.stringify(values);
  axiosInstance.post(`${StockAnalysissModURL}`, body);
};
