import { filterApiData } from "../../../../../AppUtils/Utils/appFunctions";
import { axiosInstance } from "../../../../../AppUtils/Utils/axios";
import { defaultRowsPerPage } from "../../../../../AppUtils/Utils/globalConstant";

export const ledgerReportURL = "api/v1/account-report-app/general-ledger";

// query for report
const getReportQuery = (updatedValue: any) => {
  const { account, startDateAd, endDateAd } = updatedValue;
  return `&account_head=${account}&date_after=${startDateAd}&date_before=${endDateAd}`;
};

export const getLedgerReportAccount = () => {
  return axiosInstance.get(
    `${ledgerReportURL}/account?offset=0&limit=${defaultRowsPerPage}`
  );
};

export const getledgerQuickReport = (data: any) => {
  const { rowsPerPage, page, updatedValue } = data;
  const { summary } = updatedValue;
  return axiosInstance.get(
    `${ledgerReportURL}/${summary ? "summary" : "detail"}?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }${getReportQuery(updatedValue)}`
  );
};
