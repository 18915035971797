import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

const itemCategoryModURL = "api/v1/inventory-item-app/item-category";

//get ItemCategory data api
export const getItemCategory = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${itemCategoryModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};
//handle search
export const searchItemCategory = ({ rowsPerPage, search }: searchProps) => {
  return `${itemCategoryModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
//create ItemCategory
export const createItemCategory = (body: string | FormData) =>
  axiosInstance.post(`${itemCategoryModURL}`, body);
//update ItemCategory
export const updateItemCategory = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${itemCategoryModURL}/${id}`, body);
