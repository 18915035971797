import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  titles: [],
  edit: false,
  title: null,
  loadingTitle: false,
  loadingCreateTitle: false,
  count: 0,
  next: "",
  previous: "",
};

export const updateTitleRequest = createAction(
  "updateTitleRequest",
  function prepare({ values, id, rowsPerPage, page }) {
    return {
      payload: {
        values,
        id,
        rowsPerPage,
        page,
      },
    };
  }
);

export const getTitleRequest = createAction(
  "getTitleRequest",
  function prepare({ rowsPerPage, page, filterData }) {
    return {
      payload: {
        rowsPerPage,
        page,
        filterData,
      },
    };
  }
);
// get next request
export const getTitleNextRequest = createAction(
  "getTitleNextRequest",
  function prepare(next) {
    return {
      payload: next,
    };
  }
);
//get previous request
export const getTitlePreviousRequest = createAction(
  "getTitlePreviousRequest",
  function prepare(previous) {
    return {
      payload: previous,
    };
  }
);
export const Title = createSlice({
  name: "TitleReducer",
  initialState,
  reducers: {
    loadingTitle: (state) => {
      state.loadingTitle = true;
    },
    getTitleSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingTitle = false;
      state.titles = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getTitleFail: (state) => {
      state.loadingTitle = false;
    },

    createTitleRequest: (state, payload) => {
      state.loadingCreateTitle = true;
      state.closeModal = true;
    },
    createTitleSuccess: (state) => {
      state.loadingCreateTitle = false;
    },
    createTitleFail: (state) => {
      state.loadingCreateTitle = false;
    },
    getTitleByIdRequest: (state, payload) => {
      state.edit = true;
    },
    titleEditSuccess: (state, { payload }) => {
      state.title = payload;
    },
    titleEditFail: (state) => {
      state.edit = false;
    },
    updateTitleSuccess: (state) => {
      state.loadingCreateTitle = false;
    },
    updateTitleFail: (state) => {
      state.loadingCreateTitle = false;
    },
    clearTitleData: (state) => {
      state.edit = false;
      state.title = null;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingTitle,
  getTitleSuccess,
  getTitleFail,
  createTitleRequest,
  createTitleSuccess,
  createTitleFail,
  titleEditSuccess,
  updateTitleSuccess,
  updateTitleFail,
  clearTitleData,
  getTitleByIdRequest,
  titleEditFail,
} = Title.actions;
export default Title.reducer;
