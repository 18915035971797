import { combineReducers } from "@reduxjs/toolkit";
import CommonAppSlice from "../Pages/CommonAppRedux/CommonAppSlice";
import PaymentMode from "../Pages/CoreSetupPages/PaymentMode/Redux/PaymentModeSlice";
import User from "../Pages/CoreSetupPages/User/Redux/userSlice";
import Country from "../Pages/CoreSetupPages/Country/Redux/countrySlice";
import Province from "../Pages/CoreSetupPages/Province/Redux/provinceSlice";
import District from "../Pages/CoreSetupPages/District/Redux/districtSlice";
import Palika from "../Pages/CoreSetupPages/Palika/Redux/palikaSlice";
import TaxGroup from "../Pages/CoreSetupPages/TaxGroup/Redux/taxGroupSlice";
import Auth from "../Pages/Login/Redux/authSlice";
import Manufacturer from "../Pages/InventoryPages/Manufacturer/Redux/manufacturerSlice";
import ItemUnit from "../Pages/InventoryPages/ItemUnit/Redux/itemUnitSlice";
import ItemCategory from "../Pages/InventoryPages/ItemCategory/Redux/itemCategorySlice";
import PackingType from "../Pages/InventoryPages/PackingType/Redux/packingTypeSlice";
import Item from "../Pages/InventoryPages/Item/Redux/itemSlice";
import Customer from "../Pages/InventoryPages/Customer/Redux/customerSlice";
// core set up
import Supplier from "../Pages/InventoryPages/Supplier/Redux/supplierSlice";
import UserRole from "../Pages/CoreSetupPages/UserRole/Redux/userRoleSlice";
import Organization from "../Pages/CoreSetupPages/OrganizationSetup/Redux/organizationSlice";
import Title from "../Pages/CoreSetupPages/Title/Redux/titleSlice";
import DiscountSchema from "../Pages/CoreSetupPages/DiscountScheme/Redux/DiscountSchemeSlice";
import CustomerType from "../Pages/CoreSetupPages/CustomerType/Redux/customerTypeSlice";
import Store from "../Pages/CoreSetupPages/Store/Redux/storeSlice";
import Purchase from "../Pages/InventoryPages/Purchase/Redux/purchaseSlice";
import AdditionalCharge from "../Pages/CoreSetupPages/AdditionalChargeType/Redux/AdditionalChargeSlice";
import Bank from "../Pages/CoreSetupPages/Bank/Redux/BankSlice";
import OrganizationRule from "../Pages/CoreSetupPages/OrganizationRule/Redux/organizationRuleSlice";

//extra
import PaymentDetails from "../Pages/CommonAppPages/PaymentModeDetails/Redux/paymentDetailsSlice";
//viewDetails
import ItemListDetail from "../Pages/ViewDetails/ItemList/Redux/ItemListDetailSlice";
import PurchaseReport from "../Pages/InventoryPages/Purchase/PurchaseReport/Redux/purchaseReportSlice";
import SaleReport from "../Pages/InventoryPages/Purchase/SaleReport/Redux/saleReportSlice";
import CustomerEdit from "../Pages/CoreSetupPages/CustomerEdit/Redux/customerEditSlice";
import PurchaseReturn from "../Pages/InventoryPages/PurchaseReturnPages/Redux/purchaseReturnSlice";
import Sale from "../Pages/InventoryPages/Sale/Redux/saleSlice";
import SaleReturn from "../Pages/InventoryPages/SaleReturn/Redux/saleReturnSlice";
import UserActivityLogReportInventory from "../Pages/InventoryPages/IRDReport/UserActivityLogReport/Redux/userActivityLogReportInventorySlice";
import MaterializedReportInventory from "../Pages/InventoryPages/IRDReport/MaterializedReport/Redux/materializedReportInventorySlice";
import IRDSyncInventory from "../Pages/InventoryPages/IRDReport/IRDSync/Redux/irdSyncInventorySlice";
import CustomerWiseReport from "../Pages/InventoryPages/InventoryReport/CustomerWiseReport/Redux/customerWiseReportSlice";
import SupplierWiseReport from "../Pages/InventoryPages/InventoryReport/SupplierWiseSaleReport/Redux/supplierWiseReportSlice";
import PartyPayment from "../Pages/InventoryPages/PartyPayment/Redux/partyPaymentSlice";
import CreditClearance from "../Pages/InventoryPages/CreditClearancePages/Redux/creditClearanceSlice";
import Password from "../Pages/Login/Password/Redux/passwordSlice";
import UserWiseReport from "../Pages/InventoryPages/InventoryReport/UserWiseReport/Redux/userWiseReportSlice";
import StockAnalysisReportInventory from "../Pages/InventoryPages/InventoryReport/StockAnalysis/Redux/stockAnalysisReportInventorySlice";
import StockAnalysis from "../Pages/InventoryPages/StockAnalysis/Redux/stockAnalysisSlice";
//account
import AccountHead from "../Pages/AccountingPages/AccountHead/Redux/accountHeadSlice";
import Journal from "../Pages/AccountingPages/Journal/Redux/journalSlice";
import JournalEntry from "../Pages/AccountingPages/JournalEntry/Redux/journalEntrySlice";
import Ledger from "../Pages/AccountingPages/AccountReports/LedgerReport/Redux/ledgerReportSlice";
import TrialBalance from "../Pages/AccountingPages/TrialBalance/Redux/trialBalanceSlice";
export const rootReducer = combineReducers({
  // master
  commonApp: CommonAppSlice,
  user: User,
  auth: Auth,
  paymentMode: PaymentMode,
  country: Country,
  province: Province,
  district: District,
  palika: Palika,
  taxGroup: TaxGroup,
  userRole: UserRole,
  organization: Organization,
  title: Title,
  discountSchema: DiscountSchema,
  customerType: CustomerType,
  store: Store,
  additionalCharge: AdditionalCharge,
  bank: Bank,
  paymentDetail: PaymentDetails,
  organizationRule: OrganizationRule,
  // inventory
  item: Item,
  manufacturer: Manufacturer,
  itemUnit: ItemUnit,
  itemCategory: ItemCategory,
  packingType: PackingType,
  supplier: Supplier,
  purchase: Purchase,
  purchaseReturn: PurchaseReturn,
  //purchase Report
  purchaseReport: PurchaseReport,
  saleReport: SaleReport,
  saleReturn: SaleReturn,
  partyPayment: PartyPayment,
  // inv common app
  inventorySale: Sale,
  customer: Customer,
  //viewDetails
  itemListDetail: ItemListDetail,
  //customer edit
  customerEdit: CustomerEdit,
  //inventory
  userActivityLogReportInventory: UserActivityLogReportInventory,
  materializedReportInventory: MaterializedReportInventory,
  irdSyncInventory: IRDSyncInventory,
  //core setup
  //inventory
  customerWiseReport: CustomerWiseReport,
  supplierWiseReport: SupplierWiseReport,
  creditClearance: CreditClearance,
  //change password
  password: Password,
  //userwise
  userWiseReport: UserWiseReport,
  stockAnalysisReportInventory: StockAnalysisReportInventory,
  stockAnalysis: StockAnalysis,
  // accounting
  accountHead: AccountHead,
  journal: Journal,
  journalEntry: JournalEntry,
  ledgerReport: Ledger,
  trialBalance: TrialBalance,
});
