import { memo, SyntheticEvent, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";
import { sideBarData } from "../AppPage/types";
import { useAppSelector } from "../../AppUtils/Utils/appHooks";
import { loginSelector } from "../../Pages/Login/Redux/selector";
import { commonAppSelector } from "../../Pages/CommonAppRedux/selector";
const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    tabIndex: -1,
    "aria-selected": undefined,
  };
};
interface tabs {
  dataToBeLoad: sideBarData[];
  // selectedModuleIndex: number;
}
const AppTabs = ({ dataToBeLoad }: tabs) => {
  let history = useHistory();
  const { selectedModuleIndex } = useAppSelector(commonAppSelector);
  const [value, setValue] = useState(selectedModuleIndex);
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const { globalOrganizationRules: rule } = useAppSelector(commonAppSelector);

  const tabData = useMemo(() => {
    return dataToBeLoad?.filter(({ items }) => {
      return items.some(({ link, pattern }) => {
        const splitParams = history?.location?.pathname?.split("/").at(-1);
        let newSubLink;
        if (link !== history?.location?.pathname) {
          newSubLink = history?.location?.pathname?.replace(
            `/${splitParams}`,
            ""
          );
        } else {
          newSubLink = history?.location?.pathname;
        }
        return link === newSubLink;
      });
    });
  }, [dataToBeLoad, history?.location?.pathname]);
  useEffect(() => {
    setValue(selectedModuleIndex);
  }, [selectedModuleIndex]);

  const { permissions, isSuperuser } = useAppSelector(loginSelector);
  return (
    <>
      <Box className="tabs-wrapper">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons={
            tabData && tabData[0]?.items?.length > 2 ? true : false
          }
          // allowScrollButtonsMobile
          aria-label="scrollable force tabs example"
          // TabIndicatorProps={{
          //   sx: { background: "none" },
          // }}
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              "&.Mui-disabled": { opacity: 0.3 },
            },
          }}
        >
          {tabData?.map((loadedTabData) => {
            const { items } = loadedTabData;
            return items?.map((tabToBeLoad, index: number) => {
              const { name, link, permission } = tabToBeLoad;
              let showSubMenu = permissions?.some(
                (element: any) => permission?.includes(element.codeName)
              );
              return isSuperuser || showSubMenu ? (
                <Tab
                  label={name}
                  {...a11yProps(index)}
                  key={index}
                  to={link}
                  component={Link}
                />
              ) : null;
            });
          })}
        </Tabs>
      </Box>
    </>
  );
};
export default memo(AppTabs);
