import { createSlice } from "@reduxjs/toolkit";
import { palikaInitialState } from "./types";

const initialState: palikaInitialState = {
  palikas: [],
  loadingPalika: false,
  loadingCreatePalika: false,
  loadingPalikaDistrict: false,
  defaultPalika: [],
  loadingDefaultPalika: false,
  edit: false,
  palika: null,
  count: 0,
  previous: "",
  next: "",
  districts: [],
};

export const Palika = createSlice({
  name: "PalikaReducer",
  initialState,
  reducers: {
    loadingPalika: (state) => {
      state.loadingPalika = true;
    },
    getPalikaRequest: (state, payload) => {
      state.loadingPalika = true;
    },
    getPalikaSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingPalika = false;
      state.palikas = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getPalikaFail: (state) => {
      state.loadingPalika = false;
    },
    getPalikaDistrictRequest: (state) => {
      state.loadingPalikaDistrict = true;
    },
    getPalikaDistrictSuccess: (state, { payload: { results } }) => {
      state.loadingPalikaDistrict = false;
      state.districts = results;
    },
    getPalikaDistrictFail: (state) => {
      state.loadingPalikaDistrict = false;
    },
    getDefaultPalikaRequest: (state) => {
      state.loadingDefaultPalika = true;
    },
    getDefaultPalikaSuccess: (state, { payload: { results } }) => {
      state.loadingDefaultPalika = false;
      state.defaultPalika = results;
    },
    getDefaultPalikaFail: (state) => {
      state.loadingDefaultPalika = false;
    },
    getPalikaNextRequest: (state, payload) => {
      state.loadingPalika = true;
    },
    getPalikaPreviousRequest: (state, payload) => {
      state.loadingPalika = true;
    },
    createPalikaRequest: (state, payload) => {
      state.loadingCreatePalika = true;
    },
    createPalikaSuccess: (state) => {
      state.loadingCreatePalika = false;
    },
    createPalikaFail: (state) => {
      state.loadingCreatePalika = false;
    },
    getPalikaByIdRequest: (state, payload) => {
      state.edit = true;
    },
    palikaEditSuccess: (state, { payload }) => {
      state.palika = payload;
    },
    palikaEditFail: (state) => {
      state.edit = false;
    },
    updatePalikaRequest: (state, payload) => {
      state.loadingCreatePalika = true;
    },
    updatePalikaSuccess: (state) => {
      state.loadingCreatePalika = false;
    },
    updatePalikaFail: (state) => {
      state.loadingCreatePalika = false;
    },
    //search handle in district
    loadingPalikaDistrictRequest: (state) => {
      state.loadingPalikaDistrict = true;
    },
    clearPalikaData: (state) => {
      state.edit = false;
      state.palika = null;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingPalika,
  getPalikaRequest,
  getPalikaSuccess,
  getPalikaFail,
  getPalikaNextRequest,
  getPalikaPreviousRequest,
  createPalikaRequest,
  createPalikaSuccess,
  createPalikaFail,
  palikaEditSuccess,
  updatePalikaRequest,
  updatePalikaSuccess,
  updatePalikaFail,
  clearPalikaData,
  getPalikaByIdRequest,
  palikaEditFail,
  getPalikaDistrictRequest,
  getPalikaDistrictSuccess,
  getPalikaDistrictFail,
  getDefaultPalikaRequest,
  getDefaultPalikaSuccess,
  getDefaultPalikaFail,
  loadingPalikaDistrictRequest,
} = Palika.actions;
export default Palika.reducer;
