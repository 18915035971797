import React from "react";
import { Switch } from "react-router-dom";
import {
  invCreditClearancePermission,
  invCustomerPermission,
  invCustomerWiseReportPermission,
  invDashboardAnalyticsPermission,
  invIrdSyncPermission,
  invItemUnitPermission,
  invManufacturerPermission,
  invMaterializedReportPermission,
  invPackingTypePermission,
  invPartyPaymentPermission,
  invPurchasePermission,
  invPurchaseReportPermission,
  invPurchaseReturnPermission,
  invSalePermission,
  invSaleReportPermission,
  invSaleReturnPermission,
  invStockAnalysisPermission,
  invSupplierPermission,
  invSupplierWiseReportPermission,
  invUserActivityLogReportPermission,
  invUserWiseReportPermission,
  itemCategoryPermission,
  itemPermission,
} from "../Component/Layout/Sidebar/Inventory/InvPermissionConstants";
import ProtectedRoutes from "./ProtectedRoutes";
import { lazyWithReload } from "./Utils";
const Inventory = lazyWithReload(() => import("../Pages/InventoryPages"));
const Manufacturer = lazyWithReload(
  () => import("../Pages/InventoryPages/Manufacturer/ManufacturerPages")
);
const ItemUnit = lazyWithReload(
  () => import("../Pages/InventoryPages/ItemUnit/ItemUnitPages")
);
const ItemCategory = lazyWithReload(
  () => import("../Pages/InventoryPages/ItemCategory/ItemCategoryPages")
);
const PackingType = lazyWithReload(
  () => import("../Pages/InventoryPages/PackingType/PackingTypePages")
);
const Item = lazyWithReload(
  () => import("../Pages/InventoryPages/Item/ItemPages")
);
const StockAnalysis = lazyWithReload(
  () => import("../Pages/InventoryPages/StockAnalysis/StockAnalysisPages")
);
const Purchase = lazyWithReload(
  () => import("../Pages/InventoryPages/Purchase/PurchasePages")
);
const PurchaseReturn = lazyWithReload(
  () =>
    import("../Pages/InventoryPages/PurchaseReturnPages/PurchaseReturnPages")
);
const Supplier = lazyWithReload(
  () => import("../Pages/InventoryPages/Supplier/SupplierPages")
);
const PurchaseReport = lazyWithReload(
  () =>
    import(
      "../Pages/InventoryPages/Purchase/PurchaseReport/PurchaseReportPages/Index"
    )
);

const SaleReport = lazyWithReload(
  () =>
    import("../Pages/InventoryPages/Purchase/SaleReport/SaleReportPages/Index")
);
const Sale = lazyWithReload(
  () => import("../Pages/InventoryPages/Sale/SalePages")
);
const SaleReturn = lazyWithReload(
  () => import("../Pages/InventoryPages/SaleReturn/SaleReturnPages")
);
const UserActivityLogReportInventory = lazyWithReload(
  () =>
    import(
      "../Pages/InventoryPages/IRDReport/UserActivityLogReport/UserActivityLogReportPages/Index"
    )
);

const MaterializedReportInventory = lazyWithReload(
  () =>
    import(
      "../Pages/InventoryPages/IRDReport/MaterializedReport/MaterializedReportPages/Index"
    )
);
const IRDSyncInventory = lazyWithReload(
  () => import("../Pages/InventoryPages/IRDReport/IRDSync/IRDSyncPages")
);
const Customer = lazyWithReload(
  () => import("../Pages/InventoryPages/Customer/CustomerPages")
);
const CustomerWiseReport = lazyWithReload(
  () =>
    import(
      "../Pages/InventoryPages/InventoryReport/CustomerWiseReport/CustomerWiseReportPages/Index"
    )
);
const StockAnalysisReport = lazyWithReload(
  () =>
    import(
      "../Pages/InventoryPages/InventoryReport/StockAnalysis/StockAnalysisReportPages/Index"
    )
);
const UserWiseReport = lazyWithReload(
  () =>
    import(
      "../Pages/InventoryPages/InventoryReport/UserWiseReport/UserWiseReportPages/Index"
    )
);
const SupplierWiseReport = lazyWithReload(
  () =>
    import(
      "../Pages/InventoryPages/InventoryReport/SupplierWiseSaleReport/SupplierWiseSaleReportPages/Index"
    )
);
const PartyPayment = lazyWithReload(
  () => import("../Pages/InventoryPages/PartyPayment/PartyPaymentPages")
);
const CreditClearance = lazyWithReload(
  () => import("../Pages/InventoryPages/CreditClearancePages/CreditClearance")
);
// inventory components imports

const dashboardAnalytics = lazyWithReload(
  () => import("../Pages/InventoryPages/Dashboard/DashboardAnalytics")
);
const InventoryRoutes = () => {
  return (
    <>
      <Switch>
        <ProtectedRoutes
          exact
          path="/inventory"
          component={Inventory}
          permission={[{ mainModule: true }]}
        />
        <ProtectedRoutes
          exact
          path="/inventory/item"
          component={Item}
          permission={itemPermission}
        />
        <ProtectedRoutes
          exact
          path="/inventory/manufacturer"
          component={Manufacturer}
          permission={invManufacturerPermission}
        />

        <ProtectedRoutes
          exact
          path="/inventory/item-unit"
          component={ItemUnit}
          permission={invItemUnitPermission}
        />
        <ProtectedRoutes
          exact
          path="/inventory/item-category"
          component={ItemCategory}
          permission={itemCategoryPermission}
        />
        <ProtectedRoutes
          exact
          path="/inventory/packing-type"
          component={PackingType}
          permission={invPackingTypePermission}
        />
        <ProtectedRoutes
          exact
          path="/inventory/purchase"
          component={Purchase}
          permission={invPurchasePermission}
        />
        <ProtectedRoutes
          exact
          path="/inventory/purchase-return"
          component={PurchaseReturn}
          permission={invPurchaseReturnPermission}
        />
        <ProtectedRoutes
          exact
          path="/inventory/supplier"
          component={Supplier}
          permission={invSupplierPermission}
        />
        <ProtectedRoutes
          exact
          path="/inventory/purchase-report"
          component={PurchaseReport}
          permission={invPurchaseReportPermission}
        />
        <ProtectedRoutes
          exact
          path="/inventory/sale-report"
          component={SaleReport}
          permission={invSaleReportPermission}
        />
        <ProtectedRoutes
          exact
          path="/inventory/sale"
          component={Sale}
          permission={invSalePermission}
        />
        <ProtectedRoutes
          exact
          path="/inventory/sale-return"
          component={SaleReturn}
          permission={invSaleReturnPermission}
        />
        <ProtectedRoutes
          exact
          path="/inventory/lab-ird-user-activity-report"
          component={UserActivityLogReportInventory}
          permission={invUserActivityLogReportPermission}
        />
        <ProtectedRoutes
          exact
          path="/inventory/lab-ird-materialized-report"
          component={MaterializedReportInventory}
          permission={invMaterializedReportPermission}
        />
        <ProtectedRoutes
          exact
          path="/inventory/ird-sync"
          component={IRDSyncInventory}
          permission={invIrdSyncPermission}
        />
        <ProtectedRoutes
          exact
          path="/inventory/customer"
          component={Customer}
          permission={invCustomerPermission}
        />
        <ProtectedRoutes
          exact
          path="/inventory/customer-wise-report"
          component={CustomerWiseReport}
          permission={invCustomerWiseReportPermission}
        />
        {/* <ProtectedRoutes
          exact
          path="/inventory/user-wise-report"
          component={UserWiseReport}
          permission={invUserWiseReportPermission}
        /> */}
        <ProtectedRoutes
          exact
          path="/inventory/supplier-wise-report"
          component={SupplierWiseReport}
          permission={invSupplierWiseReportPermission}
        />
        <ProtectedRoutes
          exact
          path="/inventory/party-payment"
          component={PartyPayment}
          permission={invPartyPaymentPermission}
        />
        <ProtectedRoutes
          exact
          path="/inventory/credit-clearance"
          component={CreditClearance}
          permission={invCreditClearancePermission}
        />
        <ProtectedRoutes
          exact
          path="/inventory/stock-analysis"
          component={StockAnalysis}
          permission={invStockAnalysisPermission}
        />
        <ProtectedRoutes
          exact
          path="/inventory/stock-analysis-report"
          component={StockAnalysisReport}
          permission={invStockAnalysisPermission}
        />
        <ProtectedRoutes
          exact
          path="/inventory/dashboard-analytics"
          component={dashboardAnalytics}
          permission={invDashboardAnalyticsPermission}
        />
      </Switch>
    </>
  );
};

export default InventoryRoutes;
