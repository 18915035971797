import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  IRDSyncInventorys: [],
  edit: false,
  IRDSyncInventory: null,
  loadingIRDSyncInventorys: false,
  count: 0,
  next: "",
  previous: "",
};

export const getIRDSyncInventoryRequest = createAction(
  "getIRDSyncInventoryRequest",
  function prepare({ rowsPerPage, page, filterData }) {
    return {
      payload: {
        rowsPerPage,
        page,
        filterData,
      },
    };
  }
);

// get next request
export const getIRDSyncInventoryNextRequest = createAction(
  "getIRDSyncInventoryNextRequest",
  function prepare(next) {
    return {
      payload: next,
    };
  }
);
//get previous request
export const getIRDSyncInventoryPreviousRequest = createAction(
  "getIRDSyncInventoryPreviousRequest",
  function prepare(previous) {
    return {
      payload: previous,
    };
  }
);
export const IRDSyncInventory = createSlice({
  name: "IRDSyncInventoryReducer",
  initialState,
  reducers: {
    loadingIRDSyncInventory: (state) => {
      state.loadingIRDSyncInventory = true;
    },
    getIRDSyncInventorySuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingIRDSyncInventory = false;
      state.IRDSyncInventorys = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getIRDSyncInventoryFail: (state) => {
      state.loadingIRDSyncInventory = false;
    },
    postIRDSyncInventoryRequest: (state) => {
      state.loadingUploadIRDSyncInventory = true;
    },
    postIRDSyncInventoryUploadSuccess: (state) => {
      state.loadingUploadIRDSyncInventory = false;
    },

    clearIRDSyncInventoryData: (state) => {
      state.edit = false;
      state.IRDSyncInventory = null;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingIRDSyncInventory,
  getIRDSyncInventorySuccess,
  getIRDSyncInventoryFail,
  clearIRDSyncInventoryData,
  postIRDSyncInventoryUploadSuccess,
  postIRDSyncInventoryRequest,
} = IRDSyncInventory.actions;
export default IRDSyncInventory.reducer;
