import { createSlice, createAction } from "@reduxjs/toolkit";
const initialState: any = {
  taxGroups: [],
  loadingTaxGroup: false,
  loadingCreateTaxGroup: false,
  edit: false,
  taxGroup: null,
  count: 0,
  previous: "",
  next: "",
};

export const TaxGroup = createSlice({
  name: "TaxGroupReducer",
  initialState,
  reducers: {
    loadingTaxGroup: (state) => {
      state.loadingTaxGroup = true;
    },
    getTaxGroupRequest: (state, payload) => {
      state.loadingTaxGroup = true;
    },
    getTaxGroupSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingTaxGroup = false;
      state.taxGroups = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getTaxGroupFail: (state) => {
      state.loadingTaxGroup = false;
    },
    getTaxGroupNextRequest: (state, payload) => {
      state.loadingTaxGroup = true;
    },
    getTaxGroupPreviousRequest: (state, payload) => {
      state.loadingTaxGroup = true;
    },
    createTaxGroupRequest: (state, payload) => {
      state.loadingCreateTaxGroup = true;
      state.closeModal = true;
    },
    createTaxGroupSuccess: (state) => {
      state.loadingCreateTaxGroup = false;
    },
    createTaxGroupFail: (state) => {
      state.loadingCreateTaxGroup = false;
    },
    getTaxGroupByIdRequest: (state, payload) => {
      state.edit = true;
    },
    taxGroupEditSuccess: (state, { payload }) => {
      state.taxGroup = payload;
    },
    taxGroupEditFail: (state) => {
      state.edit = false;
    },
    updateTaxGroupRequest: (state, payload) => {
      state.loadingCreateTaxGroup = true;
    },
    updateTaxGroupSuccess: (state) => {
      state.loadingCreateTaxGroup = false;
    },
    updateTaxGroupFail: (state) => {
      state.loadingCreateTaxGroup = false;
    },
    clearTaxGroupData: (state) => {
      state.edit = false;
      state.taxGroup = null;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingTaxGroup,
  getTaxGroupRequest,
  getTaxGroupSuccess,
  getTaxGroupFail,
  getTaxGroupNextRequest,
  getTaxGroupPreviousRequest,
  createTaxGroupRequest,
  createTaxGroupSuccess,
  createTaxGroupFail,
  taxGroupEditSuccess,
  updateTaxGroupRequest,
  updateTaxGroupSuccess,
  updateTaxGroupFail,
  clearTaxGroupData,
  getTaxGroupByIdRequest,
  taxGroupEditFail,
} = TaxGroup.actions;
export default TaxGroup.reducer;
