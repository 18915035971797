import { axiosInstance } from "../../../AppUtils/Utils/axios";

import { branch, credential, user } from "./types";

const loginModURL = "api/v1/user-app";

export const getLogin = (body: credential) =>
  axiosInstance.post<user>(`${loginModURL}/login`, body);
export const getAllBranches = () => axiosInstance.get<branch[]>("branches");
//for logout
export const logout = (body: any) =>
  axiosInstance.post(`${loginModURL}/logout`, body);
//for reset password
export const resetPassword = (body: string) =>
  axiosInstance.post(`${loginModURL}/password-reset/`, body);
export const confirmPassword = (body: string) =>
  axiosInstance.post(`${loginModURL}/password-reset/confirm/`, body);
export const changePassword = (id: number, body: string) =>
  axiosInstance.patch(`${loginModURL}/change-password/${id}`, body);
