import {
  loadingJournalEntry,
  getJournalEntryRequest,
  getJournalEntryNextRequest,
  getJournalEntryPreviousRequest,
  getJournalEntrySuccess,
  getJournalEntryFail,
  createJournalEntryRequest,
  createJournalEntrySuccess,
  createJournalEntryFail,
  clearJournalEntryData,
  getAccountRequest,
  getAccountSuccess,
  getAccountFail,
  getJournalEntryByIdRequest,
  getJournalEntryByIdRequestSuccess,
  getJournalEntryByIdRequestFail,

  // Delete Journal Entry
  deleteJournalEntryRequest,
  deleteJournalEntrySuccess,
  deleteJournalEntryFail,
  // Update Journal Entry
  updateJournalEntryRequest,
  updateJournalEntrySuccess,
  updateJournalEntryFail,
} from "./journalEntrySlice";
import { mergeMap } from "rxjs";
import {
  createJournalEntry,
  getJournalEntry,
  getAccount,
  getJournalEntryById,
  deleteJournalEntry,
  updateJournalEntry,
} from "./api";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";

export const controller = new AbortController();

// get AccountHead epic
const getJournalEntryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getJournalEntryRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingJournalEntry());
      try {
        const response = await getJournalEntry(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getJournalEntrySuccess(action?.payload)
        : getJournalEntryFail()
    )
  );
//get next
const getJournalEntryNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getJournalEntryNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingJournalEntry());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getJournalEntrySuccess(action?.payload)
        : getJournalEntryFail()
    )
  );

//get previous
const getJournalEntryPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getJournalEntryPreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingJournalEntry());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getJournalEntrySuccess(action?.payload)
        : getJournalEntryFail()
    )
  );
//create AccountHead epic
const createJournalEntryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createJournalEntryRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      const {
        journalDateAd,
        journalDateBs,
        journalVoucher,
        reference,
        journalEntryDetails,
        note,
      } = values;
      try {
        const body = JSON.stringify({
          journalDateAd,
          journalDateBs,
          journalVoucher,
          reference,
          journalEntryDetails,
          note,
        });
        const response = await createJournalEntry(body);
        if (response) {
          dispatch(getJournalEntryRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(clearJournalEntryData());
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? createJournalEntrySuccess()
        : createJournalEntryFail();
    })
  );

const getAccountEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAccountRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getAccount(action.payload);

        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getAccountSuccess(action?.payload) : getAccountFail()
    )
  );

const geJournalEntryByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getJournalEntryByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getJournalEntryById(action.payload?.id);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getJournalEntryByIdRequestSuccess(action?.payload)
        : getJournalEntryByIdRequestFail()
    )
  );

//delete Journal Entry
const deleteJournalEntryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(deleteJournalEntryRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      const { id } = values;
      try {
        const response = await deleteJournalEntry(id);
        if (response) {
          dispatch(getJournalEntryRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.deleteMessage));
          dispatch(clearJournalEntryData());
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.deleteFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? deleteJournalEntrySuccess()
        : deleteJournalEntryFail();
    })
  );

// update Journal Entry

const updateJournalEntryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateJournalEntryRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      const {
        journalDateAd,
        journalDateBs,
        journalVoucher,
        reference,
        journalEntryDetails,
        note,
        remarks,
      } = values;
      try {
        const body = JSON.stringify({
          journalDateAd,
          journalDateBs,
          journalVoucher,
          reference,
          journalEntryDetails,
          note,
          remarks,
        });
        const response = await updateJournalEntry(id, body);
        if (response) {
          dispatch(getJournalEntryRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(clearJournalEntryData());
          dispatch(closeModal());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateJournalEntrySuccess() : updateJournalEntryFail()
    )
  );

export const journalEntryEpics = combineEpics(
  getJournalEntryEpic,
  createJournalEntryEpic,
  getJournalEntryNext,
  getJournalEntryPrevious,
  getAccountEpic,
  geJournalEntryByIdEpic,
  deleteJournalEntryEpic,
  updateJournalEntryEpic
);
