import accountingSetupIcon from "../../../../Assets/icons/AccountingIcons/accounting.svg";
import aaccountingGroupSetupIcon from "../../../../Assets/icons/AccountingIcons/accountinggroup.svg";
import balanceSheetSetupIcon from "../../../../Assets/icons/AccountingIcons/balancesheet.svg";
import incomeStatementSetupIcon from "../../../../Assets/icons/AccountingIcons/incomestatement.svg";
import journalSetupIcon from "../../../../Assets/icons/AccountingIcons/journal.svg";
import ledgerSetupIcon from "../../../../Assets/icons/AccountingIcons/ledger.svg";
//sidebar images
import reportingSetupIcon from "../../../../Assets/icons/AccountingIcons/reporting.svg";
import trialBalanceSetupIcon from "../../../../Assets/icons/AccountingIcons/trialbalance.svg";
import {
  accGroupPermission,
  accJournalPermission,
  accJournalEntryPermission,
  accLedgerPermission,
  accTrialBalancePermission,
  accBalanceSheetPermission,
  accIncomeStatementPermission,
} from "./AccountingPermissionConstants";

export const accSidebarData = [
  {
    name: "Accounting",
    customIcon: accountingSetupIcon,
    items: [
      {
        name: "Group",
        image: aaccountingGroupSetupIcon,
        link: "/accounting/group",
        permission: accGroupPermission,
      },
      // Currently removed
      // {
      //   name: "Journal",
      //   image: journalSetupIcon,
      //   link: "/accounting/journal-voucher",
      //   permission: accJournalPermission,
      // },
      {
        name: "Journal Entry",
        image: journalSetupIcon,
        link: "/accounting/journal-entry",
        permission: accJournalEntryPermission,
      },
    ],
  },

  {
    name: "Reporting",
    customIcon: reportingSetupIcon,
    items: [
      {
        name: "Ledger",
        image: ledgerSetupIcon,
        link: "/accounting/ledger",
        permission: accLedgerPermission,
      },
      {
        name: "Trial Balance",
        image: trialBalanceSetupIcon,
        link: "/accounting/trial-balance",
        permission: accTrialBalancePermission,
      },
      {
        name: "Income Statement",
        image: incomeStatementSetupIcon,
        link: "/accounting/income-statement",
        permission: accIncomeStatementPermission,
      },
      {
        name: "Balance Sheet",
        image: balanceSheetSetupIcon,
        link: "/accounting/balance-sheet",
        permission: accBalanceSheetPermission,
      },
    ],
  },
];
