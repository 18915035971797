import {
  getBankRequest,
  getBankFail,
  getBankSuccess,
  createBankFail,
  createBankRequest,
  createBankSuccess,
  updateBankFail,
  updateBankSuccess,
  clearAllData,
  getBankNextRequest,
  getBankPreviousRequest,
  updateBankRequest,
  getBankByIdRequest,
  bankEditSuccess,
  bankEditFail,
} from "./BankSlice";
import { mergeMap } from "rxjs";
import { getBank, createBank, updateBank, getBankById } from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();

//get Bank
const getBankEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getBankRequest.match),
    mergeMap(async (action: any) => {
      try {
        const response = await getBank(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getBankSuccess(action?.payload) : getBankFail()
    )
  );

//get bank by id epic
const getBankByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getBankByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getBankById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? bankEditSuccess(action.payload) : bankEditFail()
    )
  );

//create
const createBankEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createBankRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          body.append(`${key}`, String(value));
        }
        const response = await createBank(body);
        if (response) {
          dispatch(getBankRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? createBankSuccess() : createBankFail();
    })
  );

//update
const updateBankEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateBankRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page, search } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          body.append(`${key}`, String(value));
        }
        const response = await updateBank(body, id);
        if (response) {
          dispatch(getBankRequest({ rowsPerPage, page, search }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearAllData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? updateBankSuccess() : updateBankFail();
    })
  );

//get next
const getBankNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getBankNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? getBankSuccess(action?.payload) : getBankFail();
    })
  );

//get previous
const getBankPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getBankPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getBankSuccess(action?.payload) : getBankFail()
    )
  );

export const bankEpic = combineEpics(
  getBankEpic,
  createBankEpic,
  updateBankEpic,
  getBankNext,
  getBankPrevious,
  getBankByIdEpic
);
