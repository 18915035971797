import { createSlice } from "@reduxjs/toolkit";
import { ledgerReportInitialState } from "./types";

const initialState: ledgerReportInitialState = {
  accounts: [],
  loadingGenerateReport: false,
  loadingLedgerReportAccount: false,
  ledgerReportDetails: [],
  ledgerReportSummary: [],
  count: 0,
  previous: "",
  next: "",
};

export const LedgerReportSlice = createSlice({
  name: "LedgerReportReducer",
  initialState,
  reducers: {
    loadingLedgerReport: (state) => {
      state.loadingGenerateReport = true;
    },
    //detail
    getLedgerReportDetailRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getLedgerReportDetailSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.ledgerReportSummary = [];
      state.loadingGenerateReport = false;
      state.ledgerReportDetails = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getLedgerReportDetailFail: (state) => {
      state.loadingGenerateReport = false;
    },
    // summary
    getLedgerReportSummaryRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getLedgerReportSummarySuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.ledgerReportDetails = [];
      state.loadingGenerateReport = false;
      state.ledgerReportSummary = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getLedgerReportSummaryFail: (state) => {
      state.loadingGenerateReport = false;
    },
    //account
    loadingLedgerReportAccountSearch: (state) => {
      state.loadingLedgerReportAccount = true;
    },
    getLedgerReportAccountRequest: (state) => {
      state.loadingLedgerReportAccount = true;
    },
    getLedgerReportAccountSuccess: (state, { payload: { results } }) => {
      state.loadingLedgerReportAccount = false;
      state.accounts = results;
    },
    getLedgerReportAccountFail: (state) => {
      state.loadingLedgerReportAccount = false;
    },
    //next
    getLedgerReportDetailsNextRequest: (state, payload) => {
      state.loadingGenerateReport = true;
    },
    getLedgerReportSummaryNextRequest: (state, payload) => {
      state.loadingGenerateReport = true;
    },
    //previous
    getLedgerReportDetailsPreviousRequest: (state, payload) => {
      state.loadingGenerateReport = true;
    },
    getLedgerReportSummaryPreviousRequest: (state, payload) => {
      state.loadingGenerateReport = true;
    },

    //clear
    clearLedgerReportAccountData: (state) => {
      state.accounts = [];
    },
    clearLedgerReportSummary: (state) => {
      state.ledgerReportSummary = [];
    },
    clearLedgerReportDetails: (state) => {
      state.ledgerReportDetails = [];
    },
  },
});

export const {
  //details
  loadingLedgerReport,
  getLedgerReportDetailRequest,
  getLedgerReportDetailSuccess,
  getLedgerReportDetailFail,
  //summary
  getLedgerReportSummaryRequest,
  getLedgerReportSummarySuccess,
  getLedgerReportSummaryFail,
  //account
  loadingLedgerReportAccountSearch,
  getLedgerReportAccountRequest,
  getLedgerReportAccountSuccess,
  getLedgerReportAccountFail,
  //next
  getLedgerReportDetailsNextRequest,
  getLedgerReportSummaryNextRequest,
  //previous
  getLedgerReportDetailsPreviousRequest,
  getLedgerReportSummaryPreviousRequest,
  //clear
  clearLedgerReportAccountData,
  clearLedgerReportSummary,
  clearLedgerReportDetails,
} = LedgerReportSlice.actions;
export default LedgerReportSlice.reducer;
