import { createSlice, createAction } from "@reduxjs/toolkit";
import { UserActivityLogReportInventoryInitialState } from "./types";
const initialState: UserActivityLogReportInventoryInitialState = {
  suppliers: [],
  users: [],
  items: [],
  purchaseDetails: [],
  //loading
  loadingGenerateReport: false,
  loadingUserActivityLogReportInventorySupplier: false,
  loadingUserActivityLogReportInventoryItem: false,
  loadingUserActivityLogReportInventoryUser: false,
  loadingUserActivityLogReportInventoryDetail: false,
  count: 0,
  previous: "",
  next: "",
};

export const UserActivityLogReportInventory = createSlice({
  name: "userActivityLogReportInventoryReducer",
  initialState,
  reducers: {
    //loading
    loadingUserActivityLogReportInventoryDetail: (state) => {
      state.loadingUserActivityLogReportInventoryDetail = true;
    },
    //external reducer request
    getUserActivityLogReportInventoryDetailRequest: (state, { payload }) => {
      state.loadingUserActivityLogReportInventoryDetail = true;
    },
    getUserActivityLogReportInventorySupplierRequest: (state) => {
      state.loadingUserActivityLogReportInventorySupplier = true;
    },
    getUserActivityLogReportInventoryItemRequest: (state) => {
      state.loadingUserActivityLogReportInventoryItem = true;
    },
    getUserActivityLogReportInventoryUserRequest: (state) => {
      state.loadingUserActivityLogReportInventoryUser = true;
    },
    getUserActivityLogReportInventoryAllSummaryRequest: (
      state,
      { payload }
    ) => {
      state.loadingGenerateReport = true;
    },
    getUserActivityLogReportInventoryAllDetailRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getUserActivityLogReportInventoryQuickSummaryRequest: (
      state,
      { payload }
    ) => {
      state.loadingGenerateReport = true;
    },
    getUserActivityLogReportInventoryQuickDetailRequest: (
      state,
      { payload }
    ) => {
      state.loadingGenerateReport = true;
    },
    getUserActivityLogReportInventoryNextRequest: (state, payload) => {
      state.loadingUserActivityLogReportInventoryDetail = true;
    },
    getUserActivityLogReportInventoryPreviousRequest: (state, payload) => {
      state.loadingUserActivityLogReportInventoryDetail = true;
    },
    //external get reducer
    getUserActivityLogReportInventoryDetailSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.purchaseDetails = results;
      state.loadingUserActivityLogReportInventoryDetail = false;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getUserActivityLogReportInventoryDetailFail: (state) => {
      state.loadingUserActivityLogReportInventoryDetail = false;
    },
    getUserActivityLogReportInventoryUserSuccess: (
      state,
      { payload: { results } }
    ) => {
      state.loadingUserActivityLogReportInventoryUser = false;
      state.users = results;
    },
    getUserActivityLogReportInventoryUserFail: (state) => {
      state.loadingUserActivityLogReportInventoryUser = false;
    },
    getUserActivityLogReportInventorySupplierSuccess: (
      state,
      { payload: { results } }
    ) => {
      state.loadingUserActivityLogReportInventorySupplier = false;
      state.suppliers = results;
    },
    getUserActivityLogReportInventorySupplierFail: (state) => {
      state.loadingUserActivityLogReportInventorySupplier = false;
    },
    getUserActivityLogReportInventoryItemSuccess: (
      state,
      { payload: { results } }
    ) => {
      state.loadingUserActivityLogReportInventoryItem = false;
      state.items = results;
    },
    getUserActivityLogReportInventoryItemFail: (state) => {
      state.loadingUserActivityLogReportInventoryItem = false;
    },
    getUserActivityLogReportInventoryAllSummarySuccess: (
      state,
      { payload: { results } }
    ) => {
      state.loadingGenerateReport = false;
      state.purchaseDetails = results;
    },
    getUserActivityLogReportInventoryAllSummaryFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getUserActivityLogReportInventoryQuickSummarySuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingGenerateReport = false;
      state.purchaseDetails = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getUserActivityLogReportInventoryQuickSummaryFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getUserActivityLogReportInventoryAllDetailSuccess: (
      state,
      { payload: { results } }
    ) => {
      state.loadingGenerateReport = false;
      state.purchaseDetails = results;
    },
    getUserActivityLogReportInventoryAllDetailFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getUserActivityLogReportInventoryQuickDetailSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingGenerateReport = false;
      state.purchaseDetails = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getUserActivityLogReportInventoryQuickDetailFail: (state) => {
      state.loadingGenerateReport = false;
    },

    clearUserActivityLogReportInventoryData: (state) => {
      state.suppliers = [];
      state.users = [];
      state.items = [];
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  //reducerReport
  getUserActivityLogReportInventoryDetailRequest,
  getUserActivityLogReportInventorySupplierRequest,
  getUserActivityLogReportInventoryItemRequest,
  getUserActivityLogReportInventoryUserRequest,
  getUserActivityLogReportInventoryAllSummaryRequest,
  getUserActivityLogReportInventoryAllDetailRequest,
  getUserActivityLogReportInventoryQuickSummaryRequest,
  getUserActivityLogReportInventoryQuickDetailRequest,
  getUserActivityLogReportInventoryPreviousRequest,
  getUserActivityLogReportInventoryNextRequest,
  //reducer get data
  getUserActivityLogReportInventoryDetailSuccess,
  getUserActivityLogReportInventoryDetailFail,
  getUserActivityLogReportInventoryItemSuccess,
  getUserActivityLogReportInventoryItemFail,
  getUserActivityLogReportInventorySupplierSuccess,
  getUserActivityLogReportInventorySupplierFail,
  getUserActivityLogReportInventoryUserSuccess,
  getUserActivityLogReportInventoryUserFail,
  getUserActivityLogReportInventoryQuickDetailSuccess,
  getUserActivityLogReportInventoryQuickDetailFail,
  getUserActivityLogReportInventoryAllDetailSuccess,
  getUserActivityLogReportInventoryAllDetailFail,
  getUserActivityLogReportInventoryQuickSummarySuccess,
  getUserActivityLogReportInventoryQuickSummaryFail,
  getUserActivityLogReportInventoryAllSummarySuccess,
  getUserActivityLogReportInventoryAllSummaryFail,
  //loading
  loadingUserActivityLogReportInventoryDetail,
  //clear
  clearUserActivityLogReportInventoryData,
} = UserActivityLogReportInventory.actions;
export default UserActivityLogReportInventory.reducer;
