import { axiosInstance } from "../../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../../AppUtils/Utils/globalTypes";
import { MaterializedReportInventory } from "./types";

const MaterializedReportInventoryModURL =
  "/api/v1/inventory-ird-report-app/materialized-view-report";

export const getMaterializedReportInventorySupplier = () =>
  axiosInstance.get(
    `${MaterializedReportInventoryModURL}/supplier?offset=0&limit=${0}`
  );
export const getMaterializedReportInventoryUser = () =>
  axiosInstance.get(
    `${MaterializedReportInventoryModURL}/user?offset=0&limit=${0}`
  );
export const getMaterializedReportInventoryItem = () =>
  axiosInstance.get(
    `${MaterializedReportInventoryModURL}/item?offset=0&limit=${0}`
  );
export const getMaterializedReportInventoryAllReport = (data: any) => {
  const { updatedValue } = data;

  const {
    item,
    summary,
    detail,
    supplier,
    user,
    purchaseType,
    startDateAd,
    endDateAd,
  } = updatedValue;
  return axiosInstance.get(
    `${MaterializedReportInventoryModURL}?offset=0&limit=${0}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }&date_after=${startDateAd}&date_before=${endDateAd}`
  );
};
export const getMaterializedReportInventoryQuickReport = (data: any) => {
  const { rowsPerPage, page, updatedValue } = data;
  const {
    item,
    summary,
    detail,
    supplier,
    user,
    purchaseType,
    startDateAd,
    endDateAd,
  } = updatedValue;
  return axiosInstance.get(
    `${MaterializedReportInventoryModURL}?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }&date_after=${startDateAd}&date_before=${endDateAd}`
  );
};

export const searchMaterializedReportInventory = ({
  rowsPerPage,
  search,
  updatedValue,
}: any) => {
  const {
    item,
    summary,
    detail,
    supplier,
    user,
    purchaseType,
    startDateAd,
    endDateAd,
  } = updatedValue;

  return `${MaterializedReportInventoryModURL}?offset=0&limit=${rowsPerPage}&search=${search}&date_after=${startDateAd}&date_before=${endDateAd}`;
};
