import { mergeMap } from "rxjs";
import * as API from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import { getNext, getPrevious } from "../../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../../AppUtils/Utils/globalTypes";
import { alertErrorAction } from "../../../../CommonAppRedux/CommonAppSlice";
import messages from "../../../../../AppUtils/Utils/validationConstants";
import {
  getLedgerReportAccountFail,
  getLedgerReportAccountRequest,
  getLedgerReportAccountSuccess,
  getLedgerReportDetailFail,
  getLedgerReportDetailRequest,
  getLedgerReportDetailSuccess,
  getLedgerReportDetailsNextRequest,
  getLedgerReportDetailsPreviousRequest,
  getLedgerReportSummaryFail,
  getLedgerReportSummaryNextRequest,
  getLedgerReportSummaryPreviousRequest,
  getLedgerReportSummaryRequest,
  getLedgerReportSummarySuccess,
} from "./ledgerReportSlice";
export const controller = new AbortController();

//account
const getLedgerReportAccountEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getLedgerReportAccountRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getLedgerReportAccount();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getLedgerReportAccountSuccess(action?.payload)
        : getLedgerReportAccountFail()
    )
  );

//get ledge report summary
const getLedgerReportSummaryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getLedgerReportSummaryRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getledgerQuickReport(action?.payload);
        response?.data?.count === 0 &&
          dispatch(alertErrorAction(messages?.reportData));
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getLedgerReportSummarySuccess(action?.payload)
        : getLedgerReportSummaryFail()
    )
  );
//get ledge report details
const getLedgerReportDetailsEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getLedgerReportDetailRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getledgerQuickReport(action?.payload);
        response?.data?.count === 0 &&
          dispatch(alertErrorAction(messages?.reportData));
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getLedgerReportDetailSuccess(action?.payload)
        : getLedgerReportDetailFail()
    )
  );

//get next
const getLedgerReportDetailsNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getLedgerReportDetailsNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getLedgerReportDetailSuccess(action?.payload)
        : getLedgerReportDetailFail()
    )
  );

const getLedgerReportSummaryNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getLedgerReportSummaryNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getLedgerReportSummarySuccess(action?.payload)
        : getLedgerReportSummaryFail()
    )
  );
//get previous
const getLedgerReportSummaryPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getLedgerReportSummaryPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getLedgerReportSummarySuccess(action?.payload)
        : getLedgerReportSummaryFail()
    )
  );

const getLedgerReportDetailsPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getLedgerReportDetailsPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getLedgerReportDetailSuccess(action?.payload)
        : getLedgerReportDetailFail()
    )
  );
export const ledgerReportEpics = combineEpics(
  getLedgerReportAccountEpic,
  getLedgerReportDetailsNext,
  getLedgerReportSummaryNext,
  getLedgerReportDetailsPrevious,
  getLedgerReportSummaryPrevious,
  getLedgerReportSummaryEpic,
  getLedgerReportDetailsEpic
);
