import {
  getStockAnalysisFail,
  getStockAnalysisSuccess,
  loadingStockAnalysis,
  getStockAnalysisRequest,
  getStockAnalysisNextRequest,
  getStockAnalysisPreviousRequest,
  postStockAnalysisRequest,
  postStockAnalysisUploadSuccess,
} from "./stockAnalysisSlice";
import { mergeMap } from "rxjs";
import { getStockAnalysis, postStockAnalysisApi } from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  alertErrorAction,
  alertSuccessAction,
} from "../../../CommonAppRedux/CommonAppSlice";
import {
  defaultRowsPerPage,
  defaultPage,
} from "../../../../AppUtils/Utils/globalConstant";

export const controller = new AbortController();
//get StockAnalysisType epic
const getStockAnalysisEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getStockAnalysisRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingStockAnalysis());
      try {
        const response = await getStockAnalysis(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getStockAnalysisSuccess(action?.payload)
        : getStockAnalysisFail()
    )
  );
//get next
const getStockAnalysisNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getStockAnalysisNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingStockAnalysis());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getStockAnalysisSuccess(action?.payload)
        : getStockAnalysisFail()
    )
  );
//get previous
const getStockAnalysisPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getStockAnalysisPreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingStockAnalysis());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getStockAnalysisSuccess(action?.payload)
        : getStockAnalysisFail()
    )
  );
const postStockAnalysis = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(postStockAnalysisRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await postStockAnalysisApi();
        dispatch(alertSuccessAction("IRD Sync Post"));
        dispatch(
          getStockAnalysisRequest({
            rowsPerPage: defaultRowsPerPage,
            page: defaultPage,
          })
        );

        return { payload: response };
      } catch (e) {
        alert("fail");
        dispatch(alertErrorAction("IRD Sync Not Post"));
        return { error: e };
      }
    }),
    map((action) => postStockAnalysisUploadSuccess())
  );

export const stockAnalysisEpics = combineEpics(
  getStockAnalysisEpic,
  getStockAnalysisNext,
  getStockAnalysisPrevious,
  postStockAnalysis
);
