import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  countries: [],
  loadingCountry: false,
  loadingCreateCountry: false,
  edit: false,
  country: null,
  count: 0,
  previous: "",
  next: "",
};

export const Country = createSlice({
  name: "CountryReducer",
  initialState,
  reducers: {
    loadingCountry: (state) => {
      state.loadingCountry = true;
    },
    getCountryRequest: (state, payload) => {
      state.loadingCountry = true;
    },
    getCountrySuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingCountry = false;
      state.countries = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getCountryFail: (state) => {
      state.loadingCountry = false;
    },
    getCountryNextRequest: (state, payload) => {
      state.loadingCountry = true;
    },
    getCountryPreviousRequest: (state, payload) => {
      state.loadingCountry = true;
    },
    createCountryRequest: (state, payload) => {
      state.loadingCreateCountry = true;
      state.closeModal = true;
    },
    createCountrySuccess: (state) => {
      state.loadingCreateCountry = false;
    },
    createCountryFail: (state) => {
      state.loadingCreateCountry = false;
    },
    getCountryByIdRequest: (state, payload) => {
      state.edit = true;
    },
    countryEditSuccess: (state, { payload }) => {
      state.country = payload;
    },
    countryEditFail: (state) => {
      state.edit = false;
    },
    updateCountryRequest: (state, payload) => {
      state.loadingCreateCountry = true;
    },
    updateCountrySuccess: (state) => {
      state.loadingCreateCountry = false;
    },
    updateCountryFail: (state) => {
      state.loadingCreateCountry = false;
    },
    clearCountryData: (state) => {
      state.edit = false;
      state.country = null;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingCountry,
  getCountryRequest,
  getCountrySuccess,
  getCountryFail,
  getCountryNextRequest,
  getCountryPreviousRequest,
  createCountryRequest,
  createCountrySuccess,
  createCountryFail,
  countryEditSuccess,
  updateCountryRequest,
  updateCountrySuccess,
  updateCountryFail,
  clearCountryData,
  getCountryByIdRequest,
  countryEditFail,
} = Country.actions;
export default Country.reducer;
