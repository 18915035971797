//IPD permission constants
export const accGroupPermission = [
  "view_acc_group",
  "add_acc_group",
  "edit_acc_group",
];
export const accJournalPermission = [
  "view_acc_journal",
  "add_acc_journal",
  "edit_acc_journal",
];
export const accJournalEntryPermission = [
  "view_acc_journal",
  "add_acc_journal",
  "edit_acc_journal",
];
export const accLedgerPermission = [
  "view_acc_ledger",
  "add_acc_ledger",
  "edit_acc_ledger",
];
export const accBalanceSheetPermission = [
  "view_acc_balance_sheet",
  "add_acc_balance_sheet",
  "edit_acc_balance_sheet",
];
export const accIncomeStatementPermission = [
  "view_acc_income_statement",
  "add_acc_income_statement",
  "edit_acc_income_statement",
];
export const accTrialBalancePermission = [
  "view_acc_trial_balance",
  // "add_acc_trial_balance",
  // "edit_acc_trial_balance",
];
