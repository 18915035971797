import { createSlice } from "@reduxjs/toolkit";
import { districtInitailState } from "./types";

const initialState: districtInitailState = {
  districts: [],
  loadingDistrict: false,
  loadingCreateDistrict: false,
  loadingDistrictProvince: false,
  edit: false,
  district: null,
  count: 0,
  previous: "",
  next: "",
  provinces: [],
};

export const District = createSlice({
  name: "DistrictReducer",
  initialState,
  reducers: {
    loadingDistrict: (state) => {
      state.loadingDistrict = true;
    },
    getDistrictRequest: (state, payload) => {
      state.loadingDistrict = true;
    },
    getDistrictSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingDistrict = false;
      state.districts = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getDistrictFail: (state) => {
      state.loadingDistrict = false;
    },
    getDistrictProvinceRequest: (state) => {
      state.loadingDistrictProvince = true;
    },
    getDistrictProvinceSuccess: (state, { payload: { results } }) => {
      state.loadingDistrictProvince = false;
      state.provinces = results;
    },
    getDistrictProvinceFail: (state) => {
      state.loadingDistrictProvince = false;
    },
    getDistrictNextRequest: (state, payload) => {
      state.loadingDistrict = true;
    },
    getDistrictPreviousRequest: (state, payload) => {
      state.loadingDistrict = true;
    },
    createDistrictRequest: (state, payload) => {
      state.loadingCreateDistrict = true;
    },
    createDistrictSuccess: (state) => {
      state.loadingCreateDistrict = false;
    },
    createDistrictFail: (state) => {
      state.loadingCreateDistrict = false;
    },
    getDistrictByIdRequest: (state, payload) => {
      state.edit = true;
    },
    districtEditSuccess: (state, { payload }) => {
      state.district = payload;
    },
    districtEditFail: (state) => {
      state.edit = false;
    },
    updateDistrictRequest: (state, payload) => {
      state.loadingCreateDistrict = true;
    },
    updateDistrictSuccess: (state) => {
      state.loadingCreateDistrict = false;
    },
    updateDistrictFail: (state) => {
      state.loadingCreateDistrict = false;
    },
    clearDistrictData: (state) => {
      state.edit = false;
      state.district = null;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingDistrict,
  getDistrictRequest,
  getDistrictSuccess,
  getDistrictFail,
  getDistrictNextRequest,
  getDistrictPreviousRequest,
  createDistrictRequest,
  createDistrictSuccess,
  createDistrictFail,
  districtEditSuccess,
  updateDistrictRequest,
  updateDistrictSuccess,
  updateDistrictFail,
  clearDistrictData,
  getDistrictByIdRequest,
  districtEditFail,
  getDistrictProvinceRequest,
  getDistrictProvinceSuccess,
  getDistrictProvinceFail,
} = District.actions;
export default District.reducer;
