import {
  getStockAnalysisReportInventorySupplierRequest,
  getStockAnalysisReportInventoryItemRequest,
  getStockAnalysisReportInventoryUserRequest,
  getStockAnalysisReportInventoryAllSummaryRequest,
  getStockAnalysisReportInventoryAllDetailRequest,
  getStockAnalysisReportInventoryQuickSummaryRequest,
  getStockAnalysisReportInventoryQuickDetailRequest,
  //reducer get data
  getStockAnalysisReportInventoryItemSuccess,
  getStockAnalysisReportInventoryItemFail,
  getStockAnalysisReportInventorySupplierSuccess,
  getStockAnalysisReportInventorySupplierFail,
  getStockAnalysisReportInventoryUserSuccess,
  getStockAnalysisReportInventoryUserFail,
  getStockAnalysisReportInventoryQuickDetailSuccess,
  getStockAnalysisReportInventoryQuickDetailFail,
  getStockAnalysisReportInventoryAllDetailSuccess,
  getStockAnalysisReportInventoryAllDetailFail,
  getStockAnalysisReportInventoryQuickSummarySuccess,
  getStockAnalysisReportInventoryQuickSummaryFail,
  getStockAnalysisReportInventoryAllSummarySuccess,
  getStockAnalysisReportInventoryAllSummaryFail,
  getStockAnalysisReportInventoryDetailSuccess,
  getStockAnalysisReportInventoryDetailFail,
  loadingStockAnalysisReportInventoryDetail,
  getStockAnalysisReportInventoryPreviousRequest,
  getStockAnalysisReportInventoryNextRequest,
  getStockAnalysisReportInventoryDetailRequest,
} from "./stockAnalysisReportInventorySlice";
import { mergeMap } from "rxjs";
import * as API from "./api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../../AppUtils/Utils/validationConstants";
import { ContactsOutlined } from "@mui/icons-material";
export const controller = new AbortController();
const getStockAnalysisReportInventorySupplierEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getStockAnalysisReportInventorySupplierRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getStockAnalysisReportInventorySupplier();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getStockAnalysisReportInventorySupplierSuccess(action?.payload)
        : getStockAnalysisReportInventorySupplierFail()
    )
  );
//user
const getStockAnalysisReportInventoryUserEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getStockAnalysisReportInventoryUserRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getStockAnalysisReportInventoryUser();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getStockAnalysisReportInventoryUserSuccess(action?.payload)
        : getStockAnalysisReportInventoryUserFail()
    )
  );
//item
const getStockAnalysisReportInventoryItemEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getStockAnalysisReportInventoryItemRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getStockAnalysisReportInventoryItem();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getStockAnalysisReportInventoryItemSuccess(action?.payload)
        : getStockAnalysisReportInventoryItemFail()
    )
  );

//get all purchase summary detail
const getStockAnalysisReportInventoryAllSummaryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getStockAnalysisReportInventoryAllSummaryRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getStockAnalysisReportInventoryAllReport(
          action?.payload
        );
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getStockAnalysisReportInventoryAllSummarySuccess(action?.payload)
        : getStockAnalysisReportInventoryAllSummaryFail()
    )
  );
//quick summary
const getStockAnalysisReportInventoryQuickSummaryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getStockAnalysisReportInventoryQuickSummaryRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getStockAnalysisReportInventoryQuickReport(
          action.payload
        );
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getStockAnalysisReportInventoryQuickSummarySuccess(action?.payload)
        : getStockAnalysisReportInventoryQuickSummaryFail()
    )
  );

//get all purchase summary detail
const getStockAnalysisReportInventoryAllDetailEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getStockAnalysisReportInventoryAllDetailRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getStockAnalysisReportInventoryAllReport(
          action?.payload
        );
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getStockAnalysisReportInventoryAllDetailSuccess(action?.payload)
        : getStockAnalysisReportInventoryAllDetailFail()
    )
  );
//quick Detail
const getStockAnalysisReportInventoryQuickDetailEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getStockAnalysisReportInventoryQuickDetailRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getStockAnalysisReportInventoryQuickReport(
          action.payload
        );
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getStockAnalysisReportInventoryQuickDetailSuccess(action?.payload)
        : getStockAnalysisReportInventoryQuickDetailFail()
    )
  );

//next and previous
//get next
const getStockAnalysisReportInventoryNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getStockAnalysisReportInventoryNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingStockAnalysisReportInventoryDetail());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getStockAnalysisReportInventoryDetailSuccess(action?.payload)
        : getStockAnalysisReportInventoryDetailFail()
    )
  );
//get previous
const getStockAnalysisReportInventoryPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getStockAnalysisReportInventoryPreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingStockAnalysisReportInventoryDetail());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getStockAnalysisReportInventoryDetailSuccess(action?.payload)
        : getStockAnalysisReportInventoryDetailFail()
    )
  );
//het purchase report details
const getStockAnalysisReportInventoryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getStockAnalysisReportInventoryDetailRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getStockAnalysisReportInventoryQuickReport(
          action.payload
        );
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getStockAnalysisReportInventoryDetailSuccess(action?.payload)
        : getStockAnalysisReportInventoryDetailFail()
    )
  );
export const stockAnalysisReportInventoryEpics = combineEpics(
  getStockAnalysisReportInventorySupplierEpic,
  getStockAnalysisReportInventoryItemEpic,
  getStockAnalysisReportInventoryUserEpic,
  getStockAnalysisReportInventoryAllSummaryEpic,
  getStockAnalysisReportInventoryQuickSummaryEpic,
  getStockAnalysisReportInventoryQuickDetailEpic,
  getStockAnalysisReportInventoryAllDetailEpic,
  getStockAnalysisReportInventoryPrevious,
  getStockAnalysisReportInventoryNext,
  getStockAnalysisReportInventoryEpic
);
