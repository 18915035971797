import { createSlice, createAction } from "@reduxjs/toolkit";
import { MaterializedReportInventoryInitialState } from "./types";
const initialState: MaterializedReportInventoryInitialState = {
  suppliers: [],
  users: [],
  items: [],
  purchaseDetails: [],
  //loading
  loadingGenerateReport: false,
  loadingMaterializedReportInventorySupplier: false,
  loadingMaterializedReportInventoryItem: false,
  loadingMaterializedReportInventoryUser: false,
  loadingMaterializedReportInventoryDetail: false,
  count: 0,
  previous: "",
  next: "",
};

// export const getMaterializedReportInventoryDetailRequest = createAction(
//   "getMaterializedReportInventoryDetailRequest",
//   function prepare({ detailsRowsPerPage, page }) {
//     return {
//       payload: {
//         detailsRowsPerPage,
//         page,
//       },
//     };
//   }
// );
export const MaterializedReportInventory = createSlice({
  name: "MaterializedReportInventoryReducer",
  initialState,
  reducers: {
    //loading
    loadingMaterializedReportInventoryDetail: (state) => {
      state.loadingMaterializedReportInventoryDetail = true;
    },
    //external reducer request
    getMaterializedReportInventoryDetailRequest: (state, { payload }) => {
      state.loadingMaterializedReportInventoryDetail = true;
    },
    getMaterializedReportInventorySupplierRequest: (state) => {
      state.loadingMaterializedReportInventorySupplier = true;
    },
    getMaterializedReportInventoryItemRequest: (state) => {
      state.loadingMaterializedReportInventoryItem = true;
    },
    getMaterializedReportInventoryUserRequest: (state) => {
      state.loadingMaterializedReportInventoryUser = true;
    },
    getMaterializedReportInventoryAllSummaryRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getMaterializedReportInventoryAllDetailRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getMaterializedReportInventoryQuickSummaryRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getMaterializedReportInventoryQuickDetailRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getMaterializedReportInventoryNextRequest: (state, payload) => {
      state.loadingMaterializedReportInventoryDetail = true;
    },
    getMaterializedReportInventoryPreviousRequest: (state, payload) => {
      state.loadingMaterializedReportInventoryDetail = true;
    },
    //external get reducer
    getMaterializedReportInventoryDetailSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.purchaseDetails = results;
      state.loadingMaterializedReportInventoryDetail = false;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getMaterializedReportInventoryDetailFail: (state) => {
      state.loadingMaterializedReportInventoryDetail = false;
    },
    getMaterializedReportInventoryUserSuccess: (
      state,
      { payload: { results } }
    ) => {
      state.loadingMaterializedReportInventoryUser = false;
      state.users = results;
    },
    getMaterializedReportInventoryUserFail: (state) => {
      state.loadingMaterializedReportInventoryUser = false;
    },
    getMaterializedReportInventorySupplierSuccess: (
      state,
      { payload: { results } }
    ) => {
      state.loadingMaterializedReportInventorySupplier = false;
      state.suppliers = results;
    },
    getMaterializedReportInventorySupplierFail: (state) => {
      state.loadingMaterializedReportInventorySupplier = false;
    },
    getMaterializedReportInventoryItemSuccess: (
      state,
      { payload: { results } }
    ) => {
      state.loadingMaterializedReportInventoryItem = false;
      state.items = results;
    },
    getMaterializedReportInventoryItemFail: (state) => {
      state.loadingMaterializedReportInventoryItem = false;
    },
    getMaterializedReportInventoryAllSummarySuccess: (
      state,
      { payload: { results } }
    ) => {
      state.loadingGenerateReport = false;
      state.purchaseDetails = results;
    },
    getMaterializedReportInventoryAllSummaryFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getMaterializedReportInventoryQuickSummarySuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingGenerateReport = false;
      state.purchaseDetails = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getMaterializedReportInventoryQuickSummaryFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getMaterializedReportInventoryAllDetailSuccess: (
      state,
      { payload: { results } }
    ) => {
      state.loadingGenerateReport = false;
      state.purchaseDetails = results;
    },
    getMaterializedReportInventoryAllDetailFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getMaterializedReportInventoryQuickDetailSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingGenerateReport = false;
      state.purchaseDetails = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getMaterializedReportInventoryQuickDetailFail: (state) => {
      state.loadingGenerateReport = false;
    },

    clearMaterializedReportInventoryData: (state) => {
      state.suppliers = [];
      state.users = [];
      state.items = [];
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  //reducerReport
  getMaterializedReportInventoryDetailRequest,
  getMaterializedReportInventorySupplierRequest,
  getMaterializedReportInventoryItemRequest,
  getMaterializedReportInventoryUserRequest,
  getMaterializedReportInventoryAllSummaryRequest,
  getMaterializedReportInventoryAllDetailRequest,
  getMaterializedReportInventoryQuickSummaryRequest,
  getMaterializedReportInventoryQuickDetailRequest,
  getMaterializedReportInventoryPreviousRequest,
  getMaterializedReportInventoryNextRequest,
  //reducer get data
  getMaterializedReportInventoryDetailSuccess,
  getMaterializedReportInventoryDetailFail,
  getMaterializedReportInventoryItemSuccess,
  getMaterializedReportInventoryItemFail,
  getMaterializedReportInventorySupplierSuccess,
  getMaterializedReportInventorySupplierFail,
  getMaterializedReportInventoryUserSuccess,
  getMaterializedReportInventoryUserFail,
  getMaterializedReportInventoryQuickDetailSuccess,
  getMaterializedReportInventoryQuickDetailFail,
  getMaterializedReportInventoryAllDetailSuccess,
  getMaterializedReportInventoryAllDetailFail,
  getMaterializedReportInventoryQuickSummarySuccess,
  getMaterializedReportInventoryQuickSummaryFail,
  getMaterializedReportInventoryAllSummarySuccess,
  getMaterializedReportInventoryAllSummaryFail,
  //loading
  loadingMaterializedReportInventoryDetail,
  //clear
  clearMaterializedReportInventoryData,
} = MaterializedReportInventory.actions;
export default MaterializedReportInventory.reducer;
