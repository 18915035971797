import {
  getIRDSyncInventoryFail,
  getIRDSyncInventorySuccess,
  loadingIRDSyncInventory,
  getIRDSyncInventoryRequest,
  getIRDSyncInventoryNextRequest,
  getIRDSyncInventoryPreviousRequest,
  postIRDSyncInventoryRequest,
  postIRDSyncInventoryUploadSuccess,
} from "./irdSyncInventorySlice";
import { mergeMap } from "rxjs";
import { getIRDSyncInventory, postIRDSyncInventoryApi } from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  dispatchAction,
  stateAction,
} from "../../../../../AppUtils/Utils/globalTypes";
import { getNext, getPrevious } from "../../../../CommonAppRedux/api";
import {
  alertErrorAction,
  alertSuccessAction,
} from "../../../../CommonAppRedux/CommonAppSlice";
import {
  defaultRowsPerPage,
  defaultPage,
} from "../../../../../AppUtils/Utils/globalConstant";

export const controller = new AbortController();
//get IRDSyncInventoryType epic
const getIRDSyncInventoryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getIRDSyncInventoryRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingIRDSyncInventory());
      try {
        const response = await getIRDSyncInventory(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getIRDSyncInventorySuccess(action?.payload)
        : getIRDSyncInventoryFail()
    )
  );
//get next
const getIRDSyncInventoryNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getIRDSyncInventoryNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingIRDSyncInventory());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getIRDSyncInventorySuccess(action?.payload)
        : getIRDSyncInventoryFail()
    )
  );
//get previous
const getIRDSyncInventoryPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getIRDSyncInventoryPreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingIRDSyncInventory());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getIRDSyncInventorySuccess(action?.payload)
        : getIRDSyncInventoryFail()
    )
  );
const postIRDSyncInventory = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(postIRDSyncInventoryRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await postIRDSyncInventoryApi();
        dispatch(alertSuccessAction("IRD Sync Post"));
        dispatch(
          getIRDSyncInventoryRequest({
            rowsPerPage: defaultRowsPerPage,
            page: defaultPage,
          })
        );

        return { payload: response };
      } catch (e) {
        alert("fail");
        dispatch(alertErrorAction("IRD Sync Not Post"));
        return { error: e };
      }
    }),
    map((action) => postIRDSyncInventoryUploadSuccess())
  );

export const IRDSyncInventoryEpics = combineEpics(
  getIRDSyncInventoryEpic,
  getIRDSyncInventoryNext,
  getIRDSyncInventoryPrevious,
  postIRDSyncInventory
);
