import { createSlice } from "@reduxjs/toolkit";
import { InitialState } from "./types";

const initialState: InitialState = {
  additionalCharges: [],
  edit: false,
  additionalCharge: null,
  loading: false,
  loadingCreate: false,
  count: 0,
  previous: "",
  next: "",
};

export const AdditionalCharge = createSlice({
  name: "additionalChargeReducer",
  initialState,
  reducers: {
    getAdditionalChargeRequest: (state, payload) => {
      state.loading = true;
    },
    getAdditionalChargeSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loading = false;
      state.additionalCharges = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getAdditionalChargeFail: (state) => {
      state.loading = false;
    },
    getAdditionalChargeNextRequest: (state, payload) => {
      state.loading = true;
    },
    getAdditionalChargePreviousRequest: (state, payload) => {
      state.loading = true;
    },
    createAdditionalChargeRequest: (state, payload) => {
      state.loadingCreate = true;
    },
    updateAdditionalChargeRequest: (state, payload) => {
      state.loadingCreate = true;
    },
    createAdditionalChargeSuccess: (state) => {
      state.loadingCreate = false;
    },
    createAdditionalChargeFail: (state) => {
      state.loadingCreate = false;
    },
    getAdditionalChargeByIdRequest: (state, payload) => {
      state.edit = true;
    },
    additionalChargeEditSuccess: (state, { payload }) => {
      state.additionalCharge = payload;
    },
    additionalChargeEditFail: (state) => {
      state.edit = false;
    },
    updateAdditionalChargeSuccess: (state) => {
      state.loadingCreate = false;
    },
    updateAdditionalChargeFail: (state) => {
      state.loadingCreate = false;
    },
    clearAllData: (state) => {
      state.edit = false;
      state.additionalCharge = null;
    },
  },
});

//action creaters generated for each case reducer
export const {
  getAdditionalChargeRequest,
  getAdditionalChargeFail,
  getAdditionalChargeSuccess,
  getAdditionalChargeNextRequest,
  getAdditionalChargePreviousRequest,
  createAdditionalChargeFail,
  createAdditionalChargeRequest,
  createAdditionalChargeSuccess,
  additionalChargeEditSuccess,
  updateAdditionalChargeFail,
  updateAdditionalChargeRequest,
  updateAdditionalChargeSuccess,
  clearAllData,
  getAdditionalChargeByIdRequest,
  additionalChargeEditFail,
} = AdditionalCharge.actions;

export default AdditionalCharge.reducer;
