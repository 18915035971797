// @ts-ignore
import { filterData } from "../../Component/FilterData/types";
import { rowProps } from "../../Pages/ViewDetails/CommonTable/types";
import { globalSearchPostPerPage, prefixes } from "./globalConstant";
import { convertDateAdToBs, dateFormater } from "./dateFunctions";
import {
  alertErrorAction,
  commonAppFormikData,
} from "../../Pages/CommonAppRedux/CommonAppSlice";
import { Dispatch } from "react";
import { AnyAction } from "@reduxjs/toolkit";
import { axiosInstance } from "./axios";

//function to filter api data
const filterApiData = (filterData: filterData) => {
  const { fromDateAd, toDateAd, fromDateBs, toDateBs, filterField } =
    filterData;
  // return array of string
  let filterFieldValue = filterField?.map(
    (x: any) =>
      `&${x.filterField ? x.filterField : x.id}=${x.filterFieldValue ?? ""}`
  );
  return `&date_after=${
    fromDateAd
      ? dateFormater(new Date(fromDateAd))
      : fromDateBs
      ? fromDateBs
      : ""
  }&date_before=${
    toDateAd ? dateFormater(new Date(toDateAd)) : toDateBs ? toDateBs : ""
  }${filterFieldValue?.join("") ?? ""}`;
};
//function to check duplicate value
const checkDuplicateValue = (duplicateDataList: any, filedName: string) => {
  return duplicateDataList[0][`${filedName}`]?.length > 0
    ? duplicateDataList[0][`${filedName}`]?.length === 0
    : duplicateDataList[0][`${filedName}`] !== "undefined";
};
//custom round function
const customRound = (num: number | string) => {
  // The rounding problem can be avoided by using numbers represented in exponential notation:
  // @ts-ignore
  return +(Math.round(num + "e+2") + "e-2");
};

//data to convert file
const dataURLtoFile = (dataurl: any, filename: any) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};
// date formater for start and end date in appointment module
const formatStartOrEndDate = (date: any): any => {
  // @ts-ignore
  const dateToFormat = new Date(date);
  const dateOnly = dateToFormat?.toISOString().substring(0, 10);
  const timeOnly = dateToFormat?.toTimeString().substring(0, 5);
  const finalDateTime = dateOnly + "T" + timeOnly;
  return finalDateTime;
};
// handle url when user search from autocomplete
const getSearchURL = (value: string) =>
  `offset=0&limit=${globalSearchPostPerPage}&search=${value.trimStart()}`;

// update details
const updateDetails = (data: any, key: string) => {
  return data?.map((data: any) => {
    const {
      [key]: { id },
    } = data;
    return { ...data, [key]: id };
  });
};
// check equality between two objects
const checkEqualityData = (
  values: any,
  initialValues: any,
  checkEquality: React.MutableRefObject<boolean>,
  dispatch: Dispatch<AnyAction>
) => {
  checkEquality.current =
    JSON.stringify(values) === JSON.stringify(initialValues) ? true : false;
  dispatch(commonAppFormikData(checkEquality.current));
};
const onKeyUpFunction = (e: any) => {
  if (e.key === "Enter") {
    // @ts-ignore
    const next = inputRef.current[i + 1];
    if (next) {
      //@ts-ignore
      return next.focus();
    }
  }
};

//
const handleFollowUpCheck = (
  values: any,
  setFieldValue: any,
  dispatch: any
) => {
  axiosInstance
    .get(`/api/v1/global-api/customer/check-follow-up-status/${values?.id}`)
    .then((response: any) => {
      if (response.status === 200) {
        // dispatch(alertSuccessAction("Successfully"));
      }
    })
    .catch((error: any) => {
      dispatch(
        alertErrorAction(
          `${values?.fullName} Patient Has ${
            error?.response.data?.message ?? "Internal Server Error"
          }`
        )
      );
      setFieldValue("customer", null);
    });
};
//isDefault Check api unique
const handleIsDefaultCheck = (
  setFieldValue: any,
  dispatch: any,
  apiUrl: any,
  keyName: any,
  array: boolean | undefined
) => {
  axiosInstance
    .get(`${apiUrl}?is_default=true`)
    .then((response: any) => {
      let data = response?.data?.results[0];
      if (response.status === 200) {
        if (array) {
          if (data) {
            setFieldValue(`${keyName}`, [data] ?? []);
          } else {
            setFieldValue(`${keyName}`, []);
          }
        } else {
          setFieldValue(`${keyName}`, data ?? null);
        }
      }
    })
    .catch((error: any) => {
      dispatch(alertErrorAction("No Default Data"));
    });
};
const handleIsDefaultCheckImci = (
  setFieldValue: any,
  dispatch: any,
  apiUrl: any,
  keyName: any,
  array: boolean | undefined,
  dateValidation: number
) => {
  axiosInstance
    .get(`${apiUrl}?is_default_imci=true`)
    .then((response: any) => {
      let data = response?.data?.results[0];
      if (response.status === 200) {
        if (array) {
          if (dateValidation < 60) {
            if (data) {
              setFieldValue(`${keyName}`, [data] ?? []);
            } else {
              handleIsDefaultCheck(
                setFieldValue,
                dispatch,
                apiUrl,
                keyName,
                array
              );
            }
          } else {
            handleIsDefaultCheck(
              setFieldValue,
              dispatch,
              apiUrl,
              keyName,
              array
            );
          }
        } else {
          setFieldValue(`${keyName}`, data ?? null);
        }
      }
    })
    .catch((error: any) => {
      dispatch(alertErrorAction("No Default Data"));
    });
};
//get followpatient details
const getOpdPatientDetail = (dispatch: any, apiUrl: any) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`${apiUrl}`)
      .then((response: any) => {
        let data = response?.data?.results[0];
        resolve(data);
      })
      .catch((error: any) => {
        dispatch(alertErrorAction("Patient Data Not Found"));
        reject(error);
      });
  });
};

//nshi get data from url
const handleGetNshiDataCheck = (
  setNshiData: any,
  dispatch: any,
  nshiNo: any,
  url: string
) => {
  const formattedNshiNo = nshiNo.replace(/-/g, "");
  axiosInstance
    .get(`${url}?nshi_no=${formattedNshiNo}`)
    .then((response: any) => {
      let data = response?.data;
      if (response.data) {
        setNshiData(data);
      }
    })
    .catch((error: any) => {
      dispatch(alertErrorAction("Bad Server Connection Insurance"));
    });
};

//
const handleGetDailyFollowPatientCheck = (
  dispatch: any,
  customerData: any
): Promise<any> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(
        `api/v1/clinic-customer-app/opd-customer-follow-up?offset=0&limit=0&search=${customerData}&date_after=${dateFormater(
          new Date()
        )}&date_before=${dateFormater(new Date())}`
      )
      .then((response: any) => {
        let data = response?.data?.results;
        if (response.status === 200) {
          resolve(data); // Resolve the promise with the data
        } else {
          reject("Error: Unexpected response status");
        }
      })
      .catch((error: any) => {
        dispatch(alertErrorAction("Bad Server Connection Server"));
        reject(error);
      });
  });
};

const getDynamicPermissionData = (
  permissionData: string[]
): { [key: string]: string } => {
  const passPermission: { [key: string]: string } = {};

  prefixes.forEach((prefix) => {
    const permission = permissionData.find((p) => p.includes(`${prefix}_`));
    if (permission) {
      passPermission[`${prefix}_permission`] = permission;
    }
  });

  return passPermission;
};

const parseDateFormaterNshi = (inputString: any, rule: any) => {
  const bsIndex = inputString.indexOf("BS:");
  const adIndex = inputString.indexOf("AD:");
  if (bsIndex >= 0 && adIndex >= 0) {
    const bsDate = inputString.substring(bsIndex + 3, adIndex - 1);
    const adDate = inputString.substring(adIndex + 4);
    let formattedBsDate = bsDate.replace(/\//g, "-");
    let formattedAdDate = adDate.replace(/\//g, "-");
    let dateArrAd = formattedAdDate.split("-");
    let dateArrBs = formattedBsDate.split("-");

    let formattedDateAd = "";
    let formattedDateBs = "";

    //@ts-ignore
    if (rule?.dateFormat === "YYYY-MM-DD") {
      formattedDateAd = dateArrAd[2] + "-" + dateArrAd[1] + "-" + dateArrAd[0];
      formattedDateBs = dateArrBs[2] + "-" + dateArrBs[1] + "-" + dateArrBs[0];
    } else {
      formattedDateAd = dateArrAd[0] + "-" + dateArrAd[1] + "-" + dateArrAd[2];
      formattedDateBs = dateArrBs[0] + "-" + dateArrBs[1] + "-" + dateArrBs[2];
    }

    return {
      formattedBsDate,
      formattedAdDate,
      formattedDateAd,
      formattedDateBs,
    };
  } else {
    return null; // Return null for invalid input
  }
};

const parseExpiryDateFormaterRemaining = (inputString: any, rule: any) => {
  let dateArrAd = inputString.split("/");
  let formattedDateAdForAmount = "";
  //@ts-ignore
  if (rule?.dateFormat === "YYYY-MM-DD") {
    formattedDateAdForAmount =
      dateArrAd[2] + "-" + dateArrAd[1] + "-" + dateArrAd[0];
  } else {
    formattedDateAdForAmount =
      dateArrAd[0] + "-" + dateArrAd[1] + "-" + dateArrAd[2];
  }
  return { formattedDateAdForAmount };
};

const filterDataTodayListing = {
  filterField: [],
  fromDateAd: new Date(),
  fromDateBs: convertDateAdToBs(new Date()),
  toDateAd: new Date(),
  toDateBs: convertDateAdToBs(new Date()),
};
export {
  dateFormater,
  formatStartOrEndDate,
  dataURLtoFile,
  checkDuplicateValue,
  filterApiData,
  customRound,
  getSearchURL,
  checkEqualityData,
  onKeyUpFunction,
  updateDetails,
  handleFollowUpCheck,
  handleIsDefaultCheck,
  getDynamicPermissionData,
  handleGetNshiDataCheck,
  parseExpiryDateFormaterRemaining,
  parseDateFormaterNshi,
  filterDataTodayListing,
  getOpdPatientDetail,
  handleGetDailyFollowPatientCheck,
  handleIsDefaultCheckImci,
};
