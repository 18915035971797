import {
  createItemSuccess,
  createItemFail,
  createItemRequest,
  getItemSuccess,
  getItemFail,
  getItemManufacturerRequest,
  getItemManufacturerSuccess,
  getItemManufacturerFail,
  getItemUnitRequest,
  getItemUnitSuccess,
  getItemUnitFail,
  getItemCategoryRequest,
  getItemCategorySuccess,
  getItemCategoryFail,
  getPackingTypeRequest,
  getPackingTypeSuccess,
  getPackingTypeFail,
  updateItemFail,
  updateItemSuccess,
  getItemRequest,
  updateItemRequest,
  getItemNextRequest,
  getItemPreviousRequest,
  itemEditRequest,
  itemEditSuccess,
  getItemCustomerTypeRequest,
  getItemCustomerTypeSuccess,
  getItemCustomerTypeFail,
  clearItemData,
} from "./itemSlice";
import { mergeMap } from "rxjs";
import {
  createItem,
  getItem,
  updateItem,
  getItemManufacturer,
  getItemCategory,
  getItemUnit,
  getPackingType,
  getInvItemDetails,
  getItemCustomerType,
} from "./api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";

export const controller = new AbortController();

// get item epic
const getItemEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getItemRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getItem(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getItemSuccess(action?.payload) : getItemFail()
    )
  );

const getAllItemManufacturer = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getItemManufacturerRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getItemManufacturer(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getItemManufacturerSuccess(action?.payload)
        : getItemManufacturerFail()
    )
  );
const getAllItemUnit = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getItemUnitRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getItemUnit(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getItemUnitSuccess(action?.payload) : getItemUnitFail()
    )
  );
const getAllItemCategory = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getItemCategoryRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getItemCategory(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getItemCategorySuccess(action?.payload)
        : getItemCategoryFail()
    )
  );
const getAllPackingType = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPackingTypeRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPackingType();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPackingTypeSuccess(action?.payload)
        : getPackingTypeFail()
    )
  );
//get next
const getItemNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getItemNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getItemSuccess(action?.payload) : getItemFail()
    )
  );

//get previous
const getItemPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getItemPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getItemSuccess(action?.payload) : getItemFail()
    )
  );
//create Item epic
const createItemEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createItemRequest.match),
    mergeMap(
      async ({ payload: { values, rowsPerPage, page, setShowItem } }) => {
        // const {
        //   name,
        //   code,
        //   stockAlertQty,
        //   location,
        //   basicInfo,
        //   taxable,
        //   taxRate,
        //   image,
        //   discountable,
        //   expirable,
        //   purchaseCost,
        //   saleCost,
        //   itemCategory,
        //   unit,
        //   itemType,
        //   active,
        //   manufacturer,
        //   invPackingTypeDetails,
        //   invItemPrices,
        // } = values;
        const { image, invPackingTypeDetails, invItemPrices, ...restValues } =
          values;

        try {
          const body = new FormData();
          for (let [key, value] of Object.entries(restValues)) {
            // @ts-ignore
            body.append(key, value);
          }
          if (image) {
            body.append("image", image);
          }
          for (let x in invPackingTypeDetails) {
            body.append(
              `invPackingTypeDetails[${x}][packingType]`,
              invPackingTypeDetails[x].packingType
            );
            body.append(
              `invPackingTypeDetails[${x}][packQty]`,
              invPackingTypeDetails[x].packQty
            );
            body.append(
              `invPackingTypeDetails[${x}][active]`,
              invPackingTypeDetails[x].active
            );
          }
          //inventory item prices
          invItemPrices.forEach((itemPrice: any, i: number) => {
            const {
              id,
              customerTypeName,
              isNew,
              unique_id,
              customerTypeObj,
              ...updatedItemPrice
            } = itemPrice;
            // append updated item price
            Object.keys(updatedItemPrice).map((key) => {
              return body.append(
                `invItemPrices[${i}][${key}]`,
                updatedItemPrice[key]
              );
            });
          });
          const response = await createItem(body);
          if (response) {
            !setShowItem && dispatch(getItemRequest({ rowsPerPage, page }));
            dispatch(alertSuccessAction(messages.createMessage));
            setShowItem ? setShowItem(false) : dispatch(closeModal());
            // dispatch(closeModal());
          }
          return { payload: { response } };
        } catch (e) {
          dispatch(alertErrorAction(messages.createFailMessage));
          return { error: e };
        }
      }
    ),
    map((action) => {
      return action?.payload ? createItemSuccess() : createItemFail();
    })
  );

//update Item epic
const updateItemEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateItemRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      const { image, invPackingTypeDetails, invItemPrices, ...restValues } =
        values;
      // const {id:packId}=invPackingTypeDetails;
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(restValues)) {
          // @ts-ignore
          body.append(key, value);
        }
        if (image) {
          body.append("image", image);
        }
        invPackingTypeDetails.forEach((packType: any, i: number) => {
          const { id, ...updatedPackingType } = packType;
          // append updated item price
          Object.keys(updatedPackingType).map((key) => {
            return body.append(
              `invPackingTypeDetails[${i}][${key}]`,
              updatedPackingType[key]
            );
          });
          if (id !== undefined) {
            body.append(
              `invPackingTypeDetails[${i}][id]`,
              invPackingTypeDetails[i].id
            );
          }
        });
        //inventory item prices
        invItemPrices.forEach((itemPrice: any, i: number) => {
          const {
            id,
            customerTypeName,
            isNew,
            unique_id,
            customerTypeObj,
            ...updatedItemPrice
          } = itemPrice;
          // append updated item price
          Object.keys(updatedItemPrice).map((key) => {
            return body.append(
              `invItemPrices[${i}][${key}]`,
              updatedItemPrice[key]
            );
          });
          if (id !== undefined) {
            body.append(`invItemPrices[${i}][id]`, invItemPrices[i].id);
          }
        });

        const response = await updateItem(body, id);
        if (response) {
          dispatch(getItemRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearItemData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) => (action?.payload ? updateItemSuccess() : updateItemFail()))
  );
// get item epic
const getItemDetailsEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(itemEditRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getInvItemDetails(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) => action?.payload && itemEditSuccess(action?.payload))
  );
// get item customer type
const getItemCustomerTypeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getItemCustomerTypeRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getItemCustomerType();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getItemCustomerTypeSuccess(action?.payload)
        : getItemCustomerTypeFail()
    )
  );
export const itemEpics = combineEpics(
  getItemEpic,
  createItemEpic,
  updateItemEpic,
  getItemNext,
  getItemPrevious,
  getAllItemManufacturer,
  getAllItemUnit,
  getAllItemCategory,
  getAllPackingType,
  getItemDetailsEpic,
  getItemCustomerTypeEpic
);
