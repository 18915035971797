import {
  //create
  createCustomerRequest,
  createCustomerSuccess,
  createCustomerFail,
  //get
  getCustomerRequest,
  getCustomerSuccess,
  getCustomerFail,
  //update
  updateCustomerRequest,
  updateCustomerSuccess,
  updateCustomerFail,
  //clear customer data
  clearCustomerData,
  //get next / previous
  getCustomerNextRequest,
  getCustomerPreviousRequest,
  //title
  getCustomerTitleRequest,
  getCustomerTitleSuccess,
  getCustomerTitleFail,
  //customerType
  getCustomerCustomerTypeRequest,
  getCustomerCustomerTypeSuccess,
  getCustomerCustomerTypeFail,
  //importCSV
  importCSVFileCustomerRequest,
  importCSVFileCustomerSuccess,
  importCSVFileCustomerFail,
} from "./customerSlice";
import { mergeMap } from "rxjs";
import {
  createCustomer,
  getCustomer,
  updateCustomer,
  getCustomerTitle,
  getCustomerCustomerType,
  importCSVFileCustomer,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
  getImportCSVMessage,
  clearImportCSVMessage,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";

export const controller = new AbortController();

//get customer epic
const getCustomerEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getCustomer(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getCustomerSuccess(action.payload) : getCustomerFail()
    )
  );

//get next
const getCustomerNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getCustomerSuccess(action?.payload) : getCustomerFail()
    )
  );

//get previous
const getCustomerPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getCustomerSuccess(action?.payload) : getCustomerFail()
    )
  );

//create Customer epic
const createCustomerEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createCustomerRequest.match),
    mergeMap(
      async ({ payload: { values, rowsPerPage, page, setShowCustomer } }) => {
        try {
          const body = JSON.stringify(values);
          const response = await createCustomer(body);
          if (response) {
            dispatch(alertSuccessAction(messages.createMessage));
            !setShowCustomer &&
              dispatch(getCustomerRequest({ rowsPerPage, page }));
            setShowCustomer ? setShowCustomer(false) : dispatch(closeModal());
          }
          return { payload: { response } };
        } catch (e) {
          dispatch(alertErrorAction(messages.createFailMessage));
          return { error: e };
        }
      }
    ),
    map((action) => {
      return action?.payload
        ? createCustomerSuccess(action?.payload)
        : createCustomerFail();
    })
  );

//update Customer epic
const updateCustomerEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateCustomerRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page, search } }) => {
      try {
        // let body = new FormData();
        const body = JSON.stringify(values);
        for (let [key, value] of Object.entries(values)) {
          // @ts-ignore
          body.append(`${key}`, value);
        }
        const response = await updateCustomer(body, id);
        if (response) {
          dispatch(getCustomerRequest({ rowsPerPage, page, search }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearCustomerData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateCustomerSuccess() : updateCustomerFail()
    )
  );

//get customer title epic
const getCustomerTitleEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerTitleRequest.match),
    mergeMap(async (action: any) => {
      try {
        const response = await getCustomerTitle(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCustomerTitleSuccess(action.payload)
        : getCustomerTitleFail()
    )
  );

//customer customerType epic
const getCustomerCustomerTypeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerCustomerTypeRequest.match),
    mergeMap(async (action: any) => {
      try {
        const response = await getCustomerCustomerType(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCustomerCustomerTypeSuccess(action?.payload)
        : getCustomerCustomerTypeFail()
    )
  );

const importCSVFileCustomerEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(importCSVFileCustomerRequest.match),
    mergeMap(
      async ({ payload: { values, forceImport, rowsPerPage, page } }) => {
        try {
          const body = new FormData();

          body.append("file", values);
          body.append("forceImport", forceImport);

          const response = await importCSVFileCustomer(body);

          if (response) {
            dispatch(getCustomerRequest({ rowsPerPage, page }));
            dispatch(alertSuccessAction(messages.createMessage));
            dispatch(closeModal());
            dispatch(clearImportCSVMessage());
          }
          return { payload: { response } };
        } catch (e: any) {
          if (e?.response?.data?.status === "400") {
            dispatch(
              getImportCSVMessage({
                status: e?.response?.data?.status,
                msg: e.response?.data?.message,
              })
            );
          }

          return { error: e };
        }
      }
    ),
    map((action) => {
      return action?.payload
        ? importCSVFileCustomerSuccess()
        : importCSVFileCustomerFail();
    })
  );

export const customerEpics = combineEpics(
  getCustomerEpic,
  createCustomerEpic,
  updateCustomerEpic,
  getCustomerNext,
  getCustomerPrevious,
  getCustomerTitleEpic,
  getCustomerCustomerTypeEpic,
  importCSVFileCustomerEpic
);
