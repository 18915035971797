import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

const districtModURL = "api/v1/core-app/district";

//get District data api
export const getDistrict = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${districtModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

//get District Province
export const getDistrictProvince = () => {
  return axiosInstance.get(`${districtModURL}/province`);
};

//get District by Id
export const getDistrictById = (id: number) => {
  return axiosInstance.get(`${districtModURL}/${id}`);
};

//handle search
export const searchDistrict = ({ rowsPerPage, search }: searchProps) => {
  return `${districtModURL}?offset=0&limit=${rowsPerPage}&search=${
    search ?? ""
  }`;
};
//create District
export const createDistrict = (body: string | FormData) =>
  axiosInstance.post(`${districtModURL}`, body);
//update District
export const updateDistrict = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${districtModURL}/${id}`, body);
