import { Switch } from "react-router-dom";
import {
  accGroupPermission,
  accJournalPermission,
  accJournalEntryPermission,
  accTrialBalancePermission,
} from "../Component/Layout/Sidebar/Accounting/AccountingPermissionConstants";
import ProtectedRoutes from "./ProtectedRoutes";
import { lazyWithReload } from "./Utils";

const AccountingSetup = lazyWithReload(
  () => import("../Pages/AccountingPages")
);
const Group = lazyWithReload(
  () => import("../Pages/AccountingPages/AccountHead/AccountingHeadPages")
);
const Journal = lazyWithReload(
  () => import("../Pages/AccountingPages/Journal/JournalPages")
);
const JournalEntry = lazyWithReload(
  () => import("../Pages/AccountingPages/JournalEntry/JournalEntryPages")
);
const LedgerReport = lazyWithReload(
  () =>
    import(
      "../Pages/AccountingPages/AccountReports/LedgerReport/LedgerReportPages"
    )
);
const TrialBalance = lazyWithReload(
  () => import("../Pages/AccountingPages/TrialBalance/TrialBalancePages")
);
const AccountingRoutes = () => {
  return (
    <>
      <Switch>
        <ProtectedRoutes
          exact
          path="/accounting"
          component={AccountingSetup}
          permission={[{ mainModule: true }]}
        />
        <ProtectedRoutes
          exact
          path="/accounting/group"
          component={Group}
          permission={accGroupPermission}
        />
        // * Currently Removed
        {/* <ProtectedRoutes
          exact
          path="/accounting/journal-voucher"
          component={Journal}
          permission={accJournalPermission}
        /> */}
        <ProtectedRoutes
          exact
          path="/accounting/journal-entry"
          component={JournalEntry}
          permission={accJournalEntryPermission}
        />
        <ProtectedRoutes
          exact
          path="/accounting/trial-balance"
          component={TrialBalance}
          permission={accTrialBalancePermission}
        />
        {/* reports */}
        <ProtectedRoutes
          exact
          path="/accounting/ledger"
          component={LedgerReport}
          permission={[]}
        />
      </Switch>
    </>
  );
};

export default AccountingRoutes;
