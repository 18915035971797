import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

export const accountHeadApi = "api/v1/account-app/account-head";

//get accountHead data api
export const getAccountHead = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${accountHeadApi}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};
//handle search
export const searchAccountHead = ({ rowsPerPage, search }: searchProps) => {
  return `${accountHeadApi}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
//create AccountHead
export const createAccountHead = (body: string | FormData) =>
  axiosInstance.post(`${accountHeadApi}`, body);
//update AccountHead
export const updateAccountHead = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${accountHeadApi}/${id}`, body);

export const getParent = ({ rowsPerPage }: any) =>
  axiosInstance.get(
    `api/v1/account-app/account-head/account?is_group=true&offset=0&limit=${rowsPerPage}`
  );

export const getTreeView = () =>
  axiosInstance.get("api/v1/account-app/account-head/account-tree");
