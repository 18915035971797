import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

export const journalModURL = "/api/v1/account-app/journal-voucher";

//get Journal Voucher data api
export const getJournal = (data: getApiProps) => {
  const { rowsPerPage, page, search } = data;
  return axiosInstance.get(
    `${journalModURL}?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}&ordering=-id&search=${search ?? ""}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

export const searchJournal = ({ rowsPerPage, search }: searchProps) => {
  return `${journalModURL}?offset=0&limit=${rowsPerPage}&ordering=-id&search=${search}`;
};
//create Journal Vouher
export const createJournal = (body: string | FormData) =>
  axiosInstance.post(`${journalModURL}`, body);
//update Journal Voucher
export const updateJournal = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${journalModURL}/${id}`, body);

//get Journal by id
export const getJournalVoucherById = (id: number) => {
  return axiosInstance.get(`${journalModURL}/${id}`);
};

export const getAccount = ({ rowsPerPage }: any) =>
  axiosInstance.get(
    `/api/v1/account-app/journal-voucher/account?offset=0&limit=${rowsPerPage}`
  );
export const approveVoucher = (body: string | FormData, id: number) =>
  axiosInstance.patch(
    `/api/v1/account-app/journal-voucher/${id}/approve`,
    body
  );

export const cancelVoucher = (body: string | FormData, id: number) =>
  axiosInstance.patch(`/api/v1/account-app/journal-voucher/${id}/cancel`, body);
