import {
  createManufacturerFail,
  createManufacturerRequest,
  createManufacturerSuccess,
  getManufacturerSuccess,
  getManufacturerFail,
  loadingManufacturer,
  updateManufacturerFail,
  updateManufacturerSuccess,
  getManufacturerRequest,
  updateManufacturerRequest,
  getManufacturerNextRequest,
  getManufacturerPreviousRequest,
  clearManufacturerData,
} from "./manufacturerSlice";
import { mergeMap } from "rxjs";
import { createManufacturer, getManufacturer, updateManufacturer } from "./api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();
// get manufacture epic
const getManufacturerEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getManufacturerRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingManufacturer());
      try {
        const response = await getManufacturer(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getManufacturerSuccess(action?.payload)
        : getManufacturerFail()
    )
  );

//get next
const getManufacturerNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getManufacturerNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingManufacturer());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getManufacturerSuccess(action?.payload)
        : getManufacturerFail()
    )
  );

//get previous
const getManufacturerPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getManufacturerPreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingManufacturer());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getManufacturerSuccess(action?.payload)
        : getManufacturerFail()
    )
  );
//create manufacturer epic
const createManufacturerEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createManufacturerRequest.match),
    mergeMap(
      async ({
        payload: { values, rowsPerPage, page, setShowModalFromAnotherModule },
      }) => {
        const { name, active } = values;
        try {
          const body = JSON.stringify({ name, active });
          const response = await createManufacturer(body);
          if (response) {
            dispatch(getManufacturerRequest({ rowsPerPage, page }));
            dispatch(alertSuccessAction(messages.createMessage));
            setShowModalFromAnotherModule
              ? setShowModalFromAnotherModule(false)
              : dispatch(closeModal());
          }
          return { payload: { response } };
        } catch (e) {
          dispatch(alertErrorAction(messages.createFailMessage));
          return { error: e };
        }
      }
    ),
    map((action) => {
      return action?.payload
        ? createManufacturerSuccess()
        : createManufacturerFail();
    })
  );

//update manufacturer epic
const updateManufacturerEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateManufacturerRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      const { name, active, remarks } = values;
      try {
        const body = JSON.stringify({ name, active, remarks });
        const response = await updateManufacturer(body, id);
        if (response) {
          dispatch(getManufacturerRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(clearManufacturerData());
          dispatch(closeModal());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateManufacturerSuccess() : updateManufacturerFail()
    )
  );

export const manufacturerEpics = combineEpics(
  getManufacturerEpic,
  createManufacturerEpic,
  updateManufacturerEpic,
  getManufacturerNext,
  getManufacturerPrevious
);
