import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

export const journalEntryModURL = "/api/v1/account-app/journal-entry";

//get Journal Voucher data api
export const getJournalEntry = (data: getApiProps) => {
  const { rowsPerPage, page, search } = data;
  return axiosInstance.get(
    `${journalEntryModURL}?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}&ordering=-id&search=${search ?? ""}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

export const searchJournalEntry = ({ rowsPerPage, search }: searchProps) => {
  return `${journalEntryModURL}?offset=0&limit=${rowsPerPage}&ordering=-id&search=${search}`;
};
//create Journal Entry
export const createJournalEntry = (body: string | FormData) =>
  axiosInstance.post(`${journalEntryModURL}`, body);

//get Journal Entry by id
export const getJournalEntryById = (id: string) => {
  return axiosInstance.get(`${journalEntryModURL}/${id}`);
};

export const getAccount = ({ rowsPerPage }: any) =>
  axiosInstance.get(
    `/api/v1/account-app/journal-voucher/account?offset=0&limit=${rowsPerPage}`
  );

//delete Journal Entry
export const deleteJournalEntry = (id: number) =>
  axiosInstance.delete(`${journalEntryModURL}/${id}`);

//update journal entry
export const updateJournalEntry = (id: number, body: FormData | string) =>
  axiosInstance.patch(`${journalEntryModURL}/${id}`, body);
