import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

export const creditClearanceModURL =
  "/api/v1/inventory-credit-clearance-app/credit-clearance";
// export const createCreditClearanceReturnModUrl="/api/v1/inventory-credit-clearance-app/credit-clearance-return";

//get party payment
export const getCreditClearance = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${creditClearanceModURL}?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

//get party payment supplier
export const getCreditClearanceCustomer = () => {
  return axiosInstance.get(
    `${creditClearanceModURL}/customer?offset=0&limit=0`
  );
};

// search party payment
export const searchCreditClearance = ({ rowsPerPage, search }: searchProps) => {
  return `${creditClearanceModURL}?offset=0&limit=${rowsPerPage}$search=${search}`;
};

//create party payment
export const createCreditClearance = (body: string | FormData) => {
  return axiosInstance.post(`${creditClearanceModURL}`, body);
};

// update party payment
export const updateCreditClearance = (body: string | FormData, id: number) => {
  return axiosInstance.patch(`${creditClearanceModURL}/${id}`, body);
};

// retrive party payment by id
export const getCreditClearanceById = (id: number) => {
  return axiosInstance.get(`${creditClearanceModURL}/${id}`);
};

//get payment mode
export const getCreditClearanceMode = () => {
  return axiosInstance.get("api/v1/core-app/payment-mode?offset=0&limit=0");
};
// credit clearance customer wise

// export const getCreditClearanceCustomerWise = () => {
//   return axiosInstance.get(
//       `${creditClearanceModURL}/customer-wise?offset=0&limit=0`
//   );
// };
//
export const getCreditClearanceSalesWise = ({ rowsPerPage, page, id }: any) => {
  return axiosInstance.get(
    `${creditClearanceModURL}/sales-wise?customer=${id}&offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}`
  );
};

export const getCreditClearanceCustomerWise = ({ id }: any) => {
  return axiosInstance.get(`${creditClearanceModURL}/customer-wise/${id}`);
};
