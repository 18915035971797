import { createSlice, createAction } from "@reduxjs/toolkit";
import { journalEntry } from "./types";
import { v4 as uuidv4 } from "uuid";
const initialState: any = {
  journalEntries: [],
  loading: false,
  loadingCreateJournalEntry: false,
  edit: false,
  journalEntry: null || {},
  count: 0,
  previous: "",
  next: "",
  loadingAccount: false,
  accounts: [],
  journalEntryDetails: [
    {
      unique: uuidv4(),
      accountHead: null,
      drAmount: 0,
      crAmount: 0,
      disabled: false,
    },
  ],
  readOnly: false,
  deletingJournalEntry: false,
};

export const JournalEntry = createSlice({
  name: "JournalEntryReducer",
  initialState,
  reducers: {
    loadingJournalEntry: (state) => {
      state.loadingJournalEntry = true;
    },
    getJournalEntryRequest: (state, payload) => {
      state.loadingJournalEntry = true;
    },
    getJournalEntrySuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingJournalEntry = false;
      state.journalEntries = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getJournalEntryFail: (state) => {
      state.loadingJournalEntry = false;
    },
    getJournalEntryNextRequest: (state, payload) => {
      state.loadingJournalEntry = true;
    },
    getJournalEntryPreviousRequest: (state, payload) => {
      state.loadingJournalEntry = true;
    },
    createJournalEntryRequest: (state, payload) => {
      state.loadingCreateJournalEntry = true;
    },
    createJournalEntrySuccess: (state) => {
      state.loadingCreateJournalEntry = false;
    },
    createJournalEntryFail: (state) => {
      state.loadingCreateJournalEntry = false;
    },

    getJournalEntryByIdRequest: (state, { payload: { id, readOnly } }) => {
      state.edit = true;
      state.readOnly = readOnly;
    },
    getJournalEntryByIdRequestSuccess: (state, { payload }) => {
      const newEntry = {
        unique: uuidv4(),
        accountHead: null,
        drAmount: 0,
        crAmount: 0,
        disabled: false,
      };
      // const newData = action.payload?.journalDetails?.sort(
      //   (a, b) => Number(b.drAmount) - Number(a.drAmount)
      // );
      return {
        ...state,
        journalEntry: payload,
        // here disable is used to enable edit
        journalEntryDetails: [
          ...payload.journalEntryDetails
            .map((value: any) => {
              return { ...value, unique: uuidv4(), disabled: true };
            })
            ?.sort((a: any, b: any) => Number(b.drAmount) - Number(a.drAmount)),
          newEntry,
        ],
      };
    },
    getJournalEntryByIdRequestFail: (state) => {
      state.edit = false;
    },

    getJournalEntryEdit: (state, { payload }) => {
      const data = [...state.journalEntryDetails];
      const newData = data.map((row: any, id: number) =>
        row?.id === payload?.id ? { ...payload, disabled: false } : row
      );
      state.journalEntryDetails = newData;
    },

    getAccountRequest: (state, payload) => {
      state.loadingAccount = true;
    },
    getAccountSuccess: (state, { payload: { results } }) => {
      // copying journal entries details
      const newEntry = JSON.parse(
        JSON.stringify([...state.journalEntryDetails])
      );
      // Only taking id
      let filterData = newEntry?.map((value: any) => {
        return {
          accountHeadId: value.accountHead?.id,
        };
      });
      // removing last object
      filterData?.splice(-1);
      // removing key name and converting to value [2,9]
      const idArray = filterData.map(
        (item: { accountHeadId: number }) => item.accountHeadId
      );
      // filtering the data
      const availableAccount = results?.filter(
        (value1: any) => !idArray?.includes(value1.id)
      );

      state.accounts = availableAccount;
      state.loadingAccount = false;
    },
    getAccountFail: (state) => {
      state.loadingAccount = false;
    },

    addEntryDetails: (state, { payload }) => {
      state.journalEntryDetails = payload;
    },
    deleteJournalEntryRequest: (state, payload) => {
      state.deletingJournalEntry = true;
    },
    deleteJournalEntrySuccess: (state) => {
      state.deletingJournalEntry = false;
    },
    deleteJournalEntryFail: (state) => {
      state.deletingJournalEntry = false;
    },

    updateJournalEntryRequest: (state, payload) => {
      state.loadingCreateJournalEntry = true;
    },
    updateJournalEntrySuccess: (state) => {
      state.loadingCreateJournalEntry = false;
    },
    updateJournalEntryFail: (state) => {
      state.loadingCreateJournalEntry = false;
    },

    clearJournalEntryData: (state) => {
      state.edit = false;
      state.journal = null;
      state.journalEntryDetails = [
        {
          unique: uuidv4(),
          accountHead: null,
          drAmount: 0,
          crAmount: 0,
          disabled: false,
        },
      ];
      state.readOnly = false;
    },
  },
});
// Action creators are generated for each case reducer function
const { actions, reducer } = JournalEntry;
export const {
  loadingJournalEntry,
  getJournalEntryRequest,
  getJournalEntryNextRequest,
  getJournalEntryPreviousRequest,
  getJournalEntrySuccess,
  getJournalEntryFail,
  createJournalEntryRequest,
  createJournalEntrySuccess,
  createJournalEntryFail,
  // get journal entry by id
  getJournalEntryByIdRequest,
  getJournalEntryByIdRequestSuccess,
  getJournalEntryByIdRequestFail,
  // to enable edit button in details
  getJournalEntryEdit,

  addEntryDetails,
  getAccountRequest,
  getAccountSuccess,
  getAccountFail,
  // Delete Journal Entry
  deleteJournalEntryRequest,
  deleteJournalEntrySuccess,
  deleteJournalEntryFail,
  // Update Journal Entry
  updateJournalEntryRequest,
  updateJournalEntrySuccess,
  updateJournalEntryFail,

  clearJournalEntryData,
} = actions;
export default reducer;
