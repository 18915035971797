import { createSlice, createAction } from "@reduxjs/toolkit";
import { trialBalance as trialBalanceData } from "./types";
import { v4 as uuidv4 } from "uuid";
const initialState: any = {
  trialBalances: [],
  loading: false,
  count: 0,
  previous: "",
  next: "",
};

export const TrialBalance = createSlice({
  name: "TrialBalanceReducer",
  initialState,

  reducers: {
    loadingTrialBalance: (state) => {
      state.loading = true;
    },
    getTrialBalanceRequest: (state, payload) => {
      state.loading = true;
    },
    getTrialBalanceSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loading = false;
      state.trialBalances = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getTrialBalanceFail: (state) => {
      state.loading = false;
    },
    getTrialBalanceNextRequest: (state, payload) => {
      state.loading = true;
    },
    getTrialBalancePreviousRequest: (state, payload) => {
      state.loading = true;
    },

    clearTrialBalanceData: (state) => {
      state.trialBalances = null;
    },
  },
});
// Action creators are generated for each case reducer function
const { actions, reducer } = TrialBalance;
export const {
  loadingTrialBalance,
  getTrialBalanceRequest,
  getTrialBalanceNextRequest,
  getTrialBalancePreviousRequest,
  getTrialBalanceSuccess,
  getTrialBalanceFail,
  clearTrialBalanceData,
} = actions;
export default reducer;
