import {
  loadingJournal,
  getJournalRequest,
  getJournalNextRequest,
  getJournalPreviousRequest,
  getJournalSuccess,
  getJournalFail,
  createJournalRequest,
  createJournalSuccess,
  createJournalFail,
  updateJournalRequest,
  updateJournalSuccess,
  updateJournalFail,
  clearJournalData,
  getAccountRequest,
  getAccountSuccess,
  getAccountFail,
  approveVoucherRequest,
  approveVoucherSuccess,
  approveVoucherFail,
  cancelVoucherRequest,
  cancelVoucherSuccess,
  cancelVoucherFail,
  getJournalVoucherByIdRequest,
  journalVoucherEditSuccess,
  journalVoucherEditFail,
} from "./journalSlice";
import { mergeMap } from "rxjs";
import {
  createJournal,
  getJournal,
  updateJournal,
  getAccount,
  approveVoucher,
  getJournalVoucherById,
  cancelVoucher,
} from "./api";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";

export const controller = new AbortController();

// get AccountHead epic
const getJournalEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getJournalRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingJournal());
      try {
        const response = await getJournal(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getJournalSuccess(action?.payload) : getJournalFail()
    )
  );
//get next
const getJournalNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getJournalNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingJournal());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getJournalSuccess(action?.payload) : getJournalFail()
    )
  );

//get previous
const getJournalPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getJournalPreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingJournal());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getJournalSuccess(action?.payload) : getJournalFail()
    )
  );
//create AccountHead epic
const createJournalEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createJournalRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      const { journalDateAd, journalDateBs, reference, voucherDetails, note } =
        values;
      try {
        const body = JSON.stringify({
          journalDateAd,
          journalDateBs,
          reference,
          voucherDetails,
          note,
        });
        const response = await createJournal(body);
        if (response) {
          dispatch(getJournalRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(clearJournalData());
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? createJournalSuccess() : createJournalFail();
    })
  );

const geJournalVoucherByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getJournalVoucherByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getJournalVoucherById(action.payload?.id);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? journalVoucherEditSuccess(action?.payload)
        : journalVoucherEditFail()
    )
  );

//update AccountHead epic
const updateJournalEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateJournalRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      const {
        voucherNoFull,
        journalDateAd,
        journalDateBs,
        reference,
        journalVoucherDetails,
        note,
        remarks,
      } = values;
      try {
        const body = JSON.stringify({
          voucherNoFull,
          journalDateAd,
          journalDateBs,
          reference,
          journalVoucherDetails,
          note,
          remarks,
        });
        const response = await updateJournal(body, id);
        if (response) {
          dispatch(getJournalRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(clearJournalData());
          dispatch(closeModal());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateJournalSuccess() : updateJournalFail()
    )
  );

const getAccountEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAccountRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getAccount(action.payload);

        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getAccountSuccess(action?.payload) : getAccountFail()
    )
  );

const approveVoucherEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(approveVoucherRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      const {
        journalDateAd,
        journalDateBs,
        reference,
        journalVoucherDetails,
        note,
        // remarks,
      } = values;
      try {
        const body = JSON.stringify({
          journalDateAd,
          journalDateBs,
          reference,
          journalVoucherDetails,
          note,
          // remarks,
        });
        const response = await approveVoucher(body, id);
        if (response) {
          dispatch(getJournalRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(clearJournalData());
          dispatch(closeModal());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? approveVoucherSuccess() : approveVoucherFail()
    )
  );
const cancelVoucherEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(cancelVoucherRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      const {
        journalDateAd,
        journalDateBs,
        reference,
        journalVoucherDetails,
        note,
        // remarks,
      } = values;
      try {
        const body = JSON.stringify({
          journalDateAd,
          journalDateBs,
          reference,
          journalVoucherDetails,
          note,
          // remarks,
        });
        const response = await cancelVoucher(body, id);
        if (response) {
          dispatch(getJournalRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(clearJournalData());
          dispatch(closeModal());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? cancelVoucherSuccess() : cancelVoucherFail()
    )
  );

export const journalEpics = combineEpics(
  getJournalEpic,
  createJournalEpic,
  geJournalVoucherByIdEpic,
  updateJournalEpic,
  getJournalNext,
  getJournalPrevious,
  getAccountEpic,
  approveVoucherEpic,
  cancelVoucherEpic
);
