import { createSlice } from "@reduxjs/toolkit";
import { InitialState } from "./types";

const initialState: InitialState = {
  discountSchemas: [],
  edit: false,
  discountSchema: null,
  loadingDiscount: false,
  loadingCreateDiscount: false,
  count: 0,
  previous: "",
  next: "",
};

export const DiscountScheme = createSlice({
  name: "discountSchemeReducer",
  initialState,
  reducers: {
    getDiscountSchemaRequest: (state, payload) => {
      state.loadingDiscount = true;
    },
    getDiscountSchemaSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingDiscount = false;
      state.discountSchemas = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getDiscountSchemaFail: (state) => {
      state.loadingDiscount = false;
    },
    getDiscountSchemaNextRequest: (state, payload) => {
      state.loadingDiscount = true;
    },
    getDiscountSchemaPreviousRequest: (state, payload) => {
      state.loadingDiscount = true;
    },
    createDiscountSchemaRequest: (state, payload) => {
      state.loadingCreateDiscount = true;
    },
    updateDiscountSchemaRequest: (state, payload) => {
      state.loadingCreateDiscount = true;
    },
    createDiscountSchemaSuccess: (state) => {
      state.loadingCreateDiscount = false;
    },
    createDiscountSchemaFail: (state) => {
      state.loadingCreateDiscount = false;
    },
    getDiscountSchemeByIdRequest: (state, payload) => {
      state.edit = true;
    },
    discountSchemaEditSuccess: (state, { payload }) => {
      state.discountSchema = payload;
    },
    discountSchemeEditFail: (state) => {
      state.edit = false;
    },
    updateDiscountSchemaSuccess: (state) => {
      state.loadingCreateDiscount = false;
    },
    updateDiscountSchemaFail: (state) => {
      state.loadingCreateDiscount = false;
    },
    clearAllData: (state) => {
      state.edit = false;
      state.discountSchema = null;
    },
  },
});

// Action creaters generated for each case reducer function
export const {
  getDiscountSchemaRequest,
  getDiscountSchemaSuccess,
  getDiscountSchemaFail,
  getDiscountSchemaPreviousRequest,
  getDiscountSchemaNextRequest,
  createDiscountSchemaRequest,
  createDiscountSchemaSuccess,
  createDiscountSchemaFail,
  discountSchemaEditSuccess,
  updateDiscountSchemaSuccess,
  updateDiscountSchemaFail,
  clearAllData,
  updateDiscountSchemaRequest,
  getDiscountSchemeByIdRequest,
  discountSchemeEditFail,
} = DiscountScheme.actions;

export default DiscountScheme.reducer;
