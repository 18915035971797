/** @format */
import { combineEpics } from "redux-observable";
import { paymentModeEpics } from "../Pages/CoreSetupPages/PaymentMode/Redux/PaymentModeEpic";
import { userEpics } from "../Pages/CoreSetupPages/User/Redux/userEpic";
import { authEpics } from "../Pages/Login/Redux/authEpic";
import { countryEpics } from "../Pages/CoreSetupPages/Country/Redux/countryEpic";
import { provinceEpics } from "../Pages/CoreSetupPages/Province/Redux/provinceEpic";
import { districtEpics } from "../Pages/CoreSetupPages/District/Redux/districtEpic";
import { palikaEpics } from "../Pages/CoreSetupPages/Palika/Redux/palikaEpic";
import { taxGroupEpics } from "../Pages/CoreSetupPages/TaxGroup/Redux/taxGroupEpic";
import { organizationEpics } from "../Pages/CoreSetupPages/OrganizationSetup/Redux/organizationEpic";
import { titleEpics } from "../Pages/CoreSetupPages/Title/Redux/titleEpic";
import { discountSchemaEpics } from "../Pages/CoreSetupPages/DiscountScheme/Redux/DiscountSchemeEpic";
import { customerTypeEpics } from "../Pages/CoreSetupPages/CustomerType/Redux/customerTypeEpic";
import { storeEpics } from "../Pages/CoreSetupPages/Store/Redux/storeEpic";
import { userRoleEpics } from "../Pages/CoreSetupPages/UserRole/Redux/userRoleEpic";
import { additionalChargeEpic } from "../Pages/CoreSetupPages/AdditionalChargeType/Redux/AdditionalChargeEpic";
import { bankEpic } from "../Pages/CoreSetupPages/Bank/Redux/BankEpic";
import { organizationRuleEpics } from "../Pages/CoreSetupPages/OrganizationRule/Redux/organizationRuleEpic";
import { manufacturerEpics } from "../Pages/InventoryPages/Manufacturer/Redux/manufacturerEpic";
import { itemUnitEpics } from "../Pages/InventoryPages/ItemUnit/Redux/itemUnitEpic";
import { itemCategoryEpics } from "../Pages/InventoryPages/ItemCategory/Redux/itemCategoryEpic";
import { packingTypeEpics } from "../Pages/InventoryPages/PackingType/Redux/packingTypeEpic";
import { itemEpics } from "../Pages/InventoryPages/Item/Redux/itemEpic";
import { supplierEpics } from "../Pages/InventoryPages/Supplier/Redux/supplierEpic";
import { purchaseEpics } from "../Pages/InventoryPages/Purchase/Redux/purchaseEpic";
// lab epics
import { partyPaymentEpics } from "../Pages/InventoryPages/PartyPayment/Redux/partyPaymentEpic";

//common viewDetails
import { itemListDetailEpics } from "../Pages/ViewDetails/ItemList/Redux/ItemListDetailEpic";
import { purchaseReportEpics } from "../Pages/InventoryPages/Purchase/PurchaseReport/Redux/purchaseReportEpic";
import { saleReportEpics } from "../Pages/InventoryPages/Purchase/SaleReport/Redux/saleReportEpic";
import { customerEditEpics } from "../Pages/CoreSetupPages/CustomerEdit/Redux/customerEditEpic";
import { purchaseReturnEpics } from "../Pages/InventoryPages/PurchaseReturnPages/Redux/purchaseReturnEpic";
import { saleEpics } from "../Pages/InventoryPages/Sale/Redux/saleEpic";
import { commonAppEpics } from "../Pages/CommonAppRedux/commonAppEpic";
import { saleReturnEpics } from "../Pages/InventoryPages/SaleReturn/Redux/saleReturnEpic";
import { userActivityLogReportInventoryEpics } from "../Pages/InventoryPages/IRDReport/UserActivityLogReport/Redux/userActivityLogReportInventoryEpic";
import { materializedReportInventoryEpics } from "../Pages/InventoryPages/IRDReport/MaterializedReport/Redux/materializedReportInventoryEpic";
import { IRDSyncInventoryEpics } from "../Pages/InventoryPages/IRDReport/IRDSync/Redux/irdSyncInventoryEpic";
import { customerEpics } from "../Pages/InventoryPages/Customer/Redux/customerEpic";
import { CustomerWiseReportEpics } from "../Pages/InventoryPages/InventoryReport/CustomerWiseReport/Redux/customerWiseReportEpic";
import { SupplierWiseReportEpics } from "../Pages/InventoryPages/InventoryReport/SupplierWiseSaleReport/Redux/supplierWiseReportEpic";
import { creditClearanceEpics } from "../Pages/InventoryPages/CreditClearancePages/Redux/creditClearanceEpic";
import { passwordEpics } from "../Pages/Login/Password/Redux/passwordEpic";
import { UserWiseReportEpics } from "../Pages/InventoryPages/InventoryReport/UserWiseReport/Redux/userWiseReportEpic";
import { stockAnalysisReportInventoryEpics } from "../Pages/InventoryPages/InventoryReport/StockAnalysis/Redux/stockAnalysisReportInventoryEpic";
import { stockAnalysisEpics } from "../Pages/InventoryPages/StockAnalysis/Redux/stockAnalysisEpic";

// accounting
import { accountHeadEpics } from "../Pages/AccountingPages/AccountHead/Redux/accountHeadEpic";
import { journalEpics } from "../Pages/AccountingPages/Journal/Redux/journalEpic";
import { journalEntryEpics } from "../Pages/AccountingPages/JournalEntry/Redux/journalEntryEpic";
import { ledgerReportEpics } from "../Pages/AccountingPages/AccountReports/LedgerReport/Redux/ledgerReportEpic";
import { trialBalanceEpics } from "../Pages/AccountingPages/TrialBalance/Redux/trialBalanceEpic";

export const RootEpic = combineEpics(
  //common app
  commonAppEpics,
  userEpics,
  paymentModeEpics,
  authEpics,
  countryEpics,
  provinceEpics,
  districtEpics,
  palikaEpics,
  taxGroupEpics,
  manufacturerEpics,
  itemUnitEpics,
  itemCategoryEpics,
  packingTypeEpics,
  itemEpics,
  supplierEpics,
  userRoleEpics,
  organizationEpics,
  organizationRuleEpics,
  titleEpics,
  discountSchemaEpics,
  customerTypeEpics,
  storeEpics,
  purchaseEpics,
  purchaseReturnEpics,
  additionalChargeEpic,
  bankEpic,
  // inventory
  saleEpics,
  saleReturnEpics,
  customerEpics,
  partyPaymentEpics,
  //viewDetails
  itemListDetailEpics,
  //purchase report
  purchaseReportEpics,
  saleReportEpics,
  //customer edit
  customerEditEpics,
  userActivityLogReportInventoryEpics,
  materializedReportInventoryEpics,
  IRDSyncInventoryEpics,
  CustomerWiseReportEpics,
  SupplierWiseReportEpics,
  creditClearanceEpics,
  passwordEpics,
  UserWiseReportEpics,

  stockAnalysisReportInventoryEpics,
  stockAnalysisEpics,
  // accounting
  accountHeadEpics,
  journalEpics,
  journalEntryEpics,
  ledgerReportEpics,
  trialBalanceEpics
);
