import {
  createUserFail,
  createUserRequest,
  createUserSuccess,
  getUserFail,
  getUserSuccess,
  updateUserFail,
  updateUserSuccess,
  loginUserRequest,
  loginUserSuccess,
  loginUserFail,
  getUserRequest,
  getUserNextRequest,
  getUserPreviousRequest,
  updateUserRequest,
  getUserGroupSuccess,
  getUserGroupFail,
  getUserGroupRequest,
  clearUserData,
  getUserByIdRequest,
  userEditSuccess,
  userEditFail,
  updateProfileUserRequest,
} from "./userSlice";
import { mergeMap } from "rxjs";
import {
  createUser,
  getUser,
  updateUser,
  LoginUser,
  getUserGroup,
  getUserById,
  updateProfileUser,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();
//get user epic
const getUserEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getUserRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getUser(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getUserSuccess(action?.payload) : getUserFail()
    )
  );

const getUserGroupEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getUserGroupRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getUserGroup();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getUserGroupSuccess(action?.payload)
        : getUserGroupFail()
    )
  );
//get next
const getUserNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getUserNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getUserSuccess(action?.payload) : getUserFail()
    )
  );
//get previous
const getUserPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getUserPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getUserSuccess(action?.payload) : getUserFail()
    )
  );
//create user epic
const createUserEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createUserRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          if (Array.isArray(value)) {
            // append updated item price
            value.forEach((group: string, i: number) => {
              return body.append(`groups[${i}]`, group);
            });
          } else {
            // @ts-ignore
            body.append(`${key}`, value);
          }
        }
        const response = await createUser(body);
        if (response) {
          dispatch(getUserRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? createUserSuccess() : createUserFail();
    })
  );
//update user epic
const updateUserEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateUserRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page, search } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          if (Array.isArray(value)) {
            // append updated item price
            value.forEach((group: string, i: number) => {
              return body.append(`groups[${i}]`, group);
            });
          } else {
            // @ts-ignore
            body.append(`${key}`, value);
          }
        }

        const response = await updateUser(body, id);
        if (response) {
          dispatch(getUserRequest({ rowsPerPage, page, search }));
          dispatch(alertSuccessAction(messages.updateMessage));
          // dispatch(loginUserRequest());
          dispatch(closeModal());
          dispatch(clearUserData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) => (action?.payload ? updateUserSuccess() : updateUserFail()))
  );

const updateProfileUserEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateProfileUserRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page, search } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          if (Array.isArray(value)) {
            // append updated item price
            value.forEach((group: string, i: number) => {
              return body.append(`groups[${i}]`, group);
            });
          } else {
            // @ts-ignore
            body.append(`${key}`, value);
          }
        }

        const response = await updateProfileUser(body);
        if (response) {
          dispatch(getUserRequest({ rowsPerPage, page, search }));
          dispatch(alertSuccessAction(messages.updateMessage));
          // dispatch(loginUserRequest());
          dispatch(closeModal());
          dispatch(clearUserData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) => (action?.payload ? updateUserSuccess() : updateUserFail()))
  );

const getLoginUser = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(loginUserRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await LoginUser();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? loginUserSuccess(action?.payload) : loginUserFail()
    )
  );
const getUserByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getUserByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getUserById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? userEditSuccess(action.payload) : userEditFail()
    )
  );
export const userEpics = combineEpics(
  // user
  getUserEpic,
  createUserEpic,
  updateUserEpic,
  getUserNext,
  getUserPrevious,
  getLoginUser,
  getUserGroupEpic,
  getUserByIdEpic,
  updateProfileUserEpic
);
