import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

export const trialBalanceModURL =
  "api/v1/account-report-app/trial-balance/summary";

const getReportQuery = (updatedValue: any) => {
  const { startDateAd, endDateAd } = updatedValue;
  return `&date_after=${startDateAd}&date_before=${endDateAd}`;
};
//get Trial Balance data api
export const getTrialBalance = (data: any) => {
  const { updatedValue } = data;
  return axiosInstance.get(
    `${trialBalanceModURL}?${getReportQuery(updatedValue)}`
  );
};

export const searchTrialBalance = ({ rowsPerPage, search }: searchProps) => {
  return `${trialBalanceModURL}?offset=0&limit=${rowsPerPage}&ordering=-id&search=${search}`;
};
