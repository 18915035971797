import {
  createItemCategorySuccess,
  createItemCategoryFail,
  createItemCategoryRequest,
  getItemCategorySuccess,
  getItemCategoryFail,
  loadingItemCategory,
  updateItemCategoryFail,
  updateItemCategorySuccess,
  getItemCategoryRequest,
  updateItemCategoryRequest,
  getItemCategoryNextRequest,
  getItemCategoryPreviousRequest,
  clearItemCategoryData,
} from "./itemCategorySlice";
import { mergeMap } from "rxjs";
import { createItemCategory, getItemCategory, updateItemCategory } from "./api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";

export const controller = new AbortController();

// get itemCategory epic
const getItemCategoryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getItemCategoryRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingItemCategory());
      try {
        const response = await getItemCategory(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getItemCategorySuccess(action?.payload)
        : getItemCategoryFail()
    )
  );
//get next
const getItemCategoryNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getItemCategoryNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingItemCategory());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getItemCategorySuccess(action?.payload)
        : getItemCategoryFail()
    )
  );

//get previous
const getItemCategoryPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getItemCategoryPreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingItemCategory());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getItemCategorySuccess(action?.payload)
        : getItemCategoryFail()
    )
  );
//create ItemCategory epic
const createItemCategoryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createItemCategoryRequest.match),
    mergeMap(
      async ({
        payload: { values, rowsPerPage, page, setShowModalFromAnotherModule },
      }) => {
        const { name, code, displayOrder, active } = values;
        try {
          const body = JSON.stringify({ name, code, displayOrder, active });
          const response = await createItemCategory(body);
          if (response) {
            dispatch(getItemCategoryRequest({ rowsPerPage, page }));
            dispatch(alertSuccessAction(messages.createMessage));
            setShowModalFromAnotherModule
              ? setShowModalFromAnotherModule(false)
              : dispatch(closeModal());
          }
          return { payload: { response } };
        } catch (e) {
          dispatch(alertErrorAction(messages.createFailMessage));
          return { error: e };
        }
      }
    ),
    map((action) => {
      return action?.payload
        ? createItemCategorySuccess()
        : createItemCategoryFail();
    })
  );

//update ItemCategory epic
const updateItemCategoryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateItemCategoryRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      const { name, code, displayOrder, active, remarks } = values;
      try {
        const body = JSON.stringify({
          name,
          code,
          displayOrder,
          active,
          remarks,
        });
        const response = await updateItemCategory(body, id);
        if (response) {
          dispatch(getItemCategoryRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(clearItemCategoryData());
          dispatch(closeModal());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateItemCategorySuccess() : updateItemCategoryFail()
    )
  );

export const itemCategoryEpics = combineEpics(
  getItemCategoryEpic,
  createItemCategoryEpic,
  updateItemCategoryEpic,
  getItemCategoryNext,
  getItemCategoryPrevious
);
