import {
  createAccountHeadSuccess,
  createAccountHeadFail,
  createAccountHeadRequest,
  getAccountHeadSuccess,
  getAccountHeadFail,
  loadingAccountHead,
  updateAccountHeadFail,
  updateAccountHeadSuccess,
  getAccountHeadRequest,
  updateAccountHeadRequest,
  getAccountHeadNextRequest,
  getAccountHeadPreviousRequest,
  clearAccountHeadData,
  getParentRequest,
  getParentSuccess,
  getParentFail,
  getTreeViewRequest,
  loadingTreeView,
  getTreeViewSuccess,
  getTreeViewFail,
} from "./accountHeadSlice";
import { mergeMap } from "rxjs";
import {
  createAccountHead,
  getAccountHead,
  getParent,
  updateAccountHead,
  getTreeView,
} from "./api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";

export const controller = new AbortController();

// get AccountHead epic
const getAccountHeadEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAccountHeadRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingAccountHead());
      try {
        const response = await getAccountHead(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getAccountHeadSuccess(action?.payload)
        : getAccountHeadFail()
    )
  );
//get next
const getAccountHeadNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAccountHeadNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingAccountHead());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getAccountHeadSuccess(action?.payload)
        : getAccountHeadFail()
    )
  );

//get previous
const getAccountHeadPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAccountHeadPreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingAccountHead());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getAccountHeadSuccess(action?.payload)
        : getAccountHeadFail()
    )
  );
//create AccountHead epic
const createAccountHeadEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createAccountHeadRequest.match),
    mergeMap(
      async ({
        payload: { values, rowsPerPage, page, setShowModalFromAnotherModule },
      }) => {
        const { name, parent, isGroup, description, isLocked } = values;
        try {
          const body = JSON.stringify({
            name,
            parent,
            isGroup,
            description,
            isLocked,
          });
          const response = await createAccountHead(body);
          if (response) {
            dispatch(getAccountHeadRequest({ rowsPerPage, page }));
            dispatch(alertSuccessAction(messages.createMessage));
            setShowModalFromAnotherModule
              ? setShowModalFromAnotherModule(false)
              : dispatch(closeModal());
          }
          return { payload: { response } };
        } catch (e) {
          dispatch(alertErrorAction(messages.createFailMessage));
          return { error: e };
        }
      }
    ),
    map((action) => {
      return action?.payload
        ? createAccountHeadSuccess()
        : createAccountHeadFail();
    })
  );

//update AccountHead epic
const updateAccountHeadEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateAccountHeadRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      const { name, parent, isGroup, description, isLocked, remarks } = values;
      try {
        const body = JSON.stringify({
          name,
          parent,
          isGroup,
          description,
          isLocked,
          remarks,
        });
        const response = await updateAccountHead(body, id);
        if (response) {
          dispatch(getAccountHeadRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(clearAccountHeadData());
          dispatch(closeModal());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateAccountHeadSuccess() : updateAccountHeadFail()
    )
  );

const getParentEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getParentRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getParent(action.payload);

        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getParentSuccess(action?.payload) : getParentFail()
    )
  );

const getTreeViewEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTreeViewRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingTreeView());
      try {
        const response = await getTreeView();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getTreeViewSuccess(action?.payload) : getTreeViewFail()
    )
  );
export const accountHeadEpics = combineEpics(
  getAccountHeadEpic,
  createAccountHeadEpic,
  updateAccountHeadEpic,
  getAccountHeadNext,
  getAccountHeadPrevious,
  getParentEpic,
  getTreeViewEpic
);
