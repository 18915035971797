import {
  getPaymentModeRequest,
  getPaymentModeSuccess,
  getPaymentModeFail,
  getPaymentModeNextRequest,
  getPaymentModePreviousRequest,
  getCashRequest,
  getCashSuccess,
  getCashFail,
  createPaymentModeFail,
  createPaymentModeRequest,
  createPaymentModeSuccess,
  updatePaymentModeFail,
  updatePaymentModeRequest,
  updatePaymentModeSuccess,
  clearPaymentModeData,
  getPaymentModeByIdRequest,
  paymentModeEditSuccess,
  paymentModeEditFail,
} from "./PaymentModeSlice";
import { mergeMap } from "rxjs";
import {
  getPaymentMode,
  createPaymentMode,
  updatePaymentMode,
  getCash,
  getPaymentModeById,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();

//get payment mode epic
const getPaymentModeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPaymentModeRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPaymentMode(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPaymentModeSuccess(action?.payload)
        : getPaymentModeFail()
    )
  );

//get payment Mode by id epic
const getPaymentModeByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPaymentModeByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPaymentModeById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? paymentModeEditSuccess(action.payload)
        : paymentModeEditFail()
    )
  );

//get cash epic
export const getCashEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCashRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getCash();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getCashSuccess(action?.payload) : getCashFail()
    )
  );

//create paymentMode epic
const createPaymentModeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createPaymentModeRequest.match),
    mergeMap(
      async ({
        payload: { values, rowsPerPage, page, setShowModalFromAnotherModule },
      }) => {
        try {
          const body = new FormData();
          for (let [key, value] of Object.entries(values)) {
            body.append(`${key}`, String(value));
          }
          const response = await createPaymentMode(body);
          if (response) {
            dispatch(getPaymentModeRequest({ rowsPerPage, page }));
            dispatch(alertSuccessAction(messages.createMessage));
            setShowModalFromAnotherModule
              ? setShowModalFromAnotherModule(false)
              : dispatch(closeModal());
          }
          return { payload: { response } };
        } catch (e) {
          dispatch(alertErrorAction(messages.createFailMessage));
          return { error: e };
        }
      }
    ),
    map((action) => {
      return action?.payload
        ? createPaymentModeSuccess()
        : createPaymentModeFail();
    })
  );

//update payment epic
const updatePaymentModeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updatePaymentModeRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page, search } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          body.append(`${key}`, String(value));
        }
        const response = await updatePaymentMode(body, id);
        if (response) {
          dispatch(getPaymentModeRequest({ rowsPerPage, page, search }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearPaymentModeData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updatePaymentModeSuccess() : updatePaymentModeFail()
    )
  );

//get next
const getPaymentModeNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPaymentModeNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPaymentModeSuccess(action?.payload)
        : getPaymentModeFail()
    )
  );

//get previous
const getPaymentModePrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPaymentModePreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPaymentModeSuccess(action?.payload)
        : getPaymentModeFail()
    )
  );

export const paymentModeEpics = combineEpics(
  getPaymentModeEpic,
  getCashEpic,
  createPaymentModeEpic,
  updatePaymentModeEpic,
  getPaymentModeNext,
  getPaymentModePrevious,
  getPaymentModeByIdEpic
);
