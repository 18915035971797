import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { useImmer } from "use-immer";
import { importCSVFilePurchase } from "../../../Pages/InventoryPages/Purchase/Redux/api";
import {
  importCSVFilePurchaseRequest,
  // showImportModal,
} from "../../../Pages/InventoryPages/Purchase/Redux/purchaseSlice";
import { importCSVFileSalesRequest } from "../../../Pages/InventoryPages/Sale/Redux/saleSlice";
import AppButton from "../Button/AppButton";
import {
  showImportModal,
  showModal,
} from "../../../Pages/CommonAppRedux/CommonAppSlice";

export const FileImport = () => {
  const dispatch = useDispatch();

  return (
    <div>
      <form>
        {/* <input
          type={"file"}
          id={"csvFileInput"}
          accept={".csv"}
          onChange={handleOnChange}
          style={{ color: "#1e5b87" }}
        /> */}

        <AppButton
          style={{ height: "inherit" }}
          type="submit"
          tooltipTitle="Import csv"
          onClick={() => {
            // handleOnSubmit(e);
            dispatch(showImportModal());
          }}
        >
          Import
        </AppButton>
      </form>

      <br />

      {/* <table>
        <thead>
          <tr key={"header"}>
            {headerKeys.map((key) => (
              <th>{key}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {array.map((item: any) => (
            <tr key={item?.id}>
              {Object.values(item).map((val: any) => (
                <td>{val}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table> */}
    </div>
  );
};
export default memo(FileImport);
