import { createSlice, createAction } from "@reduxjs/toolkit";
import { purchaseReportInitialState } from "./types";
const initialState: purchaseReportInitialState = {
  suppliers: [],
  users: [],
  items: [],
  purchaseDetails: [],
  //loading
  loadingGenerateReport: false,
  loadingPurchaseReportSupplier: false,
  loadingPurchaseReportItem: false,
  loadingPurchaseReportUser: false,
  loadingPurchaseReportDetail: false,
  // loadingPurchaseReportAllSummary:false,
  // loadingPurchaseReportAllDetail:false,
  // loadingPurchaseReportQuickSummary:false,
  // loadingPurchaseReportQuickDetail:false,
  count: 0,
  previous: "",
  next: "",
};

// export const getPurchaseReportDetailRequest = createAction(
//   "getPurchaseReportDetailRequest",
//   function prepare({ detailsRowsPerPage, page }) {
//     return {
//       payload: {
//         detailsRowsPerPage,
//         page,
//       },
//     };
//   }
// );
export const PurchaseReport = createSlice({
  name: "purchaseReportReducer",
  initialState,
  reducers: {
    //loading
    loadingPurchaseReportDetail: (state) => {
      state.loadingPurchaseReportDetail = true;
    },
    //external reducer request
    getPurchaseReportDetailRequest: (state, { payload }) => {
      state.loadingPurchaseReportDetail = true;
    },
    getPurchaseReportSupplierRequest: (state) => {
      state.loadingPurchaseReportSupplier = true;
    },
    getPurchaseReportItemRequest: (state) => {
      state.loadingPurchaseReportItem = true;
    },
    getPurchaseReportUserRequest: (state) => {
      state.loadingPurchaseReportUser = true;
    },
    getPurchaseReportAllSummaryRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getPurchaseReportAllDetailRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getPurchaseReportQuickSummaryRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getPurchaseReportQuickDetailRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getPurchaseReportNextRequest: (state, payload) => {
      state.loadingPurchaseReportDetail = true;
    },
    getPurchaseReportPreviousRequest: (state, payload) => {
      state.loadingPurchaseReportDetail = true;
    },
    //external get reducer
    getPurchaseReportDetailSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.purchaseDetails = results;
      state.loadingPurchaseReportDetail = false;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getPurchaseReportDetailFail: (state) => {
      state.loadingPurchaseReportDetail = false;
    },
    getPurchaseReportUserSuccess: (state, { payload: { results } }) => {
      state.loadingPurchaseReportUser = false;
      state.users = results;
    },
    getPurchaseReportUserFail: (state) => {
      state.loadingPurchaseReportUser = false;
    },
    getPurchaseReportSupplierSuccess: (state, { payload: { results } }) => {
      state.loadingPurchaseReportSupplier = false;
      state.suppliers = results;
    },
    getPurchaseReportSupplierFail: (state) => {
      state.loadingPurchaseReportSupplier = false;
    },
    getPurchaseReportItemSuccess: (state, { payload: { results } }) => {
      state.loadingPurchaseReportItem = false;
      state.items = results;
    },
    getPurchaseReportItemFail: (state) => {
      state.loadingPurchaseReportItem = false;
    },
    getPurchaseReportAllSummarySuccess: (state, { payload: { results } }) => {
      state.loadingGenerateReport = false;
      state.purchaseDetails = results;
    },
    getPurchaseReportAllSummaryFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getPurchaseReportQuickSummarySuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingGenerateReport = false;
      state.purchaseDetails = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getPurchaseReportQuickSummaryFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getPurchaseReportAllDetailSuccess: (state, { payload: { results } }) => {
      state.loadingGenerateReport = false;
      state.purchaseDetails = results;
    },
    getPurchaseReportAllDetailFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getPurchaseReportQuickDetailSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingGenerateReport = false;
      state.purchaseDetails = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getPurchaseReportQuickDetailFail: (state) => {
      state.loadingGenerateReport = false;
    },

    clearPurchaseReportData: (state) => {
      state.suppliers = [];
      state.users = [];
      state.items = [];
    },
    clearPrintDetails: (state) => {
      state.purchaseDetails = [];
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  //reducerReport
  getPurchaseReportDetailRequest,
  getPurchaseReportSupplierRequest,
  getPurchaseReportItemRequest,
  getPurchaseReportUserRequest,
  getPurchaseReportAllSummaryRequest,
  getPurchaseReportAllDetailRequest,
  getPurchaseReportQuickSummaryRequest,
  getPurchaseReportQuickDetailRequest,
  getPurchaseReportPreviousRequest,
  getPurchaseReportNextRequest,
  //reducer get data
  getPurchaseReportDetailSuccess,
  getPurchaseReportDetailFail,
  getPurchaseReportItemSuccess,
  getPurchaseReportItemFail,
  getPurchaseReportSupplierSuccess,
  getPurchaseReportSupplierFail,
  getPurchaseReportUserSuccess,
  getPurchaseReportUserFail,
  getPurchaseReportQuickDetailSuccess,
  getPurchaseReportQuickDetailFail,
  getPurchaseReportAllDetailSuccess,
  getPurchaseReportAllDetailFail,
  getPurchaseReportQuickSummarySuccess,
  getPurchaseReportQuickSummaryFail,
  getPurchaseReportAllSummarySuccess,
  getPurchaseReportAllSummaryFail,
  //loading
  loadingPurchaseReportDetail,
  //clear
  clearPurchaseReportData,
  clearPrintDetails,
} = PurchaseReport.actions;
export default PurchaseReport.reducer;
