import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

const packingTypeModURL = "api/v1/inventory-item-app/packing-type";

//get PackingType data api
export const getPackingType = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${packingTypeModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};
// get unit packing type
export const getUnitPackingType = () =>
  axiosInstance.get("api/v1/inventory-item-app/packing-type?unit=true");
//handle search
export const searchPackingType = ({ rowsPerPage, search }: searchProps) => {
  return `${packingTypeModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
//create PackingType
export const createPackingType = (body: string | FormData) =>
  axiosInstance.post(`${packingTypeModURL}`, body);
//update PackingType
export const updatePackingType = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${packingTypeModURL}/${id}`, body);
