import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  provinces: [],
  loadingProvince: false,
  loadingCreateProvince: false,
  edit: false,
  province: null,
  count: 0,
  previous: "",
  next: "",
};

export const Province = createSlice({
  name: "ProvinceReducer",
  initialState,
  reducers: {
    loadingProvince: (state) => {
      state.loadingProvince = true;
    },
    getProvinceRequest: (state, payload) => {
      state.loadingProvince = true;
    },
    getProvinceSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingProvince = false;
      state.provinces = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getProvinceFail: (state) => {
      state.loadingProvince = false;
    },
    getProvinceNextRequest: (state, payload) => {
      state.loadingProvince = true;
    },
    getProvincePreviousRequest: (state, payload) => {
      state.loadingProvince = true;
    },
    createProvinceRequest: (state, payload) => {
      state.loadingCreateProvince = true;
      state.closeModal = true;
    },
    createProvinceSuccess: (state) => {
      state.loadingCreateProvince = false;
    },
    createProvinceFail: (state) => {
      state.loadingCreateProvince = false;
    },
    getProvinceByIdRequest: (state, payload) => {
      state.edit = true;
    },
    provinceEditSuccess: (state, { payload }) => {
      state.province = payload;
    },
    provinceEditFail: (state) => {
      state.edit = false;
    },
    updateProvinceRequest: (state, payload) => {
      state.loadingCreateProvince = true;
    },
    updateProvinceSuccess: (state) => {
      state.loadingCreateProvince = false;
    },
    updateProvinceFail: (state) => {
      state.loadingCreateProvince = false;
    },
    clearProvinceData: (state) => {
      state.edit = false;
      state.province = null;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingProvince,
  getProvinceRequest,
  getProvinceSuccess,
  getProvinceFail,
  getProvinceNextRequest,
  getProvincePreviousRequest,
  createProvinceRequest,
  createProvinceSuccess,
  createProvinceFail,
  provinceEditSuccess,
  updateProvinceRequest,
  updateProvinceSuccess,
  updateProvinceFail,
  clearProvinceData,
  getProvinceByIdRequest,
  provinceEditFail,
} = Province.actions;
export default Province.reducer;
