import axios from "axios";
import deleteCookie from "./Cookies/deleteCookie";
import getCookie from "./Cookies/getCookie";
import setCookie from "./Cookies/setCookie";
import { store } from "../../Store/index";
import {
  alertErrorAction,
  alertSuccessAction,
} from "../../Pages/CommonAppRedux/CommonAppSlice";
import { loginFail } from "../../Pages/Login/Redux/authSlice";
//@ts-ignore
const abortController = new AbortController();
export const axiosInstance = axios.create({
  baseURL:
    `${process.env.REACT_APP_HTTP_SCHEME}` + process.env.REACT_APP_BASE_URL,
});
const valuescheck = localStorage.getItem("url");
axiosInstance.interceptors.request.use((config) => {
  if (!window.navigator.onLine) {
    store.dispatch(alertErrorAction("No Internet"));
  } else {
    config.headers = {
      Authorization:
        config.url !== "branches" && getCookie("accessToken")
          ? `Bearer ${getCookie("accessToken")}`
          : "",
      "Content-Type": "application/json",
    };
    config.baseURL =
      localStorage.getItem("url") !== null
        ? config.url === "branches"
          ? `${process.env.REACT_APP_HTTP_SCHEME}` +
            process.env.REACT_APP_BASE_URL
          : `${process.env.REACT_APP_HTTP_SCHEME}${localStorage.getItem("url")}`
        : `${process.env.REACT_APP_HTTP_SCHEME}` +
          process.env.REACT_APP_BASE_URL;

    config.params = config.params || {};
    if (config.data !== undefined) {
      if (config?.data?.isLogin) {
        return config;
      } else if (typeof config.data === "object") {
        let bodyFormData = config.data;
        bodyFormData.append("appType", "1");
        bodyFormData.append("deviceType", "2");
        config.data = bodyFormData;
      } else if (typeof config.data === "string") {
        let bodyData = JSON.parse(config.data);
        config.data = { ...bodyData, appType: 1, deviceType: 2 };
      }
    }
    // config.params["auth"] = accessToken;
    return config;
  }
});

// axiosInstance.interceptors.request.eject(myInterceptor);
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    //for handling no internet
    if (axios.isCancel(error)) {
    } else if (error === "No Internet") {
      store.dispatch(alertErrorAction("No Internet"));
    }
    //for handling CORS error
    else if (error.toJSON().message === "Network Error") {
      store.dispatch(alertErrorAction("Network Error"));
    }
    //for handling page not found
    else if (error.response?.status === 404) {
      store.dispatch(alertErrorAction("Page Not Found"));
    }
    //internal server error
    else if (error.response?.status === 500 || error.response?.status > 500) {
      store.dispatch(alertErrorAction("Internal server Error"));
    }
    //to handle forbidden response status
    else if (error.response?.status === 403) {
      store.dispatch(alertErrorAction("403"));
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    //refresh token
    const originalRequest = error.config;
    //when refresh token is also not valid and preventing the infinite loop
    if (
      error.response.status === 401 &&
      originalRequest.url === "api/v1/user-app/login/refresh"
    ) {
      store.dispatch(alertErrorAction("unAuthorized"));
    }
    const value = {
      refresh: getCookie("refreshToken"),
    };
    if (error?.response?.status === 401 && value?.refresh === undefined) {
      store.dispatch(alertErrorAction("No Cookies Found"));
      store.dispatch(loginFail());
    }
    if (
      error.response.status === 401 &&
      (value?.refresh || value?.refresh === "")
    ) {
      //call for refresh token
      deleteCookie("accessToken");
      const body = JSON.stringify(value);
      axiosInstance
        .post("api/v1/user-app/login/refresh", body)
        .then((response) => {
          if (response.status === 200) {
            store.dispatch(
              alertSuccessAction("New Access Token Generate Successfully")
            );
            setCookie("accessToken", response?.data?.access, {
              secure: true,
              // "max-age": 360000,
            });
            originalRequest.headers[
              "Authorization"
            ] = `Bearer ${response?.data.access}`;
            // setTimeout(()=>{
            //   window.location.reload();
            // },500)
            return axiosInstance(originalRequest);
          }
        })
        .catch((error) => {
          if (error.response.data.code === "token_not_valid") {
            store.dispatch(
              alertErrorAction("Refresh Token is Not Valid Or Expired")
            );
          }
          store.dispatch(loginFail());
          return Promise.reject(error);
        });
    }
    // Do something with response error
    return Promise.reject(error);
  }
);
