import {
  createAdditionalChargeFail,
  createAdditionalChargeRequest,
  createAdditionalChargeSuccess,
  getAdditionalChargeFail,
  getAdditionalChargeSuccess,
  updateAdditionalChargeFail,
  updateAdditionalChargeSuccess,
  getAdditionalChargeRequest,
  getAdditionalChargeNextRequest,
  getAdditionalChargePreviousRequest,
  updateAdditionalChargeRequest,
  clearAllData,
  getAdditionalChargeByIdRequest,
  additionalChargeEditSuccess,
  additionalChargeEditFail,
} from "./AdditionalChargeSlice";
import { mergeMap } from "rxjs";
import {
  getAdditionalCharge,
  createAdditionalCharge,
  updateAdditionalCharge,
  getAdditionalChargeById,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();

//get Additional charge
const getAdditionalChargeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAdditionalChargeRequest.match),
    mergeMap(async (action: any) => {
      try {
        const response = await getAdditionalCharge(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getAdditionalChargeSuccess(action?.payload)
        : getAdditionalChargeFail()
    )
  );

//get additional charge by Id
const getAdditionalChargeByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAdditionalChargeByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getAdditionalChargeById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? additionalChargeEditSuccess(action.payload)
        : additionalChargeEditFail()
    )
  );

//create
const createAdditionalChargeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createAdditionalChargeRequest.match),
    mergeMap(
      async ({
        payload: { values, rowsPerPage, page, setShowModalFromAnotherModule },
      }) => {
        try {
          const body = new FormData();
          for (let [key, value] of Object.entries(values)) {
            body.append(`${key}`, String(value));
          }
          const response = await createAdditionalCharge(body);
          if (response) {
            dispatch(getAdditionalChargeRequest({ rowsPerPage, page }));
            dispatch(alertSuccessAction(messages.createMessage));
            setShowModalFromAnotherModule
              ? setShowModalFromAnotherModule(false)
              : dispatch(closeModal());
          }
          return { payload: { response } };
        } catch (e) {
          dispatch(alertErrorAction(messages.createFailMessage));
          return { error: e };
        }
      }
    ),
    map((action) => {
      return action?.payload
        ? createAdditionalChargeSuccess()
        : createAdditionalChargeFail();
    })
  );

//update
const updateAdditionalChargeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateAdditionalChargeRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page, search } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          body.append(`${key}`, String(value));
        }
        const response = await updateAdditionalCharge(body, id);
        if (response) {
          dispatch(getAdditionalChargeRequest({ rowsPerPage, page, search }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearAllData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? updateAdditionalChargeSuccess()
        : updateAdditionalChargeFail();
    })
  );

//get next
const getAdditionalChargeNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAdditionalChargeNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? getAdditionalChargeSuccess(action?.payload)
        : getAdditionalChargeFail();
    })
  );

//get previous
const getAdditonalChargePrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAdditionalChargePreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getAdditionalChargeSuccess(action?.payload)
        : getAdditionalChargeFail()
    )
  );

export const additionalChargeEpic = combineEpics(
  getAdditionalChargeEpic,
  createAdditionalChargeEpic,
  updateAdditionalChargeEpic,
  getAdditionalChargeNext,
  getAdditonalChargePrevious,
  getAdditionalChargeByIdEpic
);
