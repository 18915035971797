import {
  loadingTrialBalance,
  getTrialBalanceRequest,
  getTrialBalanceNextRequest,
  getTrialBalancePreviousRequest,
  getTrialBalanceSuccess,
  getTrialBalanceFail,
} from "./trialBalanceSlice";
import { mergeMap } from "rxjs";
import { getTrialBalance } from "./api";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";

export const controller = new AbortController();

// get AccountHead epic
const getTrialBalanceEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTrialBalanceRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingTrialBalance());
      try {
        const response = await getTrialBalance(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getTrialBalanceSuccess(action?.payload)
        : getTrialBalanceFail()
    )
  );
//get next
const getTrialBalanceNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTrialBalanceNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingTrialBalance());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getTrialBalanceSuccess(action?.payload)
        : getTrialBalanceFail()
    )
  );

//get previous
const getTrialBalancePrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTrialBalancePreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingTrialBalance());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getTrialBalanceSuccess(action?.payload)
        : getTrialBalanceFail()
    )
  );

export const trialBalanceEpics = combineEpics(
  getTrialBalanceEpic,
  getTrialBalanceNext,
  getTrialBalancePrevious
);
