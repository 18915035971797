import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import {
  searchProps,
  getApiProps,
} from "../../../../AppUtils/Utils/globalTypes";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";

const additionalChargeModURL = "api/v1/core-app/additional-charge-type";

//get additional charge
export const getAdditionalCharge = (data: getApiProps) => {
  const { rowsPerPage, page, search } = data;
  return axiosInstance.get(
    `${additionalChargeModURL}?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}&search=${search ?? ""}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

//get additional charge by Id
export const getAdditionalChargeById = (id: number) => {
  return axiosInstance.get(`${additionalChargeModURL}/${id}`);
};

//get all additional charge
export const getAllAdditionalCharge = () =>
  axiosInstance.get(`${additionalChargeModURL}`);

//search additional charge
export const searchAdditionalCharge = ({
  rowsPerPage,
  search,
}: searchProps) => {
  return `${additionalChargeModURL}?offset=0&limit=${rowsPerPage}&search=${
    search ?? ""
  }`;
};

//update additional charge
export const updateAdditionalCharge = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${additionalChargeModURL}/${id}`, body);

//create additional charge
export const createAdditionalCharge = (body: string | FormData) =>
  axiosInstance.post(`${additionalChargeModURL}`, body);
