import { axiosInstance } from "../../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../../AppUtils/Utils/globalTypes";
import { UserActivityLogReportInventory } from "./types";

const UserActivityLogReportInventoryModURL =
  "/api/v1/inventory-ird-report-app/user-activity-log-report";

export const getUserActivityLogReportInventorySupplier = () =>
  axiosInstance.get(
    `${UserActivityLogReportInventoryModURL}/supplier?offset=0&limit=${0}`
  );
export const getUserActivityLogReportInventoryUser = () =>
  axiosInstance.get(
    `${UserActivityLogReportInventoryModURL}/user?offset=0&limit=${0}`
  );
export const getUserActivityLogReportInventoryItem = () =>
  axiosInstance.get(
    `${UserActivityLogReportInventoryModURL}/item?offset=0&limit=${0}`
  );
export const getUserActivityLogReportInventoryAllReport = (data: any) => {
  const { updatedValue } = data;

  const {
    item,
    summary,
    detail,
    supplier,
    user,
    purchaseType,
    startDateAd,
    endDateAd,
  } = updatedValue;
  return axiosInstance.get(
    `${UserActivityLogReportInventoryModURL}/${
      summary ? "summary" : "detail"
    }?offset=0&limit=${0}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }&date_after=${startDateAd}&date_before=${endDateAd}`
  );
};
export const getUserActivityLogReportInventoryQuickReport = (data: any) => {
  const { rowsPerPage, page, updatedValue } = data;
  const {
    item,
    summary,
    detail,
    supplier,
    user,
    purchaseType,
    startDateAd,
    endDateAd,
  } = updatedValue;
  return axiosInstance.get(
    `${UserActivityLogReportInventoryModURL}?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }&date_after=${startDateAd}&date_before=${endDateAd}`
  );
};

export const searchUserActivityLogReportInventory = ({
  rowsPerPage,
  search,
  updatedValue,
}: any) => {
  const {
    item,
    summary,
    detail,
    supplier,
    user,
    purchaseType,
    startDateAd,
    endDateAd,
  } = updatedValue;

  return `${UserActivityLogReportInventoryModURL}?offset=0&limit=${rowsPerPage}&search=${search}&date_after=${startDateAd}&date_before=${endDateAd}`;
};
