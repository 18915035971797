import {
  getPurchaseFail,
  getPurchaseSuccess,
  loadingPurchase,
  getPurchaseRequest,
  getPurchaseSupplierRequest,
  getPurchaseSuppliersSuccess,
  getPurchaseSuppliersFail,
  getPurchaseItemsSuccess,
  getPurchaseItemsFail,
  getPurchaseItemRequest,
  getPurchasePackingTypeDetailRequest,
  getPurchasePackingTypeDetailSuccess,
  getPurchasePackingTypeDetailFail,
  getPurchasePaymentModeRequest,
  getPurchasePaymentModeSuccess,
  getPurchasePaymentModeFail,
  createPurchaseRequest,
  createPurchaseSuccess,
  createPurchaseFail,
  getViewPurchaseDetailsRequest,
  getViewPurchaseDetailsSuccess,
  getViewPurchaseDetailsFail,
  getPurchaseAdditionalChargeRequest,
  getPurchaseAdditionalChargeSuccess,
  getPurchaseAdditionalChargeFail,
  getPurchaseNextRequest,
  getPurchasePreviousRequest,
  getViewPurchaseDetailsNextRequest,
  getViewPurchaseDetailsPreviousRequest,
  editPurchaseSuccess,
  editPurchaseFail,
  getPurchaseDetailsById,
  getPurchaseDiscountSchemeRequest,
  getPurchaseDiscountSchemeSuccess,
  getPurchaseDiscountSchemeFail,
  updatePurchaseRequest,
  updatePurchaseSuccess,
  updatePurchaseFail,
  importCSVFilePurchaseRequest,
  importCSVFilePurchaseSuccess,
  importCSVFilePurchaseFail,
} from "./purchaseSlice";
import { mergeMap } from "rxjs";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import * as API from "./api";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
  getImportCSVMessage,
  clearImportCSVMessage,
} from "../../../CommonAppRedux/CommonAppSlice";
import messages from "../../../../AppUtils/Utils/validationConstants";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import { getUserByIdRequest } from "../../../CoreSetupPages/User/Redux/userSlice";
export const controller = new AbortController();
//get user epic
const getPurchaseEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPurchaseRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingPurchase());
      try {
        const response = await API.getPurchase(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getPurchaseSuccess(action?.payload) : getPurchaseFail()
    )
  );

//get next
const getPurchaseNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPurchaseNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getPurchaseSuccess(action?.payload) : getPurchaseFail()
    )
  );
//get previous
const getPurchasePrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPurchasePreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getPurchaseSuccess(action?.payload) : getPurchaseFail()
    )
  );
//create purchase epic
const createPurchaseEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createPurchaseRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      try {
        const body = JSON.stringify(values);
        const response = await API.createPurchase(body);
        if (response) {
          dispatch(getPurchaseRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
          // dispatch(clearPaymentDetails());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? createPurchaseSuccess() : createPurchaseFail();
    })
  );
//update purchase epic
const updatePurchaseEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updatePurchaseRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page, purId } }) => {
      try {
        const body = JSON.stringify(values);
        const response = await API.updatePurchase(body, purId);
        if (response) {
          dispatch(getPurchaseRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          // dispatch(clearPaymentDetails());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? updatePurchaseSuccess() : updatePurchaseFail();
    })
  );
//get purchase supplier  epic
const getPurchaseSupplierEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPurchaseSupplierRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getPurchaseSupplier(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPurchaseSuppliersSuccess(action?.payload)
        : getPurchaseSuppliersFail()
    )
  );
//get purchase item
const getPurchaseItemEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPurchaseItemRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getPurchaseItem(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPurchaseItemsSuccess(action?.payload)
        : getPurchaseItemsFail()
    )
  );
//get purchase packing type detail
const getPurchasePackingTypeDetailEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPurchasePackingTypeDetailRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getPurchasePackingTypeDetail(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPurchasePackingTypeDetailSuccess(action?.payload)
        : getPurchasePackingTypeDetailFail()
    )
  );

//get purchase payment mode
const getPurchasePaymentModeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPurchasePaymentModeRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getPurchasePaymentMode();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPurchasePaymentModeSuccess(action?.payload)
        : getPurchasePaymentModeFail()
    )
  );
//view details
const getViewPurchaseDetailsEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getViewPurchaseDetailsRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getPurchaseViewDetails(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getViewPurchaseDetailsSuccess(action?.payload)
        : getViewPurchaseDetailsFail()
    )
  );
//get next
const getPurchaseDetailNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getViewPurchaseDetailsNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getViewPurchaseDetailsSuccess(action?.payload)
        : getViewPurchaseDetailsFail()
    )
  );
//get previous
const getPurchaseDetailPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getViewPurchaseDetailsPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getViewPurchaseDetailsSuccess(action?.payload)
        : getViewPurchaseDetailsFail()
    )
  );
//get purchase additional charge
const getPurchaseAdditionalChargeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPurchaseAdditionalChargeRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getPurchaseAdditionalCharge();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPurchaseAdditionalChargeSuccess(action?.payload)
        : getPurchaseAdditionalChargeFail()
    )
  );
// get purchase by id
const getPurchaseByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPurchaseDetailsById.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getPurchaseEditDetailsById(action.payload);
        const { data } = await API.getPurchaseEditDetails(action.payload);
        return {
          payload: { purchase: response.data, purchaseDetails: data.results },
        };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? editPurchaseSuccess(action.payload) : editPurchaseFail()
    )
  );
// discount scheme
const getPurchaseDiscountSchemeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPurchaseDiscountSchemeRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getPurchaseDiscountScheme();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPurchaseDiscountSchemeSuccess(action?.payload)
        : getPurchaseDiscountSchemeFail()
    )
  );

const importCSVFilePurchaseEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(importCSVFilePurchaseRequest.match),
    mergeMap(
      async ({ payload: { values, forceImport, rowsPerPage, page } }) => {
        try {
          const body = new FormData();

          body.append("file", values);
          body.append("forceImport", forceImport);

          // const body = JSON.stringify(values);

          const response = await API.importCSVFilePurchase(body);
          if (response) {
            dispatch(getPurchaseRequest({ rowsPerPage, page }));
            dispatch(alertSuccessAction(messages.createMessage));
            dispatch(closeModal());
            dispatch(clearImportCSVMessage());
          }
          return { payload: { response } };
        } catch (e: any) {
          if (e?.response?.data?.status === "400") {
            dispatch(
              getImportCSVMessage({
                status: e?.response?.data?.status,
                msg: e.response?.data?.message,
              })
            );
          }
          // dispatch(alertErrorAction(messages.createFailMessage));
          return { error: e };
        }
      }
    ),
    map((action) => {
      return action?.payload
        ? importCSVFilePurchaseSuccess()
        : importCSVFilePurchaseFail();
    })
  );

// get purchase
export const purchaseEpics = combineEpics(
  // purchase
  getPurchaseEpic,
  getPurchaseNext,
  getPurchasePrevious,
  getPurchaseSupplierEpic,
  // item
  getPurchaseItemEpic,
  getPurchasePackingTypeDetailEpic,
  getPurchasePaymentModeEpic,
  createPurchaseEpic,
  // view details
  getViewPurchaseDetailsEpic,
  getPurchaseDetailNext,
  getPurchaseDetailPrevious,
  // additional charge
  getPurchaseAdditionalChargeEpic,
  // details used in edit purchase
  getPurchaseByIdEpic,
  // discount scheme
  getPurchaseDiscountSchemeEpic,
  // update purchase
  updatePurchaseEpic,

  importCSVFilePurchaseEpic
);
