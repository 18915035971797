import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

export const partyPaymentModURL = "api/v1/inventory-purchase-app/party-payment";

//get party payment
export const getPartyPayment = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${partyPaymentModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

//get party payment supplier
export const getPartyPaymentSupplier = () => {
  return axiosInstance.get(`${partyPaymentModURL}/supplier?offset=0&limit=0`);
};

// search party payment
export const searchPartyPayment = ({ rowsPerPage, search }: searchProps) => {
  return `${partyPaymentModURL}?offset=0&limit=${rowsPerPage}$search=${search}`;
};

//create party payment
export const createPartyPayment = (body: string | FormData) => {
  return axiosInstance.post(`${partyPaymentModURL}`, body);
};

// update party payment
export const updatePartyPayment = (body: string | FormData, id: number) => {
  return axiosInstance.patch(`${partyPaymentModURL}/${id}`, body);
};

// retrive party payment by id
export const getPartyPaymentById = (id: number) => {
  return axiosInstance.get(`${partyPaymentModURL}/${id}`);
};

//get payment mode
export const getPartyPaymentMode = () => {
  return axiosInstance.get("api/v1/core-app/payment-mode?offset=0&limit=0");
};
