import { createSlice } from "@reduxjs/toolkit";
import { saleReturnInitialState, specificSaleDetail } from "./types";
const initialState: saleReturnInitialState = {
  saleReturns: [],
  saleReturnCustomers: [],
  saleReturnItems: [],
  loadingSpecificSale: false,
  loadingCreateSaleReturn: false,
  specificSales: [],
  specificSaleDetails: [],
  // sale return additional charges
  loadingSaleReturnAdditionalCharges: false,
  saleReturnAdditionalCharges: [],
  loadingSaleReturn: false,
  loadingSpecificSaleDetails: false,
  loadingSaleReturnSupplier: false,
  loadingSaleReturnItem: false,
  loadingSelectedPackingType: false,
  loadingSaleReturnPaymentMode: false,
  // sale return print details
  viewSaleReturnPrintDetailsData: [],
  loadingPrintSaleReturn: false,
  loadingSaleReturnCustomerDetails: false,
  saleReturnPrintCount: [],
  // sale return view details
  loadingSaleReturnViewDetails: false,
  saleReturnRowData: null,
  saleReturnId: "",
  viewSaleReturnDetails: [],
  saleReturnDetailCount: 0,
  saleReturnDetailNext: 0,
  saleReturnDetailPrevious: 0,
  // retrieve details
  loadingSaleReturnDetails: false,
  // packing type
  packingTypes: [],
  saleReturnPaymentModes: [],
  count: 0,
  previous: "",
  next: "",
  // sale return id
  createdSaleReturnId: "",
  loadingSaleReturnCustomer: false,
  infoBarData: [],
  viewBillingSmallPrintDetailsData: [],
};
// sale slice
export const SaleReturn = createSlice({
  name: "saleReturnReducer",
  initialState,
  reducers: {
    loadingSaleReturn: (state) => {
      state.loadingSaleReturn = true;
    },
    getSaleReturnRequest: (state, payload) => {
      state.loadingSaleReturn = true;
    },
    getSaleReturnSuccess: (
      state,
      { payload: { results, count, next, previous, infoBarData } }
    ) => {
      state.loadingSaleReturn = false;
      state.saleReturns = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
      state.infoBarData = infoBarData;
    },
    getSaleReturnFail: (state) => {
      state.loadingSaleReturn = false;
    },
    getSaleReturnNextRequest: (state, payload) => {
      state.loadingSaleReturn = true;
    },
    getSaleReturnPreviousRequest: (state, payload) => {
      state.loadingSaleReturn = true;
    },
    //
    loadingSpecificSaleNo: (state) => {
      state.loadingSpecificSale = true;
    },
    getSpecificSaleRequest: (state, payload) => {
      state.loadingSpecificSale = true;
    },
    getSpecificSaleSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingSpecificSale = false;
      state.specificSales = results;
    },
    getSpecificSaleFail: (state) => {
      state.loadingSpecificSale = false;
      state.specificSales = [];
    },

    getSaleReturnPaymentModeRequest: (state) => {
      state.loadingSaleReturnPaymentMode = true;
    },
    // payment modes
    getSaleReturnPaymentModeSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingSaleReturnPaymentMode = false;
      state.saleReturnPaymentModes = results;
    },
    getSaleReturnPaymentModesFail: (state) => {
      state.loadingSpecificSale = false;
    },
    //additional charge
    getSaleReturnAdditionalChargeRequest: (state) => {
      state.loadingSaleReturnAdditionalCharges = true;
    },
    getSaleReturnAdditionalChargeSuccess: (state, { payload }) => {
      state.saleReturnAdditionalCharges = payload.results;
      state.loadingSaleReturnAdditionalCharges = false;
    },
    getSaleReturnAdditionalChargeFail: (state) => {
      state.loadingSaleReturnAdditionalCharges = false;
    },
    getSpecificSaleDetailsRequest: (state, payload) => {
      state.loadingSpecificSaleDetails = true;
    },
    getSpecificSaleDetailsSuccess: (state, { payload: { results } }) => {
      state.loadingSpecificSale = false;
      state.specificSaleDetails = results?.map((result: any) => ({
        ...result,
        netAmount: 0 * result?.cost,
        grossAmount: 0 * result?.cost,
        discountRate:
          Number(result.discountAmount * 100) /
          (result.cost * result.qty === 0 ? 1 : result.cost * result.qty),
        discountAmount: 0,
        returnQty: 0,
      }));
    },
    getSpecificSaleDetailsFail: (state) => {
      state.loadingSpecificSale = false;
    },
    createSaleReturnRequest: (state, payload) => {
      state.loadingCreateSaleReturn = true;
    },
    createSaleReturnSuccess: (state, { payload }) => {
      state.loadingCreateSaleReturn = false;
      state.createdSaleReturnId = payload?.response?.data?.id;
    },
    createSaleReturnFail: (state) => {
      state.loadingCreateSaleReturn = false;
    },
    updateSaleReturnSuccess: (state) => {
      state.loadingCreateSaleReturn = false;
    },
    updateSaleReturnFail: (state) => {
      state.loadingCreateSaleReturn = false;
    },
    updateSaleReturnDetailsSuccess: (state, { payload }) => {
      state.specificSaleDetails = payload;
    },
    loadingSaleReturnSupplierReq: (state) => {
      state.loadingSaleReturnSupplier = true;
    },
    // supplier reducers
    getSaleReturnCustomerRequest: (state, payload) => {
      state.loadingSaleReturnSupplier = true;
    },
    loadingSaleReturnCustomer: (state) => {
      state.loadingSaleReturnCustomer = true;
    },
    getSaleReturnCustomersSuccess: (state, { payload }) => {
      state.saleReturnCustomers = payload.results;
      state.loadingSaleReturnSupplier = false;
    },
    getSaleReturnCustomersFail: (state) => {
      state.loadingSaleReturnSupplier = false;
    },
    //  view sale return details
    getViewSaleReturnDetailsRequest: (state, { payload }) => {
      state.loadingSaleReturnViewDetails = true;
      state.saleReturnRowData = payload?.rowData;
      state.saleReturnId = payload?.id;
    },
    getViewSaleReturnDetailsSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingSaleReturnViewDetails = false;
      state.viewSaleReturnDetails = results;
      state.saleReturnDetailCount = count;
      state.saleReturnDetailNext = next;
      state.saleReturnDetailPrevious = previous;
    },
    getViewSaleReturnDetailsFail: (state) => {
      state.loadingSaleReturnViewDetails = false;
    },
    getViewSaleReturnDetailsNextRequest: (state, payload) => {
      state.loadingSaleReturnViewDetails = true;
    },
    getViewSaleReturnDetailsPreviousRequest: (state, payload) => {
      state.loadingSaleReturnViewDetails = true;
    },
    //get print sale details request
    getPrintSaleReturnDetailsRequest: (state, { payload }) => {
      // state.saleRowData = payload?.rowData;
      state.loadingPrintSaleReturn = true;
      state.saleReturnId = payload?.id;
    },
    getPrintSaleReturnDetailsSuccess: (state, { payload: { results } }) => {
      state.loadingPrintSaleReturn = false;
      state.viewSaleReturnDetails = results;
      state.viewSaleReturnPrintDetailsData = results?.map(
        (saleReturnDetail: specificSaleDetail, i: number) => [
          i + 1,
          saleReturnDetail.itemCode,
          saleReturnDetail.itemName,
          saleReturnDetail.netAmount,
          saleReturnDetail.qty,
          saleReturnDetail.discountAmount,
          // saleReturnDetail.discountRate,
          saleReturnDetail.grossAmount,
          saleReturnDetail.netAmount,
        ]
      );
      state.viewBillingSmallPrintDetailsData = results?.map(
        (saleReturnDetail: any, i: number) => [
          i + 1,
          `(${saleReturnDetail.itemCode})${saleReturnDetail.itemName}`,
          saleReturnDetail.cost,
          saleReturnDetail.qty,
          saleReturnDetail.netAmount,
        ]
      );
    },

    getPrintSaleReturnDetailsFail: (state) => {
      state.loadingPrintSaleReturn = false;
    },
    // get specific details
    getSaleReturnDetailsRequest: (state, payload) => {
      state.loadingSaleReturnDetails = true;
    },
    getSaleReturnDetailsSuccess: (state, { payload }) => {
      state.saleReturnRowData = payload;
      state.loadingSaleReturnDetails = false;
    },
    getSaleReturnDetailsFail: (state) => {
      state.loadingSaleReturnDetails = false;
    },
    // packing type
    getSelectedPackingTypeRequest: (state, payload) => {
      state.loadingSelectedPackingType = true;
    },
    getSelectedPackingTypesSuccess: (state, { payload }) => {
      state.packingTypes = payload;
      state.loadingSelectedPackingType = false;
    },
    getSelectedPackingTypesFail: (state) => {
      state.loadingSaleReturnItem = false;
    },
    // print count
    // post print count
    saleReturnPrintCountRequest: (state, payload) => {
      state.loadingPrintSaleReturn = true;
    },
    saleReturnPrintCountSuccess: (state, { payload: { results } }) => {
      state.loadingPrintSaleReturn = false;
      state.saleReturnPrintCount = results;
    },
    saleReturnPrintCountFail: (state) => {
      state.loadingPrintSaleReturn = false;
    },
    clearSpecificSaleDetails: (state) => {
      state.specificSaleDetails = [];
    },
    clearSaleReturnData: (state) => {
      state.loadingSaleReturn = false;
      state.specificSaleDetails = [];
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingSaleReturn,
  getSaleReturnSuccess,
  getSaleReturnFail,
  getSaleReturnRequest,
  clearSaleReturnData,
  createSaleReturnRequest,
  createSaleReturnFail,
  createSaleReturnSuccess,
  loadingSaleReturnSupplierReq,
  getSaleReturnCustomerRequest,
  getSaleReturnCustomersSuccess,
  getSaleReturnCustomersFail,
  updateSaleReturnDetailsSuccess,
  getSaleReturnNextRequest,
  getSaleReturnPreviousRequest,
  getSpecificSaleRequest,
  getSpecificSaleSuccess,
  getSpecificSaleFail,
  getSpecificSaleDetailsRequest,
  getSpecificSaleDetailsSuccess,
  getSpecificSaleDetailsFail,
  clearSpecificSaleDetails,
  getSelectedPackingTypesSuccess,
  getSelectedPackingTypeRequest,
  getSelectedPackingTypesFail,
  getSaleReturnPaymentModeRequest,
  getSaleReturnPaymentModeSuccess,
  getSaleReturnPaymentModesFail,
  // additional charge
  getSaleReturnAdditionalChargeRequest,
  getSaleReturnAdditionalChargeSuccess,
  getSaleReturnAdditionalChargeFail,
  // sale return view details
  getViewSaleReturnDetailsRequest,
  getViewSaleReturnDetailsSuccess,
  getViewSaleReturnDetailsFail,
  getViewSaleReturnDetailsNextRequest,
  getViewSaleReturnDetailsPreviousRequest,
  // print
  getPrintSaleReturnDetailsRequest,
  getPrintSaleReturnDetailsSuccess,
  getPrintSaleReturnDetailsFail,
  // print count request
  saleReturnPrintCountRequest,
  saleReturnPrintCountSuccess,
  saleReturnPrintCountFail,
  //retrieve data
  getSaleReturnDetailsSuccess,
  getSaleReturnDetailsRequest,
  getSaleReturnDetailsFail,
  loadingSaleReturnCustomer,
  //
  loadingSpecificSaleNo,
} = SaleReturn.actions;
export default SaleReturn.reducer;
