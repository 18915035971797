import { createSlice, createAction } from "@reduxjs/toolkit";
import { accountHead } from "./types";
const initialState: any = {
  accountHeads: [],
  loading: false,
  loadingCreateAccountHead: false,
  edit: false,
  accountHead: null,
  count: 0,
  previous: "",
  next: "",
  parents: [],
  loadingParent: false,
  treeViewData: [],
};

export const AccountHead = createSlice({
  name: "AccountHeadReducer",
  initialState,
  reducers: {
    loadingAccountHead: (state) => {
      state.loadingAccountHead = true;
    },
    getAccountHeadRequest: (state, payload) => {
      state.loadingAccountHead = true;
    },
    getAccountHeadSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingAccountHead = false;
      state.accountHeads = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getAccountHeadFail: (state) => {
      state.loadingAccountHead = false;
    },
    getAccountHeadNextRequest: (state, payload) => {
      state.loadingAccountHead = true;
    },
    getAccountHeadPreviousRequest: (state, payload) => {
      state.loadingAccountHead = true;
    },
    createAccountHeadRequest: (state, payload) => {
      state.loadingCreateAccountHead = true;
    },
    createAccountHeadSuccess: (state) => {
      state.loadingCreateAccountHead = false;
    },
    createAccountHeadFail: (state) => {
      state.loadingCreateAccountHead = false;
    },
    accountHeadEditSuccess: (state, { payload }) => {
      const selectedAccountHead = state?.accountHeads.find(
        (selected: any) => selected?.id === payload?.id
      );
      return {
        ...state,
        edit: true,
        accountHead: selectedAccountHead,
      };
    },
    updateAccountHeadRequest: (state, payload) => {
      state.loadingCreateAccountHead = true;
    },
    updateAccountHeadSuccess: (state) => {
      state.loadingCreateAccountHead = false;
    },
    updateAccountHeadFail: (state) => {
      state.loadingCreateAccountHead = false;
    },
    getParentRequest: (state, payload) => {
      state.loadingParent = true;
    },
    getParentSuccess: (state, { payload: { results } }) => {
      state.parents = results;
      state.loadingParent = false;
    },
    getParentFail: (state) => {
      state.loadingParent = false;
    },

    loadingTreeView: (state) => {
      state.loadingTreeView = true;
    },
    getTreeViewRequest: (state) => {
      state.loadingTreeView = true;
    },
    getTreeViewSuccess: (state, { payload: { results } }) => {
      state.treeViewData = results;
      state.loadingTreeView = false;
    },
    getTreeViewFail: (state) => {
      state.loadingTreeView = false;
    },
    clearAccountHeadData: (state) => {
      state.edit = false;
      state.accountHead = null;
    },
  },
});
// Action creators are generated for each case reducer function

const { actions, reducer } = AccountHead;

export const {
  loadingAccountHead,
  getAccountHeadRequest,
  getAccountHeadNextRequest,
  getAccountHeadPreviousRequest,
  getAccountHeadSuccess,
  getAccountHeadFail,
  createAccountHeadRequest,
  createAccountHeadSuccess,
  createAccountHeadFail,
  accountHeadEditSuccess,
  updateAccountHeadRequest,
  updateAccountHeadSuccess,
  updateAccountHeadFail,
  getParentRequest,
  getParentSuccess,
  getParentFail,
  getTreeViewRequest,
  loadingTreeView,
  getTreeViewSuccess,
  getTreeViewFail,
  clearAccountHeadData,
} = actions;
export default reducer;
