import {
  getMaterializedReportInventorySupplierRequest,
  getMaterializedReportInventoryItemRequest,
  getMaterializedReportInventoryUserRequest,
  getMaterializedReportInventoryAllSummaryRequest,
  getMaterializedReportInventoryAllDetailRequest,
  getMaterializedReportInventoryQuickSummaryRequest,
  getMaterializedReportInventoryQuickDetailRequest,
  //reducer get data
  getMaterializedReportInventoryItemSuccess,
  getMaterializedReportInventoryItemFail,
  getMaterializedReportInventorySupplierSuccess,
  getMaterializedReportInventorySupplierFail,
  getMaterializedReportInventoryUserSuccess,
  getMaterializedReportInventoryUserFail,
  getMaterializedReportInventoryQuickDetailSuccess,
  getMaterializedReportInventoryQuickDetailFail,
  getMaterializedReportInventoryAllDetailSuccess,
  getMaterializedReportInventoryAllDetailFail,
  getMaterializedReportInventoryQuickSummarySuccess,
  getMaterializedReportInventoryQuickSummaryFail,
  getMaterializedReportInventoryAllSummarySuccess,
  getMaterializedReportInventoryAllSummaryFail,
  getMaterializedReportInventoryDetailSuccess,
  getMaterializedReportInventoryDetailFail,
  loadingMaterializedReportInventoryDetail,
  getMaterializedReportInventoryPreviousRequest,
  getMaterializedReportInventoryNextRequest,
  getMaterializedReportInventoryDetailRequest,
} from "./materializedReportInventorySlice";
import { mergeMap } from "rxjs";
import * as API from "./api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../../AppUtils/Utils/validationConstants";
import { ContactsOutlined } from "@mui/icons-material";
export const controller = new AbortController();
const getMaterializedReportInventorySupplierEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getMaterializedReportInventorySupplierRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getMaterializedReportInventorySupplier();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getMaterializedReportInventorySupplierSuccess(action?.payload)
        : getMaterializedReportInventorySupplierFail()
    )
  );
//user
const getMaterializedReportInventoryUserEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getMaterializedReportInventoryUserRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getMaterializedReportInventoryUser();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getMaterializedReportInventoryUserSuccess(action?.payload)
        : getMaterializedReportInventoryUserFail()
    )
  );
//item
const getMaterializedReportInventoryItemEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getMaterializedReportInventoryItemRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getMaterializedReportInventoryItem();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getMaterializedReportInventoryItemSuccess(action?.payload)
        : getMaterializedReportInventoryItemFail()
    )
  );

//get all purchase summary detail
const getMaterializedReportInventoryAllSummaryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getMaterializedReportInventoryAllSummaryRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getMaterializedReportInventoryAllReport(
          action?.payload
        );
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getMaterializedReportInventoryAllSummarySuccess(action?.payload)
        : getMaterializedReportInventoryAllSummaryFail()
    )
  );
//quick summary
const getMaterializedReportInventoryQuickSummaryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getMaterializedReportInventoryQuickSummaryRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getMaterializedReportInventoryQuickReport(
          action.payload
        );
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getMaterializedReportInventoryQuickSummarySuccess(action?.payload)
        : getMaterializedReportInventoryQuickSummaryFail()
    )
  );

//get all purchase summary detail
const getMaterializedReportInventoryAllDetailEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getMaterializedReportInventoryAllDetailRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getMaterializedReportInventoryAllReport(
          action?.payload
        );
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getMaterializedReportInventoryAllDetailSuccess(action?.payload)
        : getMaterializedReportInventoryAllDetailFail()
    )
  );
//quick Detail
const getMaterializedReportInventoryQuickDetailEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getMaterializedReportInventoryQuickDetailRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getMaterializedReportInventoryQuickReport(
          action.payload
        );
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getMaterializedReportInventoryQuickDetailSuccess(action?.payload)
        : getMaterializedReportInventoryQuickDetailFail()
    )
  );

//next and previous
//get next
const getMaterializedReportInventoryNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getMaterializedReportInventoryNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingMaterializedReportInventoryDetail());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getMaterializedReportInventoryDetailSuccess(action?.payload)
        : getMaterializedReportInventoryDetailFail()
    )
  );
//get previous
const getMaterializedReportInventoryPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getMaterializedReportInventoryPreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingMaterializedReportInventoryDetail());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getMaterializedReportInventoryDetailSuccess(action?.payload)
        : getMaterializedReportInventoryDetailFail()
    )
  );
//het purchase report details
const getMaterializedReportInventoryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getMaterializedReportInventoryDetailRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getMaterializedReportInventoryQuickReport(
          action.payload
        );
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getMaterializedReportInventoryDetailSuccess(action?.payload)
        : getMaterializedReportInventoryDetailFail()
    )
  );
export const materializedReportInventoryEpics = combineEpics(
  getMaterializedReportInventorySupplierEpic,
  getMaterializedReportInventoryItemEpic,
  getMaterializedReportInventoryUserEpic,
  getMaterializedReportInventoryAllSummaryEpic,
  getMaterializedReportInventoryQuickSummaryEpic,
  getMaterializedReportInventoryQuickDetailEpic,
  getMaterializedReportInventoryAllDetailEpic,
  getMaterializedReportInventoryPrevious,
  getMaterializedReportInventoryNext,
  getMaterializedReportInventoryEpic
);
