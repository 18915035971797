import { axiosInstance } from "../../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../../AppUtils/Utils/globalTypes";
import { StockAnalysisReportInventory } from "./types";

const StockAnalysisReportInventoryModURL =
  "api/v1/inventory-stock-app/stock-analysis";

export const getStockAnalysisReportInventorySupplier = () =>
  axiosInstance.get(
    `${StockAnalysisReportInventoryModURL}/supplier?offset=0&limit=${0}`
  );
export const getStockAnalysisReportInventoryUser = () =>
  axiosInstance.get(
    `${StockAnalysisReportInventoryModURL}/user?offset=0&limit=${0}`
  );
export const getStockAnalysisReportInventoryItem = () =>
  axiosInstance.get(
    `${StockAnalysisReportInventoryModURL}/item?offset=0&limit=${0}`
  );
export const getStockAnalysisReportInventoryAllReport = (data: any) => {
  const { updatedValue } = data;

  const {
    summary,
    detail,

    startDateAd,
    endDateAd,
  } = updatedValue;
  return axiosInstance.get(
    `${StockAnalysisReportInventoryModURL}?offset=0&limit=${0}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }&date_after=${startDateAd}&date_before=${endDateAd}`
  );
};
export const getStockAnalysisReportInventoryQuickReport = (data: any) => {
  const { rowsPerPage, page, updatedValue } = data;
  const {
    summary,
    detail,

    startDateAd,
    endDateAd,
  } = updatedValue;
  return axiosInstance.get(
    `${StockAnalysisReportInventoryModURL}?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }&date_after=${startDateAd}&date_before=${endDateAd}`
  );
};

export const searchStockAnalysisReportInventory = ({
  rowsPerPage,
  search,
  updatedValue,
}: any) => {
  const {
    item,
    summary,
    detail,
    supplier,
    user,
    purchaseType,
    startDateAd,
    endDateAd,
  } = updatedValue;

  return `${StockAnalysisReportInventoryModURL}?offset=0&limit=${rowsPerPage}&search=${search}&date_after=${startDateAd}&date_before=${endDateAd}`;
};
