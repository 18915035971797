import {
  getPurchaseReportSupplierRequest,
  getPurchaseReportItemRequest,
  getPurchaseReportUserRequest,
  getPurchaseReportAllSummaryRequest,
  getPurchaseReportAllDetailRequest,
  getPurchaseReportQuickSummaryRequest,
  getPurchaseReportQuickDetailRequest,
  //reducer get data
  getPurchaseReportItemSuccess,
  getPurchaseReportItemFail,
  getPurchaseReportSupplierSuccess,
  getPurchaseReportSupplierFail,
  getPurchaseReportUserSuccess,
  getPurchaseReportUserFail,
  getPurchaseReportQuickDetailSuccess,
  getPurchaseReportQuickDetailFail,
  getPurchaseReportAllDetailSuccess,
  getPurchaseReportAllDetailFail,
  getPurchaseReportQuickSummarySuccess,
  getPurchaseReportQuickSummaryFail,
  getPurchaseReportAllSummarySuccess,
  getPurchaseReportAllSummaryFail,
  getPurchaseReportDetailSuccess,
  getPurchaseReportDetailFail,
  loadingPurchaseReportDetail,
  getPurchaseReportPreviousRequest,
  getPurchaseReportNextRequest,
  getPurchaseReportDetailRequest,
} from "./purchaseReportSlice";
import { mergeMap } from "rxjs";
import * as API from "./api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../../AppUtils/Utils/validationConstants";
import { ContactsOutlined } from "@mui/icons-material";
export const controller = new AbortController();
const getPurchaseReportSupplierEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPurchaseReportSupplierRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getPurchaseReportSupplier();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPurchaseReportSupplierSuccess(action?.payload)
        : getPurchaseReportSupplierFail()
    )
  );
//user
const getPurchaseReportUserEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPurchaseReportUserRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getPurchaseReportUser();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPurchaseReportUserSuccess(action?.payload)
        : getPurchaseReportUserFail()
    )
  );
//item
const getPurchaseReportItemEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPurchaseReportItemRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getPurchaseReportItem();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPurchaseReportItemSuccess(action?.payload)
        : getPurchaseReportItemFail()
    )
  );

//get all purchase summary detail
const getPurchaseReportAllSummaryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPurchaseReportAllSummaryRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getPurchaseReportAllReport(action?.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPurchaseReportAllSummarySuccess(action?.payload)
        : getPurchaseReportAllSummaryFail()
    )
  );
//quick summary
const getPurchaseReportQuickSummaryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPurchaseReportQuickSummaryRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getPurchaseReportQuickReport(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPurchaseReportQuickSummarySuccess(action?.payload)
        : getPurchaseReportQuickSummaryFail()
    )
  );

//get all purchase summary detail
const getPurchaseReportAllDetailEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPurchaseReportAllDetailRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getPurchaseReportAllReport(action?.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPurchaseReportAllDetailSuccess(action?.payload)
        : getPurchaseReportAllDetailFail()
    )
  );
//quick Detail
const getPurchaseReportQuickDetailEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPurchaseReportQuickDetailRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getPurchaseReportQuickReport(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPurchaseReportQuickDetailSuccess(action?.payload)
        : getPurchaseReportQuickDetailFail()
    )
  );

//next and previous
//get next
const getPurchaseReportNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPurchaseReportNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingPurchaseReportDetail());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPurchaseReportDetailSuccess(action?.payload)
        : getPurchaseReportDetailFail()
    )
  );
//get previous
const getPurchaseReportPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPurchaseReportPreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingPurchaseReportDetail());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPurchaseReportDetailSuccess(action?.payload)
        : getPurchaseReportDetailFail()
    )
  );
//het purchase report details
const getPurchaseReportEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPurchaseReportDetailRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getPurchaseReportQuickReport(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPurchaseReportDetailSuccess(action?.payload)
        : getPurchaseReportDetailFail()
    )
  );
export const purchaseReportEpics = combineEpics(
  getPurchaseReportSupplierEpic,
  getPurchaseReportItemEpic,
  getPurchaseReportUserEpic,
  getPurchaseReportAllSummaryEpic,
  getPurchaseReportQuickSummaryEpic,
  getPurchaseReportQuickDetailEpic,
  getPurchaseReportAllDetailEpic,
  getPurchaseReportPrevious,
  getPurchaseReportNext,
  getPurchaseReportEpic
);
