import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

export const userURL = "api/v1/user-app/user";
export const ProfileUrl = "api/v1/user-app/profile";

//get user data api
export const getUser = (data: getApiProps) => {
  const { rowsPerPage, page, search } = data;
  return axiosInstance.get(
    `${userURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}&search=${
      search ?? ""
    }${data?.filterData ? filterApiData(data?.filterData) : ""}`
  );
};
export const getUserGroup = () =>
  axiosInstance.get(`${userURL}/user-group?offset=0&limit=${0}`);

//handle search
export const searchUser = ({ rowsPerPage, search }: searchProps) => {
  return `${userURL}?offset=0&limit=${rowsPerPage}&search=${search ?? ""}`;
};
//create user
export const createUser = (body: string | FormData) =>
  axiosInstance.post(`${userURL}`, body);
//update user
export const updateUser = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${userURL}/${id}`, body);
//get login user
export const LoginUser = () => axiosInstance.get(`${ProfileUrl}`);
//
//patch prfrofile user
export const updateProfileUser = (body: string | FormData) =>
  axiosInstance.patch(`${ProfileUrl}`, body);
export const getUserById = (id: number) => {
  return axiosInstance.get(`${userURL}/${id}`);
};
