import { createAction, createSlice } from "@reduxjs/toolkit";
import { branch } from "./types";
import setCookie from "../../../AppUtils/Utils/Cookies/setCookie";
import deleteCookie from "../../../AppUtils/Utils/Cookies/deleteCookie";
import { logout } from "./api";
export interface AuthState {
  isAuthenticated: boolean;
  loadingAuth: boolean;
  branches: branch[];
  userName: string;
  userId: number | null;
  authError: boolean;
  isSuperuser: boolean;
  img: string | null;
  open: boolean;
  message: string;
  permissions: any;
  fullName: string;
}
const initialState: AuthState = {
  isAuthenticated: false,
  loadingAuth: false,
  branches: [],
  permissions: [],
  userName: "",
  userId: null,
  authError: false,
  isSuperuser: false,
  img: null,
  open: false,
  message: "",
  fullName: "",
};

export const loginRequest = createAction(
  "loginRequest",
  function prepare(body) {
    return {
      payload: body,
    };
  }
);
export const getBranchRequest = createAction(
  "getBranchRequest",
  function prepare() {
    return {
      payload: {},
    };
  }
);

// export const getLoginRequestFail = createAction("loginRequestFail");
const Auth = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    loadingLogin: (state) => {
      state.loadingAuth = true;
    },
    loginSuccess: (state, action) => {
      setCookie("accessToken", action.payload.tokens.access, {
        //if https secure url header then set secure true
        // secure: true,
        // httpOnly: true,
        "max-age": 360000,
        sameSite: "Lax",
      });
      setCookie("refreshToken", action.payload.tokens.refresh, {
        // secure: true,
        // httpOnly: true,
        "max-age": 3600000,
        sameSite: "Lax",
      });
      setCookie("isAuthenticated", true, {
        // secure: true,
        //  httpOnly: false,
        "max-age": 3600000,
        sameSite: "Lax",
      });
      state.loadingAuth = false;
      state.userId = action?.payload?.id;
      state.isAuthenticated = true;
      state.userName = action.payload.userName;
      state.fullName =
        action.payload.firstName +
        " " +
        action.payload.middleName +
        " " +
        action.payload.lastName;
      state.isSuperuser = action?.payload?.isSuperuser;
      state.authError = false;
      state.permissions = action?.payload?.groups?.flatMap(
        (permission: any) => permission.permissions
      );
      state.img = action.payload.photo;
    },
    loginFail: (state) => {
      deleteCookie("refreshToken");
      deleteCookie("accessToken");
      deleteCookie("isAuthenticated");
      state.loadingAuth = false;
      state.userId = null;
      state.isAuthenticated = false;
      state.userName = "";
      state.isSuperuser = false;
      state.authError = true;
      state.permissions = [];
      state.img = null;
    },
    clearAuth: (state) => {
      state.loadingAuth = false;
    },
    getBranchesSuccess: (state, { payload }) => {
      state.branches = payload;
    },
    getBranchesFail: (state) => {},
  },
});

export const {
  clearAuth,
  loginSuccess,
  loadingLogin,
  loginFail,
  getBranchesSuccess,
  getBranchesFail,
} = Auth.actions;
export default Auth.reducer;
