export const defaultRowsPerPage = 10;
export const defaultPage = 0;
export const globalSearchPostPerPage = 35;
export const dataPerRequest = 10;
export const prefixOfCoreSetUp = "core-setup";
export const paperType = ["A5", "A4"];
// billing header
export const billingHeader = [
  "SN",
  "Code",
  "Test",
  "Cost",
  "Qty",
  "Dis(A)",
  // "Tax(A)",
  "Gross(A)",
  "Net(A)",
];
// sale header
export const saleHeader = [
  "SN",
  "Code",
  "Item",
  "Cost",
  "Qty",
  "Dis(A)",
  // "Tax(A)",
  "Gross(A)",
  "Net(A)",
];

// supplier columns
export const columnForSupplierDetail = [
  { id: "supplierFullName", label: "Patient" },
  { id: "purchaseNoFull", label: "Purchase No." },
  { id: "billNo", label: "Bill No" },
  { id: "createdDateAd", label: "Date AD" },
  { id: "createdDateBs", label: "Date BS" },
  { id: "billDateBs", label: "Bill Date BS" },
  { id: "dueDateBs", label: "Due Date BS" },
  { id: "payTypeDisplay", label: "Pay Type" },
];
// columns for purchase details
export const columnForPurchaseDetails = [
  { id: "itemName", label: "Item" },
  { id: "expiryDateAd", label: "Expiry Date Ad" },
  { id: "batchNo", label: "Batch No" },
  { id: "purchaseCost", label: "Rate/CC" },
  { id: "saleCost", label: "MRP" },
  { id: "qty", label: "Qty" },
  { id: "discountRate", label: "Dis(%)" },
  { id: "discountAmount", label: "Dis(A)" },
  { id: "taxRate", label: "Tax(%)" },
  { id: "taxAmount", label: "Tax(A)" },
  { id: "grossAmount", label: "Gross(A)" },
  { id: "netAmount", label: "Net(A)" },
  { id: "freePurchase", label: "Free", boolean: true },
];
// columns for sale details
export const columnForSaleDetails = [
  { id: "itemName", label: "Item" },
  { id: "packingTypeName", label: "Packing Type" },
  { id: "cost", label: "Rate/CC" },
  { id: "qty", label: "Qty" },
  { id: "discountAmount", label: "Dis(A)" },
  { id: "taxAmount", label: "Tax(A)" },
  { id: "grossAmount", label: "Gross(A)" },
  { id: "netAmount", label: "Net(A)" },
];
// columns for customer details
export const columnForCustomerDetail = [
  { id: "customerFullName", label: "Patient" },
  { id: "saleNo", label: "Invoice No." },
  { id: "createdDateAd", label: "Date AD" },
  { id: "createdDateBs", label: "Date BS" },
  { id: "time", label: "Time" },
  { id: "payTypeDisplay", label: "Pay Type" },
  { id: "remarks", label: "Remarks" },
];

export const prefixes: string[] = [
  "export_pdf",
  "export_excel",
  "add",
  "view",
  "edit",
  "print",
  "verify",
  "unapprove",
  "email",
  "send",
];
