import { createSlice, createAction } from "@reduxjs/toolkit";
import { StockAnalysisReportInventoryInitialState } from "./types";
const initialState: StockAnalysisReportInventoryInitialState = {
  purchaseDetails: [],
  //loading
  loadingGenerateReport: false,
  loadingStockAnalysisReportInventorySupplier: false,
  loadingStockAnalysisReportInventoryItem: false,
  loadingStockAnalysisReportInventoryUser: false,
  loadingStockAnalysisReportInventoryDetail: false,
  count: 0,
  previous: "",
  next: "",
};

export const StockAnalysisReportInventory = createSlice({
  name: "stockAnalysisReportInventoryReducer",
  initialState,
  reducers: {
    //loading
    loadingStockAnalysisReportInventoryDetail: (state) => {
      state.loadingStockAnalysisReportInventoryDetail = true;
    },
    //external reducer request
    getStockAnalysisReportInventoryDetailRequest: (state, { payload }) => {
      state.loadingStockAnalysisReportInventoryDetail = true;
    },
    getStockAnalysisReportInventorySupplierRequest: (state) => {
      state.loadingStockAnalysisReportInventorySupplier = true;
    },
    getStockAnalysisReportInventoryItemRequest: (state) => {
      state.loadingStockAnalysisReportInventoryItem = true;
    },
    getStockAnalysisReportInventoryUserRequest: (state) => {
      state.loadingStockAnalysisReportInventoryUser = true;
    },
    getStockAnalysisReportInventoryAllSummaryRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getStockAnalysisReportInventoryAllDetailRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getStockAnalysisReportInventoryQuickSummaryRequest: (
      state,
      { payload }
    ) => {
      state.loadingGenerateReport = true;
    },
    getStockAnalysisReportInventoryQuickDetailRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getStockAnalysisReportInventoryNextRequest: (state, payload) => {
      state.loadingStockAnalysisReportInventoryDetail = true;
    },
    getStockAnalysisReportInventoryPreviousRequest: (state, payload) => {
      state.loadingStockAnalysisReportInventoryDetail = true;
    },
    //external get reducer
    getStockAnalysisReportInventoryDetailSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.purchaseDetails = results;
      state.loadingStockAnalysisReportInventoryDetail = false;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getStockAnalysisReportInventoryDetailFail: (state) => {
      state.loadingStockAnalysisReportInventoryDetail = false;
    },
    getStockAnalysisReportInventoryUserSuccess: (
      state,
      { payload: { results } }
    ) => {
      state.loadingStockAnalysisReportInventoryUser = false;
      // state.users = results;
    },
    getStockAnalysisReportInventoryUserFail: (state) => {
      state.loadingStockAnalysisReportInventoryUser = false;
    },
    getStockAnalysisReportInventorySupplierSuccess: (
      state,
      { payload: { results } }
    ) => {
      state.loadingStockAnalysisReportInventorySupplier = false;
      // state.suppliers = results;
    },
    getStockAnalysisReportInventorySupplierFail: (state) => {
      state.loadingStockAnalysisReportInventorySupplier = false;
    },
    getStockAnalysisReportInventoryItemSuccess: (
      state,
      { payload: { results } }
    ) => {
      state.loadingStockAnalysisReportInventoryItem = false;
      // state.items = results;
    },
    getStockAnalysisReportInventoryItemFail: (state) => {
      state.loadingStockAnalysisReportInventoryItem = false;
    },
    getStockAnalysisReportInventoryAllSummarySuccess: (
      state,
      { payload: { results } }
    ) => {
      state.loadingGenerateReport = false;
      state.purchaseDetails = results;
    },
    getStockAnalysisReportInventoryAllSummaryFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getStockAnalysisReportInventoryQuickSummarySuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingGenerateReport = false;
      state.purchaseDetails = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getStockAnalysisReportInventoryQuickSummaryFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getStockAnalysisReportInventoryAllDetailSuccess: (
      state,
      { payload: { results } }
    ) => {
      state.loadingGenerateReport = false;
      state.purchaseDetails = results;
    },
    getStockAnalysisReportInventoryAllDetailFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getStockAnalysisReportInventoryQuickDetailSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingGenerateReport = false;
      state.purchaseDetails = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getStockAnalysisReportInventoryQuickDetailFail: (state) => {
      state.loadingGenerateReport = false;
    },

    clearStockAnalysisReportInventoryData: (state) => {
      state.purchaseDetails = [];
      // state.users = [];
      // state.items = [];
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  //reducerReport
  getStockAnalysisReportInventoryDetailRequest,
  getStockAnalysisReportInventorySupplierRequest,
  getStockAnalysisReportInventoryItemRequest,
  getStockAnalysisReportInventoryUserRequest,
  getStockAnalysisReportInventoryAllSummaryRequest,
  getStockAnalysisReportInventoryAllDetailRequest,
  getStockAnalysisReportInventoryQuickSummaryRequest,
  getStockAnalysisReportInventoryQuickDetailRequest,
  getStockAnalysisReportInventoryPreviousRequest,
  getStockAnalysisReportInventoryNextRequest,
  //reducer get data
  getStockAnalysisReportInventoryDetailSuccess,
  getStockAnalysisReportInventoryDetailFail,
  getStockAnalysisReportInventoryItemSuccess,
  getStockAnalysisReportInventoryItemFail,
  getStockAnalysisReportInventorySupplierSuccess,
  getStockAnalysisReportInventorySupplierFail,
  getStockAnalysisReportInventoryUserSuccess,
  getStockAnalysisReportInventoryUserFail,
  getStockAnalysisReportInventoryQuickDetailSuccess,
  getStockAnalysisReportInventoryQuickDetailFail,
  getStockAnalysisReportInventoryAllDetailSuccess,
  getStockAnalysisReportInventoryAllDetailFail,
  getStockAnalysisReportInventoryQuickSummarySuccess,
  getStockAnalysisReportInventoryQuickSummaryFail,
  getStockAnalysisReportInventoryAllSummarySuccess,
  getStockAnalysisReportInventoryAllSummaryFail,
  //loading
  loadingStockAnalysisReportInventoryDetail,
  //clear
  clearStockAnalysisReportInventoryData,
} = StockAnalysisReportInventory.actions;
export default StockAnalysisReportInventory.reducer;
