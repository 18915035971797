import { Fragment, memo, useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import SearchIcon from "@mui/icons-material/Search";
import List from "@mui/material/List";
import Sidebar from "./";
import logo from "../../../Assets/logo.png";

import "./Sidebar.css";
import {
  Drawer,
  Search,
  SearchIconWrapper,
  StyledInputBase,
  useStyles,
} from "./SidebarStyles";
import { commonAppSelector } from "../../../Pages/CommonAppRedux/selector";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../AppUtils/Utils/appHooks";
import { loginSelector } from "../../../Pages/Login/Redux/selector";
import {
  getGlobalOrganizationRequest,
  getGlobalOrganizationRuleRequest,
  setOrganizationLogo,
} from "../../../Pages/CommonAppRedux/CommonAppSlice";

const SidebarMenu = ({ open, dataToBeLoad, setOpen }: any) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { permissions, isSuperuser } = useAppSelector(loginSelector);
  const {
    hideSidebarInitially,
    globalOrganizations,
    globalOrganizationRules: rule,
  } = useAppSelector(commonAppSelector);

  // Function to convert image to base64

  // Function to convert image to base64
  function convertImageToBase64(url: string | any): Promise<string> {
    return fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        return new Promise<string>((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64Data = reader.result as string;
            resolve(base64Data);
          };
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      });
  }

  useEffect(() => {
    if (globalOrganizations?.logo) {
      convertImageToBase64(globalOrganizations.logo)
        .then((base64Data) => {
          dispatch(setOrganizationLogo(base64Data ?? globalOrganizations.logo)); // Dispatch the Redux action to store the base64 data
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    }
  }, [globalOrganizations?.logo]);
  // let organizations: any = [];
  const [search, setSearch] = useState("");
  useEffect(() => {
    dispatch(getGlobalOrganizationRequest());
    dispatch(getGlobalOrganizationRuleRequest());
    if (hideSidebarInitially) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [hideSidebarInitially]);

  useEffect(() => {
    setOpen(!rule?.collapseSideBar);
  }, []);
  useEffect(() => {
    return () => {
      setSearch("");
    };
  }, [open]);

  return (
    <Drawer variant="permanent" open={open} className="sidebarDrawer">
      <div className={classes.toolbarIcon}>
        <div className="sidebar_logo">
          <img
            src={
              globalOrganizations?.logo
                ? String(globalOrganizations?.logo)
                : logo
            }
            alt=""
            className={open ? "fullLogo" : "shrinkLogo"}
          />
          {open && (
            <Typography variant="h6">
              {globalOrganizations?.nameDisplay
                ? globalOrganizations?.nameDisplay
                : "CIMS"}
            </Typography>
          )}
        </div>
        <Divider />

        <div>
          {open && (
            <div className={classes.searchSidebar}>
              <Search className="search-wrapper-sidebar">
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
                <SearchIconWrapper className="sidebar-search-icon-wrapper">
                  <SearchIcon style={{ color: "#f2f2f2" }} />
                </SearchIconWrapper>
              </Search>
            </div>
          )}
        </div>
        <Divider />
      </div>

      <div className="sidebarListWrapper">
        <List component="nav" className={classes.sidebarList} disablePadding>
          {dataToBeLoad?.map((item: any, index: number) => {
            let menu = item?.items
              ?.reduce((acc: any, arr: any) => acc?.concat(arr), [])
              ?.reduce(
                (acc: any, arr: any) => acc?.concat(arr?.permission),
                []
              );
            let displayMenu = permissions?.some(
              (element: any) => menu?.indexOf(element.codeName) !== -1
            );
            return isSuperuser || displayMenu ? (
              <Fragment key={index}>
                <Sidebar
                  {...item}
                  key={index}
                  search={search}
                  setSearch={setSearch}
                  className={classes.ListItemhover}
                />
              </Fragment>
            ) : (
              <Fragment key={index}></Fragment>
            );
          })}
        </List>
      </div>

      <Divider />
    </Drawer>
  );
};

export default memo(SidebarMenu);
