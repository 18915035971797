import React, { FC } from "react";
import { Redirect, Route, useHistory } from "react-router";
import { useAppSelector } from "../AppUtils/Utils/appHooks";
import Unauthorized from "../Component/ErrorPages/Unauthorized";
import { loginSelector } from "../Pages/Login/Redux/selector";
import { ProtectedRouteProps } from "./types";
const ProtectedRoute: FC<ProtectedRouteProps> = ({
  component: Component,
  permission,
  path,
  ...rest
}) => {
  let history = useHistory();

  const { permissions, isSuperuser } = useAppSelector(loginSelector);
  // const isSuperuser = useAppSelector((state) => state.auth.isSuperuser);
  const activeSideBar = localStorage.getItem("activeSidebar");
  //
  //   permissions?.some(({ codeName }: any) =>
  //     permission?.map((permission: string) => permission === codeName)
  //   ),
  //   "permisison in routes"
  // );
  return (
    <>
      {/* check if entered url lies under active side bar data */}
      {
        // history?.location?.pathname.indexOf(activeSideBar!) !== -1 ||
        // history?.location?.pathname === "/profile" ?
        isSuperuser ||
        permissions?.some(
          ({ codeName }: any) => permission?.includes(codeName)
        ) ||
        permission[0]?.mainModule ? (
          <Route
            {...rest}
            render={(props) => {
              return <Component {...props} />;
            }}
          />
        ) : (
          <Unauthorized />
        )
      }
    </>
  );
};
export default React.memo(ProtectedRoute);
