import { createSlice } from "@reduxjs/toolkit";
import { customerInitialState } from "./types";
const initialState: customerInitialState = {
  customers: [],
  loadingCustomer: false,
  loadingCreateCustomer: false,
  createdCustomerId: "",
  edit: false,
  customer: null,
  titles: [],
  loadingTitle: false,
  customerTypes: [],
  loadingCustomerType: false,
  count: 0,
  previous: "",
  next: "",
  loadingImportCSV: false,
};

export const Customer = createSlice({
  name: "Customer Reducer",
  initialState,
  reducers: {
    getCustomerRequest: (state, payload) => {
      state.loadingCustomer = true;
    },
    getCustomerSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingCustomer = false;
      state.customers = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getCustomerFail: (state) => {
      state.loadingCustomer = false;
    },
    getCustomerNextRequest: (state, payload) => {
      state.loadingCustomer = true;
    },
    getCustomerPreviousRequest: (state, payload) => {
      state.loadingCustomer = true;
    },
    createCustomerRequest: (state, payload) => {
      state.loadingCreateCustomer = true;
    },
    createCustomerSuccess: (state, { payload }) => {
      state.loadingCreateCustomer = false;
      //@ts-ignore
      state.createdCustomerId = payload?.response?.data?.id;
    },
    createCustomerFail: (state) => {
      state.loadingCreateCustomer = false;
    },
    customerEditSuccess: (state, { payload }) => {
      state.edit = true;
      state.customer = payload;
    },
    updateCustomerRequest: (state, payload) => {
      state.loadingCreateCustomer = true;
    },
    updateCustomerSuccess: (state) => {
      state.loadingCreateCustomer = false;
    },
    updateCustomerFail: (state) => {
      state.loadingCreateCustomer = false;
    },
    clearCustomerData: (state) => {
      state.edit = false;
      state.customer = null;
    },
    //title reducers
    loadingCustomerTitleReq: (state) => {
      state.loadingTitle = true;
    },
    getCustomerTitleRequest: (state, payload) => {
      state.loadingTitle = true;
    },
    getCustomerTitleSuccess: (state, { payload: { results } }) => {
      state.loadingTitle = false;
      state.titles = results;
    },
    getCustomerTitleFail: (state) => {
      state.loadingTitle = false;
    },
    //customerType reducers
    loadingCustomerCustomerTypeReq: (state) => {
      state.loadingCustomerType = true;
    },
    getCustomerCustomerTypeRequest: (state, payload) => {
      state.loadingCustomerType = true;
    },
    getCustomerCustomerTypeSuccess: (state, { payload: { results } }) => {
      state.loadingCustomerType = false;
      state.customerTypes = results;
    },
    getCustomerCustomerTypeFail: (state) => {
      state.loadingCustomerType = false;
    },

    importCSVFileCustomerRequest: (state, payload) => {
      state.loadingImportCSV = true;
    },
    importCSVFileCustomerSuccess: (state) => {
      state.loadingImportCSV = false;
    },
    importCSVFileCustomerFail: (state) => {
      state.loadingImportCSV = false;
    },
  },
});

// Action creators generated for each case reducer function
export const {
  //get customer
  getCustomerRequest,
  getCustomerSuccess,
  getCustomerFail,
  //get customer next, previous
  getCustomerNextRequest,
  getCustomerPreviousRequest,
  //create customer
  createCustomerRequest,
  createCustomerSuccess,
  createCustomerFail,
  //edit customer
  customerEditSuccess,
  //update customer
  updateCustomerRequest,
  updateCustomerSuccess,
  updateCustomerFail,
  //clear customer data
  clearCustomerData,

  // title actions
  loadingCustomerTitleReq,
  getCustomerTitleRequest,
  getCustomerTitleSuccess,
  getCustomerTitleFail,
  //customerType actions
  loadingCustomerCustomerTypeReq,
  getCustomerCustomerTypeRequest,
  getCustomerCustomerTypeSuccess,
  getCustomerCustomerTypeFail,
  // import CSV actions
  importCSVFileCustomerRequest,
  importCSVFileCustomerSuccess,
  importCSVFileCustomerFail,
} = Customer.actions;
export default Customer.reducer;
