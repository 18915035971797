import {
  createUserRoleFail,
  createUserRoleRequest,
  createUserRoleSuccess,
  getUserRoleSuccess,
  getUserRoleFail,
  loadingUserRole,
  updateUserRoleFail,
  updateUserRoleSuccess,
  getUserRoleRequest,
  getAllUserRolePermissionsRequest,
  getSpecificUserPermissionCategoryRequest,
  getSpecificUserPermissionsRequest,
  getUserPermissionSuccess,
  getUserPermissionFail,
  getUserPermissionCategorySuccess,
  getUserPermissionCategoryFail,
  getUserRoleNextRequest,
  getUserRolePreviousRequest,
  updateUserRoleRequest,
  clearUserRoleData,
  getUserGroupSystemModleRequest,
  getUserGroupSystemModuleSuccess,
  getUserGroupSystemModuleFail,
  userRoleEditSuccess,
  getUserRoleByIdRequest,
  userRoleEditFail,
} from "./userRoleSlice";
import { mergeMap } from "rxjs";
import {
  createUserRole,
  getUserRole,
  updateUserRole,
  getAllUserPermissions,
  getSpecificPermissions,
  getSpecificUserPermissionCategory,
  getUserRoleSystemModule,
  getUserRoleById,
} from "./api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";

export const controller = new AbortController();

// get User Role epic
const getUserRoleEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getUserRoleRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingUserRole());
      try {
        const response = await getUserRole(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getUserRoleSuccess(action?.payload) : getUserRoleFail()
    )
  );

//get user permission
const getAllUserRolePermissionEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAllUserRolePermissionsRequest.match),
    mergeMap(async (action) => {
      // dispatch(loadingUserRole());
      try {
        const response = await getAllUserPermissions(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getUserPermissionSuccess(action?.payload)
        : getUserPermissionFail()
    )
  );

const getSpecificUserRolePermissionCategoryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSpecificUserPermissionCategoryRequest.match),
    mergeMap(async (action) => {
      // dispatch(loadingUserRole());
      try {
        const response = await getSpecificPermissions(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getUserPermissionCategorySuccess(action?.payload)
        : getUserPermissionCategoryFail()
    )
  );

const getSpecificUserRolePermissionEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSpecificUserPermissionsRequest.match),
    mergeMap(async (action) => {
      // dispatch(loadingUserRole());
      try {
        const response = await getSpecificUserPermissionCategory(
          action.payload
        );
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getUserPermissionSuccess(action?.payload)
        : getUserPermissionFail()
    )
  );
//get next
const getUserRoleNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getUserRoleNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingUserRole());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getUserRoleSuccess(action?.payload) : getUserRoleFail()
    )
  );

//get previous
const getUserRolePrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getUserRolePreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingUserRole());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getUserRoleSuccess(action?.payload) : getUserRoleFail()
    )
  );
//create UserRole epic
const createUserRoleEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createUserRoleRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      try {
        const body = JSON.stringify(values);
        const response = await createUserRole(body);
        if (response) {
          dispatch(getUserRoleRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? createUserRoleSuccess() : createUserRoleFail();
    })
  );

//update UserRole epic
const updateUserRoleEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateUserRoleRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      try {
        const body = JSON.stringify(values);
        const response = await updateUserRole(body, id);
        if (response) {
          dispatch(getUserRoleRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearUserRoleData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateUserRoleSuccess() : updateUserRoleFail()
    )
  );

//get system module
const getUserGroupSystemModuleEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getUserGroupSystemModleRequest.match),
    mergeMap(async (action) => {
      // dispatch(loadingUserRole())
      try {
        const response = await getUserRoleSystemModule();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getUserGroupSystemModuleSuccess(action?.payload)
        : getUserGroupSystemModuleFail()
    )
  );
//
const getUserRoleByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getUserRoleByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getUserRoleById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? userRoleEditSuccess(action.payload) : userRoleEditFail()
    )
  );

export const userRoleEpics = combineEpics(
  getUserRoleEpic,
  createUserRoleEpic,
  updateUserRoleEpic,
  getUserRoleNext,
  getUserRolePrevious,
  getSpecificUserRolePermissionEpic,
  getSpecificUserRolePermissionCategoryEpic,
  getAllUserRolePermissionEpic,
  getUserGroupSystemModuleEpic,
  getUserRoleByIdEpic
);
