import { axiosInstance } from "../../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../../AppUtils/Utils/globalTypes";
import { saleReport } from "./types";
const saleReportModURL = "/api/v1/inventory-financial-report-app/sale-report";
export const getSaleReportCustomer = (rowsPerPage: number) =>
  axiosInstance.get(
    `${saleReportModURL}/customer?offset=0&limit=${rowsPerPage}`
  );
export const getSaleReportUser = () =>
  axiosInstance.get(`${saleReportModURL}/user?offset=0&limit=${0}`);
export const getSaleReportItem = () =>
  axiosInstance.get(`${saleReportModURL}/item?offset=0&limit=${0}`);
export const getSaleReportAllReport = (data: any) => {
  const { updatedValue } = data;
  const {
    item,
    summary,
    detail,
    customer,
    user,
    saleType,
    startDateAd,
    endDateAd,
    payType,
  } = updatedValue;

  return axiosInstance.get(
    `${saleReportModURL}/${summary ? "summary" : "detail"}?offset=0&limit=${0}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }&created_by=${user}&sale_type=${saleType}&customer=${customer}&item=${item}&date_after=${startDateAd}&date_before=${endDateAd}&pay_type=${payType}`
  );
};
export const getSaleReportQuickReport = (data: any) => {
  const { detailsRowsPerPage, detailsPage, updatedValue } = data;
  const {
    item,
    summary,
    detail,
    customer,
    user,
    saleType,
    startDateAd,
    endDateAd,
    payType,
  } = updatedValue;

  return axiosInstance.get(
    `${saleReportModURL}/${summary ? "summary" : "detail"}?offset=${
      Number(detailsRowsPerPage) * detailsPage
    }&limit=${Number(detailsRowsPerPage)}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }&created_by=${user}&sale_type=${saleType}&customer=${customer}&item=${item}&date_after=${startDateAd}&date_before=${endDateAd}&pay_type=${payType}`
  );
};
export const searchSaleReport = ({
  detailsRowsPerPage,
  search,
  updatedValue,
}: any) => {
  const {
    item,
    summary,
    detail,
    customer,
    user,
    saleType,
    startDateAd,
    endDateAd,
    payType,
  } = updatedValue;

  return `${saleReportModURL}/${
    summary ? "summary" : "detail"
  }?offset=0&limit=${detailsRowsPerPage}&search=${search}&created_by=${user}&sale_type=${saleType}&customer=${customer}&item=${item}&date_after=${startDateAd}&date_before=${endDateAd}&pay_type=${payType}`;
};
