import {
  getUserActivityLogReportInventorySupplierRequest,
  getUserActivityLogReportInventoryItemRequest,
  getUserActivityLogReportInventoryUserRequest,
  getUserActivityLogReportInventoryAllSummaryRequest,
  getUserActivityLogReportInventoryAllDetailRequest,
  getUserActivityLogReportInventoryQuickSummaryRequest,
  getUserActivityLogReportInventoryQuickDetailRequest,
  //reducer get data
  getUserActivityLogReportInventoryItemSuccess,
  getUserActivityLogReportInventoryItemFail,
  getUserActivityLogReportInventorySupplierSuccess,
  getUserActivityLogReportInventorySupplierFail,
  getUserActivityLogReportInventoryUserSuccess,
  getUserActivityLogReportInventoryUserFail,
  getUserActivityLogReportInventoryQuickDetailSuccess,
  getUserActivityLogReportInventoryQuickDetailFail,
  getUserActivityLogReportInventoryAllDetailSuccess,
  getUserActivityLogReportInventoryAllDetailFail,
  getUserActivityLogReportInventoryQuickSummarySuccess,
  getUserActivityLogReportInventoryQuickSummaryFail,
  getUserActivityLogReportInventoryAllSummarySuccess,
  getUserActivityLogReportInventoryAllSummaryFail,
  getUserActivityLogReportInventoryDetailSuccess,
  getUserActivityLogReportInventoryDetailFail,
  loadingUserActivityLogReportInventoryDetail,
  getUserActivityLogReportInventoryPreviousRequest,
  getUserActivityLogReportInventoryNextRequest,
  getUserActivityLogReportInventoryDetailRequest,
} from "./userActivityLogReportInventorySlice";
import { mergeMap } from "rxjs";
import * as API from "./api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../../AppUtils/Utils/validationConstants";
import { ContactsOutlined } from "@mui/icons-material";
export const controller = new AbortController();
const getUserActivityLogReportInventorySupplierEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getUserActivityLogReportInventorySupplierRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getUserActivityLogReportInventorySupplier();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getUserActivityLogReportInventorySupplierSuccess(action?.payload)
        : getUserActivityLogReportInventorySupplierFail()
    )
  );
//user
const getUserActivityLogReportInventoryUserEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getUserActivityLogReportInventoryUserRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getUserActivityLogReportInventoryUser();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getUserActivityLogReportInventoryUserSuccess(action?.payload)
        : getUserActivityLogReportInventoryUserFail()
    )
  );
//item
const getUserActivityLogReportInventoryItemEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getUserActivityLogReportInventoryItemRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getUserActivityLogReportInventoryItem();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getUserActivityLogReportInventoryItemSuccess(action?.payload)
        : getUserActivityLogReportInventoryItemFail()
    )
  );

//get all purchase summary detail
const getUserActivityLogReportInventoryAllSummaryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getUserActivityLogReportInventoryAllSummaryRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getUserActivityLogReportInventoryAllReport(
          action?.payload
        );
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getUserActivityLogReportInventoryAllSummarySuccess(action?.payload)
        : getUserActivityLogReportInventoryAllSummaryFail()
    )
  );
//quick summary
const getUserActivityLogReportInventoryQuickSummaryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getUserActivityLogReportInventoryQuickSummaryRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getUserActivityLogReportInventoryQuickReport(
          action.payload
        );
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getUserActivityLogReportInventoryQuickSummarySuccess(action?.payload)
        : getUserActivityLogReportInventoryQuickSummaryFail()
    )
  );

//get all purchase summary detail
const getUserActivityLogReportInventoryAllDetailEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getUserActivityLogReportInventoryAllDetailRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getUserActivityLogReportInventoryAllReport(
          action?.payload
        );
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getUserActivityLogReportInventoryAllDetailSuccess(action?.payload)
        : getUserActivityLogReportInventoryAllDetailFail()
    )
  );
//quick Detail
const getUserActivityLogReportInventoryQuickDetailEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getUserActivityLogReportInventoryQuickDetailRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getUserActivityLogReportInventoryQuickReport(
          action.payload
        );
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getUserActivityLogReportInventoryQuickDetailSuccess(action?.payload)
        : getUserActivityLogReportInventoryQuickDetailFail()
    )
  );

//next and previous
//get next
const getUserActivityLogReportInventoryNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getUserActivityLogReportInventoryNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingUserActivityLogReportInventoryDetail());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getUserActivityLogReportInventoryDetailSuccess(action?.payload)
        : getUserActivityLogReportInventoryDetailFail()
    )
  );
//get previous
const getUserActivityLogReportInventoryPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getUserActivityLogReportInventoryPreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingUserActivityLogReportInventoryDetail());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getUserActivityLogReportInventoryDetailSuccess(action?.payload)
        : getUserActivityLogReportInventoryDetailFail()
    )
  );
//het purchase report details
const getUserActivityLogReportInventoryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getUserActivityLogReportInventoryDetailRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getUserActivityLogReportInventoryQuickReport(
          action.payload
        );
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getUserActivityLogReportInventoryDetailSuccess(action?.payload)
        : getUserActivityLogReportInventoryDetailFail()
    )
  );
export const userActivityLogReportInventoryEpics = combineEpics(
  getUserActivityLogReportInventorySupplierEpic,
  getUserActivityLogReportInventoryItemEpic,
  getUserActivityLogReportInventoryUserEpic,
  getUserActivityLogReportInventoryAllSummaryEpic,
  getUserActivityLogReportInventoryQuickSummaryEpic,
  getUserActivityLogReportInventoryQuickDetailEpic,
  getUserActivityLogReportInventoryAllDetailEpic,
  getUserActivityLogReportInventoryPrevious,
  getUserActivityLogReportInventoryNext,
  getUserActivityLogReportInventoryEpic
);
