import React from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import MenuItemList from "./MenuItem";
import { useStyles } from "./SidebarStyles";
import { Dispatch, SetStateAction } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  Collapse,
  Divider,
  List,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import "./Sidebar.css";
import { useAppSelector } from "../../../AppUtils/Utils/appHooks";
import { loginSelector } from "../../../Pages/Login/Redux/selector";
import SVGImage from "../../AppElements/AppSVG/SVGImage";
import { divide } from "lodash";
// React runtime PropTypes
export const AppMenuItemPropTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string,
  permission: PropTypes.array,
  Icon: PropTypes.elementType,
  customIcon: PropTypes.any,
  items: PropTypes.array,
  className: PropTypes.any,
};
type AppMenuItemProp = PropTypes.InferProps<typeof AppMenuItemPropTypes>;
type AppMenuItemPropsWithoutItems = Omit<AppMenuItemProp, "items">;

// Improve child items declaration
export type AppMenuItemProps = AppMenuItemPropsWithoutItems & {
  items?: any;
  search: string;
  openSubMenu: boolean;
  setOpenSubMenu: Dispatch<SetStateAction<boolean>>;
  currentIndex?: number;
};
const Sidebar: React.FC<AppMenuItemProps> = (props, { open }) => {
  let showSubMenu: any;
  const { permissions, isSuperuser } = useAppSelector(loginSelector);

  const {
    name,
    link,
    Icon,
    items,
    className,
    search = "",
    currentIndex,
    customIcon,
  } = props;
  const classes = useStyles();
  const isExpandable = items && items.length > 0;
  const [openSubMenu, setOpenSubMenu] = useState(false);

  const MenuItemRoot = (
    <>
      {search === "" ? (
        <MenuItemList
          className={className}
          link={link}
          onClick={() => {
            setOpenSubMenu(!openSubMenu);
          }}
          currentIndex={currentIndex!}
        >
          {/* For Displaying of an icon in sidebar*/}
          {Icon ? (
            <ListItemIcon className={classes.Iconbg}>
              <Icon className={classes.SidebarIcon} />
            </ListItemIcon>
          ) : (
            customIcon && (
              <ListItemIcon className={classes.Iconbg}>
                <SVGImage src={customIcon} className={classes.SidebarIcon} />
              </ListItemIcon>
            )
          )}
          {/* for displaying of name in sidebar */}
          {<ListItemText primary={name} />}
          {/* Display the expand menu if the item has children */}
          {isExpandable && !openSubMenu && <ExpandMoreIcon />}
          {isExpandable && openSubMenu && <ExpandLessIcon />}

          {/* <div>this is another title</div> */}
        </MenuItemList>
      ) : (
        items?.map((item: any, index: number) => {
          const { name } = item;
          return (
            <React.Fragment key={index}>
              {name?.toLowerCase()?.includes(search?.toLowerCase()) && (
                <React.Fragment key={index}>
                  <Sidebar
                    {...item}
                    key={index}
                    className={classes.subMenuPadding}
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          );
        })
      )}
    </>
  );
  // for child
  const MenuItemChildren =
    search === "" ? (
      isExpandable ? (
        <Collapse in={openSubMenu}>
          <Divider />
          <List component="div" className={classes.sidebarChildrenList}>
            {items.map((item: any, index: number) => {
              //
              // let menu = item?.permission
              //   ?.reduce((acc: any, arr: any) => acc?.concat(arr), [])
              //   ?.reduce(
              //     (acc: any, arr: any) => acc?.concat(arr?.permission),
              //     []
              //   );
              const { permission, indexValue } = item;
              showSubMenu = permissions?.some(
                (element: any) => permission?.includes(element.codeName)
              );
              return (
                (isSuperuser || showSubMenu) && (
                  <React.Fragment key={index}>
                    <Sidebar
                      {...item}
                      key={index}
                      currentIndex={indexValue}
                      className={classes.subMenuPadding}
                    />
                  </React.Fragment>
                )
              );
            })}
          </List>
        </Collapse>
      ) : null
    ) : null;

  return (
    <>
      {MenuItemRoot}
      {MenuItemChildren}
    </>
  );
};

export default Sidebar;
