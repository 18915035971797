import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

const supplierModURL = "api/v1/inventory-supplier-app/supplier";

//get Supplier data api
export const getSupplier = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${supplierModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};
//handle search
export const searchSupplier = ({ rowsPerPage, search }: searchProps) => {
  return `${supplierModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
//create Supplier
export const createSupplier = (body: string | FormData) =>
  axiosInstance.post(`${supplierModURL}`, body);
//update Supplier
export const updateSupplier = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${supplierModURL}/${id}`, body);
//get Supplier data api
export const getSupplierTaxGroup = () => {
  return axiosInstance.get(`${supplierModURL}/tax-group`);
};

//
export const getSupplierById = (id: number) => {
  return axiosInstance.get(`${supplierModURL}/${id}`);
};

// import csv/xlsx file
export const importCSVFileSupplier = (body: any | string | null) => {
  return axiosInstance.post(`${supplierModURL}/import`, body);
};
